import './styles.scss';

import { Col, Form, FormProps, Input, Row, UploadFile } from 'antd';
import { HttpError, useApiUrl, useCustom } from '@refinedev/core';
import { MaskedInput } from 'antd-mask-input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Response from '../../data-access/responses/response';
import { PartnerTypeEnum } from '../../enums/partner-type.enum';
import { TenantStatusEnum } from '../../enums/tenant-status.enum';
import { getEnvConfig } from '../../getEnvConfig';
import { ITenant } from '../../interfaces/tenant';
import { maskPhone } from '../../utils/string';
import { DebounceAsyncValidator, validateEmail } from '../../utils/validator';

export type FormMode = 'show' | 'add' | 'edit';
type PartnerDetailFormProps = {
    formProps: FormProps;
    mode: FormMode;
    type: PartnerTypeEnum;
    onCancelEdit?: () => void;
    onChangeMode?: (mode: FormMode) => void;
};
export const PartnerDetailForm: React.FC<PartnerDetailFormProps> = ({
    formProps,
    mode,
    onCancelEdit,
    onChangeMode,
    type,
}) => {
    const apiUrl = useApiUrl();
    const { t } = useTranslation(['partner', 'common']);
    const [loading, setLoading] = useState<boolean>(false);

    const [isUpdateAvatar, setIsUpdateAvatar] = useState<boolean>(false);
    const initialValues: ITenant.ITenantInfor = {
        ...formProps.initialValues,
    } as ITenant.ITenantInfor;
    const [host, setHost] = useState<string>(initialValues ? initialValues.host : '');
    const [email, setEmail] = useState('');
    const onChangeImage = (file: UploadFile<any>) => {
        formProps.form?.setFieldsValue({ avatarUrl: file.response });
        setIsUpdateAvatar(true);
        if (file.status === 'uploading') {
            setLoading(true);
        } else {
            setLoading(false);
        }
    };

    const { refetch: checkUniqueHost } = useCustom<
        Response<boolean>,
        HttpError,
        ITenant.ICheckUniqueHostRequest
    >({
        url: `${apiUrl}/v1/partner/check-unique-host`,
        method: 'post',
        config: {
            payload: { host: host },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const { refetch: checkUniqueEmail } = useCustom<
        Response<boolean>,
        HttpError,
        {
            id?: string;
            email: string;
        }
    >({
        url: `${apiUrl}/v1/partner/check-unique-email`,
        method: 'post',
        config: {
            payload: { email: email },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const asyncValidateUniqueHost = DebounceAsyncValidator(400, async () => {
        const { data } = await checkUniqueHost();

        if (data && data?.data?.data) {
            return true;
        }
        return false;
    });

    const asyncValidateUniqueEmail = DebounceAsyncValidator(400, async () => {
        const { data } = await checkUniqueEmail();

        if (data && data?.data?.data) {
            return true;
        }
        return false;
    });

    useEffect(() => {
        // setHost(initialValues ? initialValues.host : '');
    }, [initialValues]);

    const renderFormItem = (isReadonly: boolean, formItem: JSX.Element, showItem: JSX.Element) => {
        if (isReadonly) {
            return showItem;
        }
        return formItem;
    };

    const hostSuffix = <span>.{getEnvConfig.DOMAIN}</span>;
    return (
        <Form
            className={`partner-detail ${mode === 'show' ? 'show-mode' : ''}`}
            {...formProps}
            onFinish={async (values) => {
                if (mode === 'edit') {
                    values.avatarUrl = isUpdateAvatar ? values.avatarUrl : null;
                } else {
                    values.avatarUrl = values.avatarUrl?.length > 0 ? values.avatarUrl : null;
                }
                values.partnerType = type;
                formProps.onFinish && formProps.onFinish(values);
            }}
            layout="vertical"
            initialValues={{
                ...initialValues,
                phone: maskPhone(initialValues?.phone || ''),
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    {type === PartnerTypeEnum.WHITELABEL && (
                        <>
                            <Form.Item
                                label={t('partners.fields.host.label')}
                                name="host"
                                rules={
                                    mode === 'add'
                                        ? [
                                              {
                                                  required: true,
                                                  message: t('partners.fields.host.required'),
                                              },
                                              {
                                                  pattern:
                                                      /^[a-zA-Z0-9]?[a-zA-Z0-9.-]*[a-zA-Z0-9]$/,
                                                  message: t('partners.fields.host.pattern'),
                                              },
                                              {
                                                  validator: async (_, value) => {
                                                      if (
                                                          value === '' ||
                                                          !value ||
                                                          value === initialValues.host ||
                                                          !/^[a-zA-Z0-9]?[a-zA-Z0-9.-]*[a-zA-Z0-9]$/.test(
                                                              value,
                                                          )
                                                      ) {
                                                          return;
                                                      }
                                                      if (value.length < 2) {
                                                          return Promise.reject(
                                                              new Error(
                                                                  t('partners.fields.host.length'),
                                                              ),
                                                          );
                                                      }

                                                      const result =
                                                          await asyncValidateUniqueHost();
                                                      if (result) {
                                                          return Promise.resolve();
                                                      }
                                                      return Promise.reject(
                                                          new Error(
                                                              t('partners.fields.host.unique'),
                                                          ),
                                                      );
                                                  },
                                              },
                                          ]
                                        : []
                                }
                            >
                                {renderFormItem(
                                    mode !== 'add',
                                    <Input
                                        placeholder={t('partners.fields.host.placeholder')}
                                        suffix={hostSuffix}
                                        onChange={(e) => setHost(e.target.value)}
                                    />,
                                    <span>{initialValues?.host}</span>,
                                )}
                            </Form.Item>
                            <Form.Item
                                label={t('partners.fields.name.label')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t('partners.fields.name.required')}`,
                                    },
                                ]}
                            >
                                {renderFormItem(
                                    mode === 'show',
                                    <Input
                                        placeholder={t('partners.fields.name.placeholder')}
                                        maxLength={100}
                                    />,
                                    <span>{initialValues?.name}</span>,
                                )}
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        label={t('partners.fields.firstName.label')}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.firstName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input placeholder={t('partners.fields.firstName.placeholder')} />,
                            <span>{initialValues?.firstName}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.lastName.label')}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.lastName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input placeholder={t('partners.fields.lastName.placeholder')} />,
                            <span>{initialValues?.lastName}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.email.label')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.email.required'),
                            },
                            {
                                type: 'email',
                                message: t('partners.fields.email.valid'),
                            },
                            {
                                validator: async (_, value) => {
                                    if (
                                        !value ||
                                        !validateEmail(value) ||
                                        value === initialValues.email
                                    ) {
                                        return;
                                    }

                                    const result = await asyncValidateUniqueEmail();
                                    if (result) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(t('partners.fields.email.unique')),
                                    );
                                },
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode !== 'add',
                            <Input
                                placeholder={t('partners.fields.email.placeholder')}
                                onChange={(e) => {
                                    if (e?.target?.value) {
                                        const _value = e.target.value.trim().toLowerCase();
                                        formProps.form?.setFieldsValue({ email: _value });
                                        setEmail(_value);
                                    }
                                }}
                            />,
                            <span>{initialValues?.email}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.phoneNumber.label')}
                        name="phone"
                        rules={[
                            {
                                pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                            {
                                required: true,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <MaskedInput
                                mask={
                                    //  https://imask.js.org/guide.html#masked-pattern
                                    '(000)-000-0000'
                                }
                            />,
                            <span>{maskPhone(initialValues?.phone || '')}</span>,
                        )}
                    </Form.Item>
                    <Form.Item hidden name="partnerType"></Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
