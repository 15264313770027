import './styles.scss';

import { TENANT_BUSINESS_SETTING_RESOURCE } from '@constants/index.constant';
import { Space } from 'antd';
import { useCustom } from '@refinedev/core';
import { BrandColorSetting } from 'components/setting/setting-business-color/setting-business-color';
import { CompanyBrandingSetting } from 'components/setting/setting-company-branding/setting-company-branding';
import Response from 'data-access/responses/response';
import { getEnvConfig } from 'getEnvConfig';
import { Setting } from 'interfaces/setting';
import React from 'react';

interface IBusinessSettingProps {
    name?: string;
}

export const BusinessSetting: React.FC<IBusinessSettingProps> = () => {
    const apiUrl = getEnvConfig.PORTAL_URL;
    const url = `${apiUrl}/${TENANT_BUSINESS_SETTING_RESOURCE}`;
    const { data } = useCustom<Response<Setting.IBrandSetting[]>>({
        url: url,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    return (
        <section className="w-full flex flex-col">
            <Space className="w-full mb-6" align={'start'}>
                <CompanyBrandingSetting url={url} data={data?.data} />
            </Space>
            <Space className="w-full" align={'start'}>
                <BrandColorSetting />
            </Space>
        </section>
    );
};
