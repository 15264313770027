import './styles.scss';

import { IReport } from '@interfaces/report';
import { useTable } from '@refinedev/core';
import { convertPrice } from '@utils/resource';
import { Table } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useTranslation } from 'react-i18next';

const REPORT_RESOURCE_URL = 'v1/report';

export const OverdueInvoiceCrmIndex: React.FC = () => {
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const { tableQueryResult } = useTable<IReport.IOverdueInvoice>({
        dataProviderName,
        resource: `${REPORT_RESOURCE_URL}/overdue-invoice`,
        hasPagination: false,
    });

    const { t } = useTranslation(['common']);

    return (
        <>
            <section className="item-list-container quote-page-wrapper mb-10">
                <div className="overflow-hidden">
                    <h3 className="title-section"> {t(`reports.overdue-invoice`)}</h3>
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult?.data?.data}
                            loading={tableQueryResult.isFetching}
                            pagination={false}
                            rowKey="id"
                            className="overdue-invoice-table"
                            showHeader={false}
                            scroll={{ x: '599px', y: '100%' }}
                        >
                            <Table.Column
                                dataIndex="dateRange"
                                key="dateRange"
                                render={(text, _: IReport.IOverdueInvoice) => (
                                    <p className="table-tbody-text">
                                        {t(`reports.${_.dateRange}`)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                dataIndex="total"
                                key="total"
                                render={(text, _: IReport.IOverdueInvoice) => (
                                    <p className="table-tbody-text">{convertPrice(_.total)}</p>
                                )}
                            />
                        </Table>
                    </div>
                </div>
            </section>
        </>
    );
};
