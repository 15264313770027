import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { ContactNoteItemForm } from '@components/contact-note/note-form';
import { ContactNoteList } from '@components/contact-note/note-list';
import PaymentForm from '@components/setting/billing-payment-method/payment-form';
import { PERMISSIONS } from '@constants/permission';
import { UserStatusEnum } from '@enums/user-status.enum';
import { IContact } from '@interfaces/contact';
import { Payment } from '@interfaces/payment';
import { useModal } from '@refinedev/antd';
import { HttpError, useCustom, useCustomMutation, useList, usePermissions } from '@refinedev/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { formatPrice } from '@utils/resource';
import { Button, Empty, Modal, Spin } from 'antd';
import Response from 'data-access/responses/response';
import { getEnvConfig } from 'getEnvConfig';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactPaymentMethodList } from './payment-method-list';
import { RecurringInvoice } from './recurring-invoice';
export const ContactInsights: React.FC<{
    contactId: string;
    contactStatus?: UserStatusEnum;
    userId?: string;
}> = ({ contactId, contactStatus, userId }) => {
    const { t } = useTranslation(['quote', 'common', 'contact']);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const stripePromise = loadStripe(getEnvConfig.STRIPE_PUBLIC_KEY);
    const apiUrl = getEnvConfig.PORTAL_URL;

    const { data: permissions } = usePermissions<string[]>();
    const { data: insightsData } = useCustom<IContact.IInvoicesOfContactDto>({
        url: `${apiUrl}/v1/contacts/invoices-contact/${contactId}`,
        method: 'get',
    });

    // Get payment method of contact
    const { data: paymentMethodData, refetch: paymentRefetch } = useCustom<Payment.IPaymentMethod>({
        url: `${apiUrl}/v1/contacts/payment-methods/${contactId}`,
        method: 'get',
    });

    const { data: latestExpirationSubscription } = useCustom<any>({
        url: `${apiUrl}/v1/contacts/latest-expiration-subscription/${contactId}`,
        method: 'get',
    });

    // Add payment method success
    const onPaymentIntentSuccess = () => {
        // close modal reload subscriptions
        close();
        setClientSecret(null);
    };

    // Set payment method default
    const { mutate: setDefaultPayment } = useCustomMutation<Response<boolean>, HttpError, any>();
    const handleSetDefaultPayment = async (cardId: string) => {
        setDefaultPayment(
            {
                url: `${apiUrl}/v1/contacts/update-default-payment/set-default`,
                method: 'put',
                values: { userCardId: cardId, contactId },
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: async (data) => {
                    if (data.data) {
                        await paymentRefetch();
                    }
                },
            },
        );
    };

    // Open modal add new payment method for contact
    const { mutate: createPaymentIntent } = useCustomMutation<
        Response<{ clientSecret: string }>,
        HttpError,
        any
    >();
    const handleAddNewCard = (userId: string) => {
        show();
        createPaymentIntent(
            {
                url: `${apiUrl}/v1/contacts/add-new-payment/${userId}`,
                method: 'post',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    setClientSecret(result.data.clientSecret);
                },
            },
        );
    };
    const { modalProps, show, close } = useModal({
        modalProps: {
            onCancel: () => {
                setClientSecret(null);
            },
        },
    });
    // Show modal and Add new note for contact
    const { modalProps: modalNoteProps, show: showModalNote, close: closeModalNote } = useModal();
    const handleAddNewNote = () => {
        showModalNote();
    };
    return (
        <>
            <div className="flex flex-col">
                <div className="mb-4">
                    <div className="mb-2 font-bold title">
                        {t('contact.insight', { ns: 'common' })}
                    </div>
                    <div className="contact-insight contact-total-insight">
                        <h4>{t('contact.total_spend', { ns: 'common' })}</h4>
                        <h3 className="mb-2">{formatPrice(insightsData?.data.sumTransaction)}</h3>
                        <span className="text-gray-400">
                            {insightsData?.data.totalTransaction}{' '}
                            {insightsData?.data.totalTransaction &&
                            insightsData.data.totalTransaction > 1
                                ? t('contact.transactions', { ns: 'common' })
                                : t('contact.transaction', { ns: 'common' })}
                        </span>
                    </div>
                </div>
                <div className="subscription">
                    <div className="flex flex-col ">
                        <div className="mb-2 font-bold title">
                            {t('contact.subscription_invoice', { ns: 'common' })}
                        </div>
                        <div className="contact-insight p-5">
                            {latestExpirationSubscription?.data ? (
                                <RecurringInvoice
                                    latestExpirationSubscription={latestExpirationSubscription.data}
                                />
                            ) : (
                                <div className="note-list-wrapper text-center last-expiration-subscription">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="subscription contact-payment-method">
                    <div className="flex flex-row justify-between items-center">
                        <div className="my-2 font-bold title">
                            {t('contact.payment_method', { ns: 'common' })}
                        </div>
                        {(permissions || []).includes(PERMISSIONS.BILLING_CREATE) &&
                        contactStatus == UserStatusEnum.ACTIVE &&
                        userId ? (
                            <div className="my-2 flex justify-end">
                                <Button
                                    onClick={() => handleAddNewCard(contactId)}
                                    className="contact-button"
                                    type="text"
                                    icon={<PlusOutlined />}
                                >
                                    {t('add_new', { ns: 'common' })}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    <ContactPaymentMethodList
                        cols={paymentMethodData?.data || []}
                        setPaymentDefault={handleSetDefaultPayment}
                    />
                </div>
                <div className="subscription">
                    <div className="flex flex-row justify-between items-center">
                        <div className="my-4 font-bold title">
                            {t('contacts.note', { ns: 'contact' })}
                        </div>
                        <Button
                            onClick={() => handleAddNewNote()}
                            className="contact-button"
                            type="text"
                            icon={<PlusOutlined />}
                        >
                            {t('add_new', { ns: 'common' })}
                        </Button>
                    </div>
                    {contactId && <ContactNoteList contactId={contactId} />}
                </div>
                <Modal
                    {...modalProps}
                    title="Payment"
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    {clientSecret && (
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret: clientSecret,
                            }}
                        >
                            <PaymentForm onSuccess={onPaymentIntentSuccess} />
                        </Elements>
                    )}
                    {!clientSecret && (
                        <div className="mt-2 mb-2">
                            <Spin spinning={true} />
                        </div>
                    )}
                </Modal>

                <Modal
                    {...modalNoteProps}
                    title={t('contacts.note_content.add_new', { ns: 'contact' })}
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    <div className="mt-2">
                        <ContactNoteItemForm
                            contactId={contactId}
                            onCancelEdit={() => {
                                closeModalNote();
                            }}
                            onUpdate={() => {
                                closeModalNote();
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
};
