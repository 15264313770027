import './styles.scss';

import { CheckOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { INotification } from '@interfaces/notification';
import {
    Badge,
    Button,
    Col,
    Dropdown,
    Menu,
    Popconfirm,
    Radio,
    RadioChangeEvent,
    Row,
    Table,
} from 'antd';
import { useCustom, useCustomMutation } from '@refinedev/core';
import Response from '@responses/response';
import { PosSiderIcon } from 'components/layout/sidebar/icons/pos-icon';
import { WhiteLabelSiderIcon } from 'components/layout/sidebar/icons/wl-icon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MarkAllAsReadIcon } from '../../components/layout/sidebar/icons/mark-all-as-read';
import { NuviooSiderIcon } from '../../components/layout/sidebar/icons/nuvioo-icon';
import { FAV_URL_KEY, PAGE_SIZE } from '../../constants/index.constant';
import { getEnvConfig } from '../../getEnvConfig';
import { ITenant } from '../../interfaces/tenant';
import { formatDate } from '../../utils/date';

type ModuleItem = {
    module: string;
    total: number;
};

export const NotificationIndex: React.FC = () => {
    const notificationApiUrl = getEnvConfig.NOTIFICATION_URL;
    const apiUrl = getEnvConfig.PORTAL_URL;

    const { t } = useTranslation(['notification']);

    const [typeNotification, setTypeNotification] = useState<string>('');
    const [current, setCurrent] = useState<number>(1);
    const [totalNotification, setTotalNotification] = useState<number>(0);
    const [filter, setFilter] = useState<string>('');

    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const favicon = localStorage.getItem(FAV_URL_KEY);

    const { data: tenant } = useCustom<Response<ITenant.ITenantInfor>>({
        url: `${apiUrl}/v1/tenant/current`,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    const {
        data: listNotifications,
        isLoading,
        refetch: refetchNotification,
    } = useCustom<{
        data: INotification.INotification[];
        total: number;
    }>({
        method: 'get',
        dataProviderName: 'notification',
        url: `${notificationApiUrl}/v1/notifications`,
        config: {
            filters: [
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: pageSize,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: current,
                },
                {
                    field: 'module',
                    operator: 'eq',
                    value: typeNotification,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
            ],
        },
    });
    const { data: listModule, refetch: refetchListModule } = useCustom<{ data: ModuleItem[] }>({
        method: 'get',
        dataProviderName: 'notification',
        url: `${notificationApiUrl}/v1/notifications/total-by-module`,
    });
    const { mutate } = useCustomMutation<any>();
    const { mutate: mutateDeleted, isLoading: isDeleted } = useCustomMutation<any>();

    useEffect(() => {
        const modules = listModule?.data?.data;
        const total: number = modules?.length
            ? modules.reduce((pre, current) => {
                  return pre + Number(current.total ?? 0);
              }, 0)
            : 0;
        setTotalNotification(total);
    }, [listModule]);

    useEffect(() => {
        if (listNotifications?.data.total === pageSize * (current - 1)) {
            setCurrent(Math.max(current - 1, 1));
        }
    }, [listNotifications]);

    useEffect(() => {
        if (current !== 1) {
            setCurrent(1);
        }
    }, [typeNotification, filter]);

    const onChangeTypeNotification = (e: RadioChangeEvent) => {
        setTypeNotification(e.target.value);
    };
    const renderIconModule = (value: string) => {
        let icon;
        switch (value) {
            case 'wl':
                icon = <WhiteLabelSiderIcon width="32" height="32" />;
                break;
            case 'pos':
                icon = <PosSiderIcon width="32" height="32" />;
                break;
            default:
                icon = <NuviooSiderIcon width="24" height="24" url={favicon || ''} />;
                break;
        }

        return icon;
    };

    const onNotificationClick = async (notification: INotification.INotification) => {
        if (!notification.isRead) {
            mutate({
                url: `${notificationApiUrl}/v1/notifications/${notification.id}/mark-as-read`,
                method: 'put',
                values: {},
            });
        }

        if (notification.route) {
            window.location.href = notification.route;
        }
    };

    const renderLabelModule = (module: string) => {
        let label = '';
        switch (module) {
            case 'wl':
                label = 'White Label';
                break;
            case 'portal':
                label = tenant?.data?.data?.name ?? 'Nuvioo';
                break;
            case 'pos':
                label = 'POS';
                break;
            default:
                break;
        }
        return label;
    };

    const changeStatus = (id: string, status: boolean) => {
        mutate(
            {
                url: `${notificationApiUrl}/v1/notifications/${id}/${
                    status ? 'mark-as-unread' : 'mark-as-read'
                }`,
                method: 'put',
                values: {},
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, variables, context) => {
                    refetchNotification();
                },
            },
        );
    };

    const onChangeFilter = (e: RadioChangeEvent) => {
        setFilter(e.target.value);
    };

    const markAllAsRead = () => {
        mutateDeleted(
            {
                url: `${notificationApiUrl}/v1/notifications/mark-all-as-read`,
                method: 'put',
                values: {},
            },
            {
                onSuccess: (value) => {
                    refetchNotification();
                },
            },
        );
    };

    return (
        <div className="notification-page-index">
            <Row>
                <Col md={24} lg={8} xl={6}>
                    <Radio.Group
                        className="type-notification"
                        onChange={onChangeTypeNotification}
                        defaultValue=""
                    >
                        <Radio.Button className="item-type max-w-full" value="">
                            <div className="module-item-wrapper flex all-notification">
                                <div className="label-module flex justify-between w-full">
                                    <span>{t('all_notifications')}</span>
                                    <Badge
                                        className="w-9 total-notification"
                                        count={totalNotification}
                                        showZero
                                    />
                                </div>
                            </div>
                        </Radio.Button>

                        {listModule?.data?.data.map((item, index) => (
                            <Radio.Button
                                className="item-type max-w-full"
                                key={index}
                                value={item.module}
                            >
                                <div
                                    className={`module-item-wrapper flex gap-2 ${
                                        item.module !== 'portal' ? 'items-center' : ''
                                    }`}
                                >
                                    <div className="w-8 h-8">{renderIconModule(item.module)}</div>
                                    <div
                                        className={`label-module flex justify-between w-full ${
                                            item.module === 'portal' ? 'pl-1' : ''
                                        } `}
                                    >
                                        <span className="label-item">
                                            {renderLabelModule(item.module)}
                                        </span>
                                        <Badge
                                            className="w-9 total-notification"
                                            count={item.total}
                                        />
                                    </div>
                                </div>
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Col>
                <Col md={24} lg={16} xl={18}>
                    <div className="pb-2 flex justify-between">
                        <Radio.Group
                            size="large"
                            className="filter-notification flex gap-3"
                            onChange={onChangeFilter}
                            defaultValue=""
                        >
                            <Radio.Button value="">{t('all')}</Radio.Button>
                            <Radio.Button value="unread">{t('unread')}</Radio.Button>
                        </Radio.Group>
                        <div className="mark-all-as-read">
                            <Button
                                size="large"
                                onClick={() => markAllAsRead()}
                                type="primary"
                                className="btn-mark-all-as-read"
                            >
                                <MarkAllAsReadIcon />
                                <span className="pl-1">{t('mark_all_as_read')}</span>
                            </Button>
                        </div>
                    </div>

                    <div className="overflow-hidden">
                        <div className="list-notification-wrapper">
                            <Table
                                showHeader={false}
                                dataSource={listNotifications?.data?.data}
                                loading={isLoading}
                                pagination={{
                                    className: 'notification-pagination',
                                    current: current,
                                    pageSize: pageSize,
                                    total: listNotifications?.data?.total,
                                    onChange: (page, size) => {
                                        setCurrent(page);
                                        setPageSize(size);
                                    },
                                }}
                            >
                                <Table.Column
                                    dataIndex="incrementId"
                                    key="incrementId"
                                    width={80}
                                    render={(text, _: INotification.INotification, index) => (
                                        <div
                                            className={`relative cursor-pointer pr-4 notification-item ${
                                                !_.isRead ? 'unread' : 'read'
                                            }`}
                                        >
                                            <p
                                                className="title-notification"
                                                onClick={() => onNotificationClick(_)}
                                                dangerouslySetInnerHTML={{
                                                    __html: t(`${_.module}.${_.title}`, _.data),
                                                }}
                                            ></p>
                                            <p
                                                className="create-date"
                                                onClick={() => onNotificationClick(_)}
                                            >
                                                {formatDate(_.createdAt)}
                                            </p>
                                            <div className="service-action">
                                                <Dropdown
                                                    overlayClassName="service-dropdown-container"
                                                    overlay={
                                                        <Menu mode="vertical">
                                                            <Menu.Item
                                                                key="1"
                                                                icon={<CheckOutlined />}
                                                                onClick={() => {
                                                                    changeStatus(_.id, _.isRead);
                                                                }}
                                                            >
                                                                {_.isRead
                                                                    ? t('mark_as_unread')
                                                                    : t('mark_as_read')}
                                                            </Menu.Item>
                                                            <Menu.Item
                                                                key="2"
                                                                icon={
                                                                    <Popconfirm
                                                                        key="delete"
                                                                        okText={'Delete'}
                                                                        cancelText={'Cancel'}
                                                                        okType="danger"
                                                                        title={'Are you sure?'}
                                                                        okButtonProps={{
                                                                            disabled: isDeleted,
                                                                        }}
                                                                        onConfirm={(): void => {
                                                                            mutateDeleted(
                                                                                {
                                                                                    url: `${notificationApiUrl}/v1/notifications/${_.id}`,
                                                                                    method: 'delete',
                                                                                    values: {},
                                                                                },
                                                                                {
                                                                                    onSuccess: (
                                                                                        value,
                                                                                    ) => {
                                                                                        refetchNotification();
                                                                                        refetchListModule();
                                                                                    },
                                                                                },
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            danger
                                                                            loading={isDeleted}
                                                                            icon={
                                                                                <DeleteOutlined />
                                                                            }
                                                                            style={{
                                                                                padding:
                                                                                    '5px 76px 5px 12px',
                                                                                margin: 0,
                                                                            }}
                                                                        >
                                                                            {'Delete'}
                                                                        </Button>
                                                                    </Popconfirm>
                                                                }
                                                                style={{ padding: 0 }}
                                                            ></Menu.Item>
                                                        </Menu>
                                                    }
                                                    trigger={['click']}
                                                >
                                                    <MoreOutlined className="absolute cursor-pointer action-icon" />
                                                </Dropdown>
                                            </div>
                                        </div>
                                    )}
                                />
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
