import { notification } from 'antd';

export const errorNotification = (text: string) => {
    notification.error({
        message: text,
        type: 'error',
    });
};

export const successNotification = (text: string) => {
    notification.error({
        message: text,
        type: 'success',
    });
};
