export const RemoveActionIcon: React.FC = () => {
    return (
        <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8333 4.99935V4.33268C11.8333 3.39926 11.8333 2.93255 11.6517 2.57603C11.4919 2.26243 11.2369 2.00746 10.9233 1.84767C10.5668 1.66602 10.1001 1.66602 9.16667 1.66602H7.83333C6.89991 1.66602 6.4332 1.66602 6.07668 1.84767C5.76308 2.00746 5.50811 2.26243 5.34832 2.57603C5.16667 2.93255 5.16667 3.39926 5.16667 4.33268V4.99935M6.83333 9.58268V13.7493M10.1667 9.58268V13.7493M1 4.99935H16M14.3333 4.99935V14.3327C14.3333 15.7328 14.3333 16.4329 14.0608 16.9677C13.8212 17.4381 13.4387 17.8205 12.9683 18.0602C12.4335 18.3327 11.7335 18.3327 10.3333 18.3327H6.66667C5.26654 18.3327 4.56647 18.3327 4.03169 18.0602C3.56129 17.8205 3.17883 17.4381 2.93915 16.9677C2.66667 16.4329 2.66667 15.7328 2.66667 14.3327V4.99935"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
