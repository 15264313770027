import { IEmployee } from '@interfaces/components/employee';
import { IUser } from '@interfaces/user';
import { useStepsForm } from '@refinedev/antd';
import { useApiUrl, useCustomMutation, useGetIdentity, useSetLocale } from '@refinedev/core';
import { Button, Form, notification, Result, Spin, Steps } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { getEnvConfig } from 'getEnvConfig';
import { InformationForm } from 'pages/internal-crm/payout-setting/sale-payouts/information-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

export const SalePayoutsPage = () => {
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const changeLanguage = useSetLocale();

    const [form] = Form.useForm();

    const { t } = useTranslation(['common']);

    const { mutate: createEmployeePayouts, isLoading: submitting } = useCustomMutation();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const [loading, setLoading] = useState<boolean>(false);
    const [tokenPayment, setTokenPayment] = useState<string>('');
    const [informationForm, setInformationForm] = useState<{
        firstName: string;
        lastName: string;
        email: string;
    }>({
        firstName: userIdentity?.firstName || '',
        lastName: userIdentity?.lastName || '',
        email: userIdentity?.email || '',
    });

    const { current, gotoStep } = useStepsForm();

    const steps = [
        {
            title: t('crm_internal.payout_setting.information'),
        },
        {
            title: t('crm_internal.payout_setting.payment_setting'),
        },
    ];

    useEffect(() => {
        changeLanguage('en');
    }, []);

    useEffect(() => {
        if (current) {
            const onSubmit = async () => {
                setLoading(true);

                form.submit(
                    getEnvConfig.FINIX_ENVIRONMENT as string,
                    getEnvConfig.FINIX_APPLICATION_ID as string,
                    async function (err, res: Finix.FormResponse) {
                        const tokenData = res.data || {};
                        const token = tokenData.id;

                        if (!token) {
                            notification.error({
                                message: 'Finix generate token error',
                            });
                        }

                        setLoading(false);
                        setTokenPayment(token);
                    },
                );
            };
            const options = {
                showAddress: true,
                onSubmit,
                styles: {
                    default: {
                        color: '#000',
                        fontSize: '14px',
                        boxShadow: 'none',
                        borderRadius: '8px',
                        padding: '8px 14px',
                        border: '1px solid #d9d9d9',
                        fontFamily: `-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'`,
                    },
                    success: {
                        color: '#5cb85c',
                    },
                    error: {
                        color: '#ff4d4f',
                        border: '1px solid rgba(255,0,0, 0.3)',
                    },
                },
            };
            const form = window.Finix.BankTokenForm('form-bank', options);
        }
    }, [current]);

    useEffect(() => {
        if (tokenPayment) {
            const requestForm: IEmployee.IEmployeePayouts = {
                ...informationForm,
                tokenPayment,
            };

            createEmployeePayouts(
                {
                    method: 'post',
                    values: requestForm,
                    url: `${apiUrl}/v1/user-payments`,
                    errorNotification: (error) => ({
                        type: 'error',
                        message: t(
                            'payouts_errors.error?.response?.data?.error ',
                            error?.response?.data?.error,
                        ),
                    }),
                },
                {
                    onSuccess: () => {
                        const { origin, pathname } = window.location;
                        window.location.href = `${origin}${pathname}?status=success`;
                    },
                },
            );
        }
    }, [tokenPayment]);

    useEffect(() => {
        form.setFieldsValue({
            firstName: userIdentity?.firstName,
            lastName: userIdentity?.lastName,
            email: userIdentity?.email,
        });
    }, [userIdentity]);

    const handleStep = async () => {
        try {
            const value = await form.validateFields();

            if (!value) {
                return;
            }
            gotoStep(current ? current - 1 : current + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const onChangeInformationForm = (type: string, value: string) => {
        setInformationForm((prev) => ({
            ...prev,
            [type]: value,
        }));
    };

    const BankAccountForm = <section id="form-bank"></section>;

    if (status === 'success') {
        return (
            <section className="center h-full">
                <Result status="success" title="Successfully Submit!" />
            </section>
        );
    }

    const formList = [
        <InformationForm onChangeInformationForm={onChangeInformationForm} />,
        BankAccountForm,
    ];

    return (
        <>
            <section className={`flex items-center justify-center m-auto ${s.employeePayoutsPage}`}>
                <Spin spinning={loading || submitting}>
                    <Form form={form} layout="vertical" className={s.employeePayoutsContainer}>
                        <section className={s.formContainer}>
                            <div className={s.stepContainer}>
                                <Steps items={steps} current={current} />
                            </div>
                            <div className={`flex flex-col ${s.formWrapper}`}>
                                {formList[current]}
                            </div>
                        </section>
                        <Button
                            type="primary"
                            onClick={handleStep}
                            className="w-full justify-center"
                        >
                            {current ? t('buttons.previous') : t('buttons.next')}
                        </Button>
                    </Form>
                </Spin>
            </section>
            <br />
        </>
    );
};
