import './styles.scss';

import { Select } from 'antd';

type FilterSelectionProps = {
    listValue: { label: string; value: number | string }[];
    placeholder: string;
    className?: string;
    defaultValue?: string;
    onChangeValue: (value: string) => void;
};

export const FilterSelection: React.FC<FilterSelectionProps> = (props) => {
    const { listValue, onChangeValue, placeholder, defaultValue, className } = props;
    const onChange = (value: string) => {
        onChangeValue(value);
    };

    return (
        <>
            <Select
                allowClear
                placeholder={placeholder}
                onChange={onChange}
                options={listValue}
                className={`filter-selection-component ${className}`}
                getPopupContainer={(e) => {
                    return e.parentNode;
                }}
                defaultValue={defaultValue || null}
            />
        </>
    );
};
