import './styles.scss';

import { NUserPayment } from '@interfaces/user-payment';
import { useOne } from '@refinedev/core';
import { DataProviderNameEnum } from 'dataProvider';
import { SalePayoutsPage } from 'pages/internal-crm/payout-setting/sale-payouts';
import { SalePayoutInfo } from 'pages/internal-crm/payout-setting/sale-payouts-info';
import { useEffect } from 'react';

export enum TenantPaymentAccountError {
    AccountNotExisted = 'account_not_existed',
    GetFailed = 'get_account_failed',
    TenantNotFound = 'tenant_not_found',
}

export const PayoutCrmLayoutPage: React.FC = () => {
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const {
        data: userPayment,
        refetch: userPaymentRefetch,
        isLoading,
    } = useOne<NUserPayment.IUserPaymentDto>({
        resource: 'v1/user-payments',
        id: '',
        queryOptions: {
            enabled: false,
        },
        dataProviderName,
    });

    useEffect(() => {
        userPaymentRefetch();
    }, []);

    return (
        <>
            {!isLoading &&
                (userPayment?.data ? (
                    <SalePayoutInfo salePayout={userPayment?.data} />
                ) : (
                    <SalePayoutsPage />
                ))}
        </>
    );
};
