import './styles.scss';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { ProductCrmDetail, ProductCrmItem } from '@components/internal-crm/product';
import { ROOT_TENANT } from '@constants/index.constant';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IProduct } from '@interfaces/product';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { CreateButton, useDrawerForm, useSimpleList } from '@refinedev/antd';
import {
    BaseKey,
    CrudFilters,
    HttpError,
    IResourceComponentsProps,
    useGetIdentity,
    useNavigation,
    useOne,
    useTranslate,
} from '@refinedev/core';
import { Col, Form, List, Row } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useParams } from 'react-router-dom';

export const ProductCrmIndex: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { goBack, push } = useNavigation();

    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const isSysAdmin =
        userIdentity?.tenantId === ROOT_TENANT && userIdentity.userType === UserTypeEnum.TENANT;

    const { id } = useParams();
    const { data, error } = useOne<IService>({
        dataProviderName,
        resource: 'v1/services',
        id: id as BaseKey,
        errorNotification: false,
        queryOptions: {
            retry: 1,
        },
    });

    const { listProps, searchFormProps } = useSimpleList<IProduct, HttpError, { name: string }>({
        dataProviderName,
        pagination: { pageSize: 12, current: 1 },
        resource: 'v1/products',
        initialFilter: [
            {
                field: 'serviceId',
                operator: 'eq',
                value: id,
            },
        ],
        onSearch: ({ name }) => {
            const productFilters: CrudFilters = [];

            productFilters.push({
                field: 'name',
                operator: 'eq',
                value: name ? name : undefined,
            });

            return productFilters;
        },
    });

    if (error) {
        const { statusCode } = error as { statusCode: number };
        if (statusCode === 404) {
            push('/404');
        }
    }

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IProduct>({
        dataProviderName,
        action: 'create',
        resource: 'v1/products',
        successNotification: {
            message: t('crm_internal.product.create_product_success'),
            type: 'success',
        },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IProduct>({
        dataProviderName,
        action: 'edit',
        resource: 'v1/products',
        successNotification: {
            message: t('crm_internal.product.edit_product_success'),
            type: 'success',
        },
        redirect: false,
        onMutationSuccess: () => {
            editFormProps.form.resetFields();
        },
    });

    return (
        <>
            <Form
                {...searchFormProps}
                onValuesChange={() => {
                    searchFormProps.form?.submit();
                }}
            >
                <Row gutter={[16, 16]} style={{ background: '#fff', padding: '16px 24px' }}>
                    <Col xs={24}>
                        <div className="product-wrapper">
                            <label className="product-header-section">
                                <ArrowLeftOutlined onClick={goBack} />
                                {data?.data.name}
                            </label>
                            {isSysAdmin && (
                                <CreateButton type="primary" onClick={() => createShow()}>
                                    {t('services.buttons.addProduct')}
                                </CreateButton>
                            )}
                        </div>
                        <List
                            grid={{
                                gutter: 20,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 2,
                                xl: 3,
                                xxl: 4,
                            }}
                            className="product-list-item"
                            {...listProps}
                            renderItem={(item: IProduct) => (
                                <ProductCrmItem
                                    item={item}
                                    editShow={editShow}
                                    isSysAdmin={isSysAdmin}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Form>
            {id && (
                <>
                    <ProductCrmDetail
                        drawerProps={createDrawerProps}
                        formProps={createFormProps}
                        saveButtonProps={createSaveButtonProps}
                        isEditMode={false}
                        serviceId={id}
                        isSysAdmin={isSysAdmin}
                    />
                    <ProductCrmDetail
                        isEditMode={true}
                        drawerProps={editDrawerProps}
                        formProps={editFormProps}
                        saveButtonProps={editSaveButtonProps}
                        serviceId={id}
                        isSysAdmin={isSysAdmin}
                    />
                </>
            )}
        </>
    );
};
