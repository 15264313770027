import { NO_PERMISSION_ITEMS, TOKEN_KEY } from './authProvider';
import { AccessControlProvider } from '@refinedev/core';
import jwt_decode from 'jwt-decode';
import { Role } from './enums/role';
import { IUser } from './interfaces/user';

const token = localStorage.getItem(TOKEN_KEY);
let payload: IUser.IPayload = {};
if (token) {
    try {
        payload = jwt_decode(token);
    } catch (error) {
        console.error(error);
    }
}
export const userRoles = payload?.roles || [];
export const userPermissions = payload?.rights || [];
export const isSa = userRoles.includes(Role.SA_ROLE);
export const isTenantAdmin = userRoles.includes(Role.TENANT_ADMIN_ROLE);
export const resourceMappings: Record<string, string> = {
    'v1/tenants': 'tenant',
    'v1/partners': 'wl_partner',
    'v1/partner': 'wl_partner',
    'v1/contacts': 'wl_contact',
    'v1/quotes': 'wl_quote',
    'v1/orders': 'wl_order',
    'v1/invoices': 'wl_invoice',
    'v1/services': 'wl_service',
    'v1/products': 'wl_service',
    'v1/sale-subscription': 'wl_recurringInvoice',
    business_setting: 'setting',
    general_setting: 'setting',
    billing_setting: 'billing',
    report_revenue: 'dashboard',
    'v1/tenant/staff': 'user_manager',
    'v1/users/payment-methods': 'billing',
    'v1/application-subscriptions': 'app_subscription',
    'v1/application-subscriptions/tenant': 'app_subscription',
    'v1/partner/user': 'wl_partner',
    'v1/tickets': 'wl_helpdesk',
    'v1/documents': 'wl_resource',
    'v1/folders': 'wl_resource',
    'v1/contact-notes': 'wl_contact',
};

export const accessControlProvider: AccessControlProvider = {
    can: async ({ resource, action, params }) => {
        let canAccess = false;
        const targetResource: string =
            resource && resourceMappings[resource]
                ? resourceMappings[resource]
                : (resource as string);
        if (NO_PERMISSION_ITEMS.includes(targetResource)) {
            canAccess = true;
        } else {
            const right = `${targetResource}_${action}`;
            canAccess = userPermissions?.includes(right) || false;
        }
        return Promise.resolve({
            can: canAccess,
            reason: !canAccess ? 'Unauthorized' : undefined,
        });
    },
};
