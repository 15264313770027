import './styles.scss';

import { COLORS_CHART, DATE_FORMAT } from '@constants/index.constant';
import { IReport } from '@interfaces/report';
import { useTranslate } from '@refinedev/core';
import { formatDate } from '@utils/resource';
import { DatePicker, Empty, Radio } from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

export type DataOverviewProps = {
    dataReportCommission: IReport.IRevenue[];
    dataReportEarning: IReport.IRevenue[];
};

const OverviewCrmChart: React.FC<{
    data?: DataOverviewProps;
    title: string;
    valueDateRange: [dayjs.Dayjs, dayjs.Dayjs];
    valueGranularity: string;
    onChangeDateRange: (dayString: string[]) => void;
    onChangeGranularity: (value: string) => void;
}> = ({
    data,
    title,
    onChangeDateRange,
    onChangeGranularity,
    valueDateRange,
    valueGranularity,
}) => {
    const { t: translate } = useTranslation(['common']);

    const [granularity, setGranularity] = useState<string>('day');

    const optionsGranularity = [
        { label: translate('reports.day'), value: 'day' },
        { label: translate('reports.week'), value: 'week' },
        { label: translate('reports.month'), value: 'month' },
    ];

    const ranges: any = {
        [translate('reports.rangeDate.yesterday')]: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],

        [translate('reports.rangeDate.today')]: [dayjs(), dayjs()],

        [translate('reports.rangeDate.last7Days')]: [dayjs().add(-7, 'd'), dayjs()],

        [translate('reports.rangeDate.last14Days')]: [dayjs().add(-14, 'd'), dayjs()],

        [translate('reports.rangeDate.last30Days')]: [dayjs().add(-30, 'd'), dayjs()],

        [translate('reports.rangeDate.last90Days')]: [dayjs().add(-90, 'd'), dayjs()],

        [translate('reports.rangeDate.thisWeek')]: [dayjs().startOf('week'), dayjs()],

        [translate('reports.rangeDate.thisMonth')]: [dayjs().startOf('month'), dayjs()],

        [translate('reports.rangeDate.thisYear')]: [dayjs().startOf('year'), dayjs()],
    };

    const options = {
        color: COLORS_CHART,

        grid: {
            left: 0,
            containLabel: true,
            top: 50,
            right: 0,
        },
        legend: {},
        xAxis: {
            type: 'category',
            data: data?.dataReportEarning?.map((item) => {
                if (granularity !== 'month') {
                    return formatDate(item.createdAt);
                }
                return dayjs(item.createdAt).format('MMM YYYY');
            }),
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: translate('reports.earning'),
                data: data?.dataReportEarning?.map((item) => (item.totalAmount / 100).toFixed(2)),
                type: 'bar',
                smooth: true,
                stack: 'total',
                label: {
                    show: false,
                },
                emphasis: {
                    focus: 'series',
                },
            },
            {
                name: translate('reports.commission'),
                data: data?.dataReportCommission?.map((item) =>
                    (item.totalAmount / 100).toFixed(2),
                ),
                type: 'bar',
                smooth: true,
                stack: 'total',
                label: {
                    show: false,
                },
                emphasis: {
                    focus: 'series',
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value: number) => '$' + value,
        },
    };

    return (
        <>
            <h3 className="title-section">{title} </h3>
            <div className="filter-wrapper">
                <div className="filter-range-date custom-date-range " id="rangeDateArea">
                    <RangePicker
                        allowClear={false}
                        ranges={ranges}
                        disabledDate={(date) => +date > +dayjs()}
                        value={valueDateRange}
                        onChange={(event: any, dayString: string[]) => onChangeDateRange(dayString)}
                        getPopupContainer={() =>
                            document.getElementById('rangeDateArea') as HTMLElement
                        }
                        format={DATE_FORMAT}
                    />
                </div>
                <div className="filter-by-time">
                    <Radio.Group
                        className="select-time-filter"
                        buttonStyle="solid"
                        options={optionsGranularity}
                        optionType="button"
                        value={valueGranularity}
                        onChange={(e) => {
                            setGranularity(e.target.value);
                            onChangeGranularity(e.target.value);
                        }}
                    />
                </div>
            </div>
            {data && Object.keys(data).length ? <ReactECharts option={options} /> : <Empty />}
        </>
    );
};
export default OverviewCrmChart;
