import { DATE_FORMAT } from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { formatDate } from '@utils/resource';
import { DatePicker, Empty, Radio, Select } from 'antd';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

type DataLineChart = {
    createdAt: string;
    totalAmount: number;
};

export const LineCrmChart: React.FC<{
    data: DataLineChart[];
    title: string;
    isFormatData?: boolean;
    valueDateRange: [dayjs.Dayjs, dayjs.Dayjs];
    valueGranularity: string;
    onChangeDateRange: (dayString: string[]) => void;
    onChangeGranularity: (value: string) => void;
    onChangeApplication?: (value: string) => void;
    applications?: IMarketplace.IApplication[];
}> = ({
    data,
    title,
    applications,
    isFormatData = true,
    valueDateRange,
    valueGranularity,
    onChangeDateRange,
    onChangeGranularity,
    onChangeApplication,
}) => {
    const { t: translate } = useTranslation(['common']);
    const [granularity, setGranularity] = useState<string>('day');

    const optionsGranularity = [
        { label: translate('reports.day'), value: 'day' },
        { label: translate('reports.week'), value: 'week' },
        { label: translate('reports.month'), value: 'month' },
    ];

    const ranges: any = {
        [translate('reports.rangeDate.yesterday')]: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],

        [translate('reports.rangeDate.today')]: [dayjs(), dayjs()],

        [translate('reports.rangeDate.last7Days')]: [dayjs().add(-7, 'd'), dayjs()],

        [translate('reports.rangeDate.last14Days')]: [dayjs().add(-14, 'd'), dayjs()],

        [translate('reports.rangeDate.last30Days')]: [dayjs().add(-30, 'd'), dayjs()],

        [translate('reports.rangeDate.last90Days')]: [dayjs().add(-90, 'd'), dayjs()],

        [translate('reports.rangeDate.thisWeek')]: [dayjs().startOf('week'), dayjs()],

        [translate('reports.rangeDate.thisMonth')]: [dayjs().startOf('month'), dayjs()],

        [translate('reports.rangeDate.thisYear')]: [dayjs().startOf('year'), dayjs()],
    };
    const options = {
        grid: {
            left: 0,
            containLabel: true,
            top: 50,
            right: 0,
        },
        xAxis: {
            type: 'category',
            data: data?.map((item) => {
                if (granularity !== 'month') {
                    return formatDate(item.createdAt);
                }
                return dayjs(item.createdAt).format('MMM YYYY');
            }),
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: isFormatData
                    ? data?.map((item) => (item.totalAmount / 100).toFixed(2))
                    : data?.map((item) => item.totalAmount.toString()),
                type: 'line',
                smooth: true,
                symbolSize: 6,
                areaStyle: {
                    opacity: 0.2,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#7F56D9',
                        },
                        {
                            offset: 1,
                            color: 'rgba(127, 86, 217, 0)',
                        },
                    ]),
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value: number) => (isFormatData ? '$' + value : value),
        },
    };

    return (
        <>
            <h3 className="title-section">{title} </h3>
            <div className="filter-wrapper ">
                <div className="filter-range-date filter-wrapper-container" id="rangeDateArea">
                    <RangePicker
                        allowClear={false}
                        ranges={ranges}
                        disabledDate={(date) => +date > +dayjs()}
                        onChange={(e: any, dayString: string[]) => onChangeDateRange(dayString)}
                        value={valueDateRange}
                        getPopupContainer={() =>
                            document.getElementById('rangeDateArea') as HTMLElement
                        }
                        format={DATE_FORMAT}
                    />
                </div>
                <div className="filter-by-time">
                    <Radio.Group
                        className="select-time-filter"
                        buttonStyle="solid"
                        options={optionsGranularity}
                        optionType="button"
                        value={valueGranularity}
                        onChange={(e) => {
                            setGranularity(e.target.value);
                            onChangeGranularity(e.target.value);
                        }}
                    />
                </div>
            </div>

            {onChangeApplication && applications && (
                <div className="filter-by-application">
                    <Select
                        placeholder={'Select application'}
                        showArrow
                        allowClear
                        suffixIcon={<img src="/images/icons/arrow-down-icon.svg" />}
                        onChange={(value) => {
                            onChangeApplication(value);
                        }}
                    >
                        {applications.map((app, index) => (
                            <Select.Option key={index} value={app.id}>
                                {app.title}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}

            {data?.length ? <ReactECharts option={options} /> : <Empty />}
        </>
    );
};
