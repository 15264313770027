import './styles.scss';

import { SettingAccountPreferences } from 'components/setting/setting-account-preferences/setting-account-preferences';
import { SettingCompanyProfile } from 'components/setting/setting-company-profile/setting-company-profile';
import React from 'react';

interface IGeneralSettingProps {
    name?: string;
}

export const GeneralSetting: React.FC<IGeneralSettingProps> = () => {
    return (
        <>
            <SettingCompanyProfile />
        </>
    );
};
