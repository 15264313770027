import './styles.scss';

import { Col, Row, Spin } from 'antd';
import { useCustomMutation, useTable } from '@refinedev/core';
import { useTranslation } from 'react-i18next';

import { NuviooSiderIcon } from '../../../components/layout/sidebar/icons/nuvioo-icon';
import { PosSiderIcon } from '../../../components/layout/sidebar/icons/pos-icon';
import { WhiteLabelSiderIcon } from '../../../components/layout/sidebar/icons/wl-icon';
import { FAV_URL_KEY } from '../../../constants/index.constant';
import { getEnvConfig } from '../../../getEnvConfig';
import { INotification } from '../../../interfaces/notification';
import { fromNow } from '../../../utils/date';

export const LatestNotificationIndex: React.FC = () => {
    const { t } = useTranslation(['notification', 'common']);
    const { tableQueryResult: notificationList } = useTable<INotification.INotification>({
        dataProviderName: 'notification',
        resource: `v1/notifications`,
        initialPageSize: 5,
        initialCurrent: 1,
        hasPagination: true,
    });
    const notificationApiUrl = getEnvConfig.NOTIFICATION_URL;

    const { mutate } = useCustomMutation();

    const favicon = localStorage.getItem(FAV_URL_KEY);

    const renderIcon = (notification: INotification.INotification) => {
        switch (notification.module) {
            case 'pos':
                return <PosSiderIcon />;
            case 'wl':
                return <WhiteLabelSiderIcon />;
            default:
                return <NuviooSiderIcon url={favicon || ''} />;
        }
    };

    const onNotificationClick = async (notification: INotification.INotification) => {
        if (!notification.isRead) {
            mutate({
                url: `${notificationApiUrl}/v1/notifications/${notification.id}/mark-as-read`,
                method: 'put',
                values: {},
            });
            notification.isRead = true;
        }

        if (notification.route) {
            window.location.href = notification.route;
        }
    };

    return (
        <>
            {notificationList?.data?.data?.length ? (
                <section className="latest-notification-container">
                    <h1 className="title mb-6">
                        {t('dashboard.latest_notification', { ns: 'common' })}
                    </h1>

                    <Spin spinning={false}>
                        <section className="notification-list-container">
                            <div className="notification-list-wrapper">
                                <Row gutter={[0, 8]}>
                                    {notificationList.data?.data?.map((notification, index) => (
                                        <Col
                                            key={index}
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            className="notification-item"
                                            onClick={() => onNotificationClick(notification)}
                                        >
                                            <div className="flex items-center">
                                                <div className="img-container">
                                                    {renderIcon(notification)}
                                                </div>
                                                <div className="notification-content">
                                                    <div
                                                        className="text"
                                                        dangerouslySetInnerHTML={{
                                                            __html: t(
                                                                `${notification.module}.${notification.title}`,
                                                                notification.data,
                                                            ),
                                                        }}
                                                    ></div>
                                                    <div className="date">
                                                        {fromNow(notification.createdAt)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </section>
                    </Spin>
                </section>
            ) : null}
        </>
    );
};
