import './styles.scss';

import { useSelect } from '@refinedev/antd';
import { BaseKey, CrudFilters, useOne } from '@refinedev/core';
import { Select, SelectProps } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContact } from '../../../interfaces/contact';

export const SelectContact: React.FC<
    SelectProps & {
        partnerId?: string | null;
        contactId?: string | null;
        isOnlyContact?: boolean;
        dataProviderName?: DataProviderNameEnum;
    }
> = (props) => {
    const { contactId, isOnlyContact = false } = props;
    const { t } = useTranslation(['common']);
    const [pageIndex, setPageIndex] = useState(1);
    const [currentData, setCurrentData] = useState<IContact.IContact[]>([]);
    const { dataProviderName = DataProviderNameEnum.DEFAULT } = props;
    const [filters, setFilters] = useState<CrudFilters>([
        {
            field: 'isOnlyContact',
            operator: 'eq',
            value: isOnlyContact,
        },
    ]);
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

    const { data: contactDetail, refetch: refetchContactDetail } = useOne<IContact.IContact>({
        resource: 'v1/contacts',
        id: contactId as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    const { selectProps, queryResult } = useSelect<IContact.IContact>({
        dataProviderName,
        resource: 'v1/contacts',
        optionLabel: 'fullName',
        optionValue: 'id',
        debounce: 500,
        onSearch: (value) => {
            setPageIndex(1);
            setCurrentData([]);
            setFilters([
                {
                    field: 'filter',
                    operator: 'eq',
                    value,
                },
            ]);

            if (props.partnerId) {
                filters.push({
                    field: 'partnerId',
                    operator: 'eq',
                    value: value,
                });
            }
            return [];
        },
        filters: props.partnerId
            ? [
                  ...filters,
                  {
                      field: 'partnerId',
                      operator: 'eq',
                      value: props.partnerId,
                  },
              ]
            : filters,

        pagination: {
            mode: 'server',
            current: pageIndex,
        },
        queryOptions: {
            onSuccess: (data) => {
                setCurrentData(currentData.concat(data.data));
            },
        },
        sort: [
            {
                field: 'name',
                order: 'asc',
            },
        ],
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_contacts'),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_contacts_failed'),
                };
            }
        },
    });

    useEffect(() => {
        if (contactId) {
            refetchContactDetail();
        }
    }, [contactId]);

    useEffect(() => {
        if (contactDetail?.data) {
            setOptions([
                {
                    value: contactDetail.data.id,
                    label: contactDetail.data.fullName ?? '',
                },
            ]);
        }
    }, [contactDetail?.data]);

    return (
        <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            {...selectProps}
            placeholder="Select Contact"
            {...props}
            allowClear
            className="select-contact"
            onPopupScroll={async (e: any) => {
                const { target } = e;
                if (
                    (target as any).scrollTop + (target as any).offsetHeight ===
                    (target as any).scrollHeight
                ) {
                    if (
                        queryResult.data &&
                        currentData.length < queryResult.data?.total &&
                        !queryResult.isRefetching &&
                        !queryResult.isFetching
                    ) {
                        setPageIndex(pageIndex + 1);
                        await queryResult.refetch();
                    }
                }
            }}
            options={
                contactId
                    ? options
                    : currentData.map((item) => ({
                          value: item.id,
                          label: item.fullName,
                      }))
            }
            loading={queryResult.isRefetching || queryResult.isFetching}
            onClear={() => {
                setPageIndex(1);
                setCurrentData([]);

                if (!isOnlyContact) setFilters([]);
                else {
                    setFilters([
                        {
                            field: 'isOnlyContact',
                            operator: 'eq',
                            value: isOnlyContact,
                        },
                    ]);
                    queryResult.refetch();
                }

                if (!filters.length) {
                    queryResult.refetch();
                }
            }}
        />
    );
};
