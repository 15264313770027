import { Content, ContentTable } from 'pdfmake/interfaces';

import { QuoteStatus } from '@enums/quote-status.enum';
import { formatPrice, formatPriceBaseOnCurrency } from '@utils/resource';
import { maskPhone } from '@utils/string';

export const renderSubscriptionSummary = (data: any, fn: (rs: string, obj: any) => string) => {
    const columnWidth = `${100 / 3}%`;
    const columnGap = 6;

    const content: Content[] = [
        {
            columns: [
                {
                    width: '100%',
                    columns: [
                        {
                            width: columnWidth,
                            stack: [
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: 'auto',
                                            text: `${fn('quotes.heading.createdBy', {
                                                ns: 'quote',
                                            })}`,
                                            style: 'label',
                                            alignment: 'left',
                                            marginBottom: 5,
                                        },
                                    ],
                                },
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: 'auto',
                                            text: `${data.createdByUser.firstName} ${data.createdByUser.lastName}`,
                                            style: 'text',
                                            alignment: 'left',
                                            marginBottom: 5,
                                        },
                                    ],
                                },
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: 'auto',
                                            text: `${data.createdByUser.email}`,
                                            style: 'label',
                                            alignment: 'left',
                                            marginBottom: 5,
                                        },
                                    ],
                                },
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: 'auto',
                                            text: `${maskPhone(data.createdByUser.phone)}`,
                                            style: 'label',
                                            alignment: 'left',
                                            marginBottom: 10,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            width: columnWidth,
                            stack: [
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: 'auto',
                                            text: ``,
                                            style: 'label',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            width: columnWidth,
                            stack: [
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: '*',
                                            text: `${fn('quotes.heading.for', { ns: 'quote' })}`,
                                            style: 'label',
                                            alignment: 'right',
                                            marginBottom: 5,
                                        },
                                    ],
                                },
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: '*',
                                            text: `${data.contactName}`,
                                            style: 'text',
                                            alignment: 'right',
                                            marginBottom: 5,
                                        },
                                    ],
                                },
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: '*',
                                            text: `${data.contactEmail}`,
                                            style: 'label',
                                            alignment: 'right',
                                            marginBottom: 5,
                                        },
                                    ],
                                },
                                {
                                    columnGap: columnGap,
                                    columns: [
                                        {
                                            width: '*',
                                            text: `${maskPhone(data.contactPhone)}`,
                                            style: 'label',
                                            alignment: 'right',
                                            marginBottom: 10,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    return content;
};

export const renderSubscriptionTable = (data: any, fn: (rs: string, obj: any) => string) => {
    const renderStatus = (frequency: string) => {
        const label = fn('subscriptions.frequency.' + frequency.toString(), {
            ns: 'common',
        });
        return label;
    };

    const tableData: ContentTable = {
        style: 'tableExample',
        table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', '*', 'auto'],
            body: [
                [
                    {
                        text: fn('quotes.fields.item.label', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('quotes.table.card', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('subscriptions.frequencyTitle', { ns: 'common' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('quotes.fields.qty.label', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('quotes.fields.total.label', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                ],
            ],
        },
    };

    tableData?.table.body.push([
        //
        {
            text: data.items.productName,
            style: 'normalText',
        },
        //
        {
            text: data?.card ? `*** ***${data?.card?.last4}` : '--',
            style: 'normalText',
        },
        //
        {
            text: renderStatus(data?.items.frequency),
            style: 'normalText',
        },
        //
        {
            text: data.items.qty,
            style: 'normalText',
        },
        //
        {
            text: formatPriceBaseOnCurrency({
                price: +data.amount || 0,
            }),
            style: 'normalText',
        },
    ]);

    return tableData;
};
