import './styles.scss';

import { PERMISSIONS } from '@constants/permission';
import { IUser } from '@interfaces/user';
import { BaseKey, useGetIdentity, useNavigation, useOne, usePermissions } from '@refinedev/core';
import { Button, Col, Row } from 'antd';
import { UserTypeEnum } from 'enums/user-type.enum';
import { useTranslation } from 'react-i18next';
import { maskPhone } from 'utils/string';

import { useParams } from 'react-router-dom';
import { QuoteStatus } from '../../enums/quote-status.enum';
import { IOrder } from '../../interfaces/order';
import { formatDate, formatPrice } from '../../utils/resource';

export const OrderDetailsIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const { id } = useParams();
    const { goBack, push } = useNavigation();

    const { data: currentUser } = useGetIdentity<IUser.IUserDto>();
    const { data: permissions } = usePermissions<string[]>();

    const { data: orderData } = useOne<IOrder.OrderDetails>({
        resource: 'v1/orders',
        id: id as BaseKey,
    });

    const order: IOrder.OrderDetails | undefined = orderData?.data;

    const getTotalPrice = () => {
        if (orderData?.data?.items) {
            let total = 0;
            const orderList = orderData?.data?.items || [];
            for (const item of orderList) {
                total += item.total;
            }

            return total;
        }
        return 0;
    };

    const pay = () => {
        if (order?.invoiceId) {
            push(`/wl/invoices/show/${order?.invoiceId}`);
        }
    };

    const showPrice =
        order?.createdByUser?.id !== currentUser?.id ||
        currentUser?.userType !== UserTypeEnum.CLIENT;

    const isPlanned = (order?.items || []).some((orderItem) => (orderItem?.percentage ?? 0) > 0);

    const canViewInvoice = () => {
        return (
            permissions?.includes(PERMISSIONS.WL_INVOICE_SHOW) &&
            order?.invoiceId &&
            order?.status === QuoteStatus.Pending &&
            // contact, partner
            ((order?.createdByUser?.id === currentUser?.id &&
                currentUser?.userType !== UserTypeEnum.TENANT) ||
                order?.userId === currentUser?.id ||
                //staff of wl partner can view invoice
                (currentUser?.userType === UserTypeEnum.WHITELABEL &&
                    !order.contactId &&
                    order.tenantId === currentUser.tenantId))
        );
    };

    return (
        <>
            <section className="block-container">
                <div className="block-heading">
                    <div>
                        <div>
                            <span>
                                {t('quotes.heading.orderDetails')}: {order?.incrementId}
                            </span>
                        </div>
                        <div>
                            <span className="date-title">
                                {t('quotes.fields.createdDate.label')} -{' '}
                                {formatDate(order?.createdAt, 'MMM DD,YYYY')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="details-section">
                    <Row gutter={20}>
                        <Col md={12}>
                            <div className="user-info">
                                <div className="info-header">{t('quotes.heading.orderedBy')}</div>
                                <div className="name">
                                    {(order?.createdByUser?.firstName ?? '') +
                                        ' ' +
                                        (order?.createdByUser?.lastName ?? '')}
                                </div>
                                <div>{order?.createdByUser?.email ?? ''}</div>
                                <div>
                                    {order?.createdByUser?.phone
                                        ? maskPhone(order?.createdByUser?.phone)
                                        : ''}
                                </div>
                            </div>
                        </Col>

                        {order?.onBehalfUser ? (
                            <Col md={12} className="user-info">
                                <div className="user-info text-right">
                                    <div className="info-header">{t('quotes.heading.for')}</div>
                                    <div className="name">
                                        {(order.onBehalfUser.firstName ?? '') +
                                            ' ' +
                                            (order.onBehalfUser.lastName ?? '')}
                                    </div>
                                    <div>{order.onBehalfUser.email ?? ''}</div>
                                    <div>
                                        {order.onBehalfUser.phone
                                            ? maskPhone(order.onBehalfUser.phone)
                                            : ''}
                                    </div>
                                </div>
                            </Col>
                        ) : null}
                    </Row>
                </div>
                <div className="details-section">
                    <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                    <Row className="item-list">
                        <Col span={24}>
                            <Row className="table-header">
                                <Col span={isPlanned ? 4 : 8}>{t('quotes.fields.item.label')}</Col>
                                <Col span={4}>{t('quotes.fields.unitPrice.label')}</Col>
                                <Col span={4}>{t('quotes.fields.qty.label')}</Col>
                                <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                                {isPlanned ? (
                                    <Col span={4}>{t('quotes.fields.percentage.label')}</Col>
                                ) : null}
                                <Col span={4}>{t('quotes.fields.total.label')}</Col>
                            </Row>
                            {order &&
                                order?.items?.length > 0 &&
                                order?.items?.map((item) => {
                                    const _percent = item?.percentage ?? 0;
                                    return (
                                        <Row className="list-product-item" key={'list' + item.id}>
                                            <Col span={isPlanned ? 4 : 8}>{item.productName}</Col>
                                            <Col span={4}>
                                                {showPrice
                                                    ? formatPrice(
                                                          _percent > 0
                                                              ? (item?.total * 100) /
                                                                    (_percent * (item?.qty ?? 1))
                                                              : item.total / item.qty,
                                                      )
                                                    : null}
                                            </Col>
                                            <Col span={4}>{item.qty}</Col>
                                            <Col span={4}>
                                                {t('quotes.frequency.' + item.frequency)}
                                            </Col>
                                            {isPlanned ? (
                                                <Col span={4}>
                                                    {item?.percentage ? item?.percentage : ''}
                                                </Col>
                                            ) : null}
                                            <Col span={4}>
                                                {showPrice ? formatPrice(item.total) : null}
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Col>
                    </Row>
                    {showPrice ? (
                        <div className="sum-total flex">
                            <span>{t('quotes.heading.totalPrice')}</span>
                            <span className="ml-auto">{formatPrice(getTotalPrice())}</span>
                        </div>
                    ) : null}
                </div>

                {order?.termsAndConditions && (
                    <div className="details-section">
                        <div className="section-header">{t('quotes.fields.tc.label')}</div>
                        <Row gutter={20}>
                            <Col md={24}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: order?.termsAndConditions as string,
                                    }}
                                ></div>
                            </Col>
                        </Row>
                    </div>
                )}
                <div className="details-section">
                    <div className="flex flex-row-reverse">
                        {canViewInvoice() ? (
                            <Button onClick={pay} type="primary" className="ml-4">
                                {t('quotes.actions.invoice')}
                            </Button>
                        ) : null}
                        <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                    </div>
                </div>
            </section>
        </>
    );
};
