import './styles.scss'; // Import your SCSS file

import { NUserPayment } from '@interfaces/user-payment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type SalePayoutInfoProps = {
    salePayout: NUserPayment.IUserPaymentDto;
};

export const SalePayoutInfo: FC<SalePayoutInfoProps> = ({ salePayout }) => {
    const { t } = useTranslation('common');
    return (
        <div className="edit-payment-container">
            <div className="edit-payment-header">
                <h2>{t('crm_internal.payout_setting.payment_detail')}</h2>
            </div>

            <div className="form-section">
                <div className="form-group">
                    <label htmlFor="nameOnCard">
                        {t('crm_internal.payout_setting.name_on_card')}
                    </label>
                    <input
                        type="text"
                        id="nameOnCard"
                        value={salePayout.configuration?.paymentInstruments?.[0].name || ''}
                        readOnly
                    />
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="cardNumber">
                            {t('crm_internal.payout_setting.masked_account_number')}
                        </label>
                        <div className="card-number-container">
                            <input
                                type="text"
                                id="cardNumber"
                                value={
                                    salePayout.configuration?.paymentInstruments?.[0]
                                        .maskedAccountNumber || ''
                                }
                                readOnly
                            />
                            <img
                                src="/images/icons/payment-card.svg"
                                alt="logo"
                                className="card-logo"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="cvv">{t('crm_internal.payout_setting.bank_code')}</label>
                        <input
                            type="text"
                            id="cvv"
                            value={salePayout.configuration?.paymentInstruments?.[0].bankCode || ''}
                            readOnly
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="cardExpire">
                            {t('crm_internal.payout_setting.enabled')}
                        </label>
                        <input
                            type="text"
                            id="cardExpire"
                            value={
                                salePayout.configuration?.paymentInstruments?.[0].enabled
                                    .toString()
                                    .toUpperCase() || ''
                            }
                            readOnly
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="cvv">{t('crm_internal.payout_setting.status')}</label>
                        <input
                            type="text"
                            id="cvv"
                            value={salePayout.configuration.status}
                            readOnly
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="country">{t('crm_internal.payout_setting.merchant_id')}</label>
                    <input type="text" id="country" value={salePayout.merchantId} readOnly />
                </div>
            </div>
        </div>
    );
};
