import './styles.scss';

import { EditButton } from '@refinedev/antd';
import { useNavigation } from '@refinedev/core';
import { Dropdown, Menu } from 'antd';

import { PERMISSIONS } from '../../constants/permission';
import { IMarketplace } from '../../interfaces/marketplace';
import { convertPrice, getPublicMediaUrl } from '../../utils/resource';

type ApplicationItemProps = {
    application: IMarketplace.IApplication;
    permission: string[];
    ignoreFooter?: boolean;
    customClasses?: string;
};

export const ApplicationItem: React.FC<ApplicationItemProps> = ({
    application,
    permission,
    ignoreFooter,
    customClasses,
}) => {
    const { show } = useNavigation();
    const canShow = permission.indexOf(PERMISSIONS.MARKETPLACE_SHOW) !== -1;

    const formatDescription = (description: string) => {
        const regex = /style="(.*?)"/gm;
        const str = description;
        const strReplace = ``;
        const result = str.replace(regex, strReplace);
        return result;
    };

    application.description = formatDescription(application.description);
    return (
        <>
            <div className={`application-item-page-list ${customClasses ? customClasses : ''}`}>
                <div
                    className="detail-application"
                    onClick={() => canShow && show('marketplace', application.id)}
                >
                    <div className="category">
                        <div className="icon"></div>
                        <p className="category-name">{application.category?.name}</p>
                    </div>
                    <div className="avatar">
                        <img
                            src={getPublicMediaUrl(application.avatar)}
                            alt="avatar"
                            className="avatar-image"
                        />
                    </div>
                    <div className="title">{application.title}</div>
                    <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: application.description }}
                    />
                </div>

                {!ignoreFooter ? (
                    <>
                        <hr onClick={() => canShow && show('marketplace', application.id)} />
                        <div className="footer-item">
                            <div className="price-wrapper">
                                <img
                                    src="/images/icons/price.svg"
                                    alt="price"
                                    className="icon-price"
                                />
                                <p className="value-price">
                                    {convertPrice(application.plans[0].pricing)}
                                </p>
                            </div>
                            {permission.indexOf(PERMISSIONS.MARKETPLACE_EDIT) !== -1 && (
                                <Dropdown
                                    overlayClassName="service-dropdown-container"
                                    placement="bottomRight"
                                    overlay={
                                        <Menu mode="vertical">
                                            <Menu.Item
                                                key="2"
                                                icon={<EditButton recordItemId={application.id} />}
                                            ></Menu.Item>
                                        </Menu>
                                    }
                                    trigger={['click']}
                                >
                                    <img
                                        src="/images/icons/dots-vertical.svg"
                                        alt="dots-vertical"
                                        className="action-icon"
                                    />
                                </Dropdown>
                            )}
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};
