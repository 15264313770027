import './styles.scss';

import { Button, Col, Row, Spin } from 'antd';
import { useList, useNavigation } from '@refinedev/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IMarketplace } from '../../../interfaces/marketplace';
import { getPublicMediaUrl } from '../../../utils/resource';

const APPLICATION_RESOURCE_URL = 'v1/application';

export const ApplicationIndex: React.FC = () => {
    const { t } = useTranslation('common');
    const [applications, setApplications] = useState<IMarketplace.IApplication[]>([]);
    const { show } = useNavigation();

    const { data: listApplications, isLoading } = useList<IMarketplace.IApplication>({
        resource: `${APPLICATION_RESOURCE_URL}`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'includeUnpublish',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });

    useEffect(() => {
        if (listApplications?.data) {
            setApplications(listApplications.data);
        }
    }, [listApplications]);

    const formatDescription = (description: string) => {
        const regex = /style="(.*?)"/gm;
        const str = description;
        const strReplace = ``;
        const result = str.replace(regex, strReplace);
        return result;
    };

    return (
        <>
            {applications ? (
                <section className="application-container">
                    <h1 className="title">{t('dashboard.application')}</h1>
                    <Spin spinning={isLoading}>
                        <section className="application-list-container">
                            <div className="list-application-wrapper">
                                <Row gutter={[22, 22]}>
                                    {applications?.map((application, index) => (
                                        <Col
                                            key={index}
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={12}
                                            xxl={12}
                                            className="application-item-container"
                                        >
                                            <div className="application-item">
                                                <div className="detail-application">
                                                    <div className="avatar">
                                                        <img
                                                            src={getPublicMediaUrl(
                                                                application.avatar,
                                                            )}
                                                            alt="avatar"
                                                            className="avatar-image"
                                                        />
                                                    </div>
                                                    <div className="title">{application.title}</div>
                                                    <div
                                                        className="description"
                                                        dangerouslySetInnerHTML={{
                                                            __html: formatDescription(
                                                                application.description,
                                                            ),
                                                        }}
                                                    />
                                                    <Button
                                                        icon={false}
                                                        className="application-detail-button"
                                                        type="primary"
                                                        onClick={() =>
                                                            show('marketplace', application.id)
                                                        }
                                                    >
                                                        <span>{t('dashboard.view_more')}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </section>
                    </Spin>
                </section>
            ) : null}
        </>
    );
};
