import './styles.scss';

import { CameraOutlined } from '@ant-design/icons';
import { useApiUrl } from '@refinedev/core';
import { Button, message, Spin } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import Upload from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPublicMediaUrl } from '../../../utils/resource';
import { Hint } from '../hint';

export const ImageUpload: React.FC<{
    onChange?: (file: UploadFile<any> | null) => void;
    size?: number;
    width: number;
    height: number;
    quality?: number;
    uploadText: string;
    value?: string;
    isTemporary?: boolean;
    shape?: 'rect' | 'round';
    isReadonly?: boolean;
    hideIconAfterUpload?: boolean;
    horizonUploadButton?: boolean;
    isLoading?: boolean;
    setIsUpdateImage?: (value: boolean) => void;
}> = ({
    onChange,
    size = 5,
    width,
    height,
    quality = 0.8,
    uploadText,
    value,
    isTemporary,
    shape,
    isReadonly,
    hideIconAfterUpload,
    horizonUploadButton,
    isLoading = false,
    setIsUpdateImage,
}) => {
    const { t } = useTranslation('common');

    const [imageUrl, setImageUrl] = useState<string | null>();
    const [file, setFile] = useState<UploadFile<any> | null>();

    useEffect(() => {
        if (value) {
            setImageUrl(getPublicMediaUrl(value));
        } else {
            setImageUrl(null);
        }
    }, [value]);

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt = file.size / 1024 / 1024 < size;
        if (!isLt) {
            message.error(`Image must be smaller than ${size}MB!`);
        }
        if (!(isJpgOrPng && isLt)) {
            return false;
        }

        getBase64(file, (url) => {
            setImageUrl(url);
            // onChange && onChange(file);
        });

        return true;
    };

    const apiUrl = useApiUrl();
    const token = localStorage.getItem('access_token');

    return (
        <ImgCrop
            modalTitle={t('common.edit_image')}
            modalOk={t('common.ok')}
            modalCancel={t('common.cancel')}
            minZoom={0.1}
            aspect={width / height}
            quality={quality || 0.8}
            cropShape={shape}
            fillColor={'transparent'}
            onModalCancel={() => {
                setIsUpdateImage && setIsUpdateImage(false);
            }}
        >
            <Upload
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                action={`${apiUrl}/v1/resource/${
                    isTemporary ? 'upload-temporary-image' : 'upload-image'
                }`}
                disabled={isReadonly}
                headers={{ Authorization: `Bearer ${token}` }}
                onChange={(info) => {
                    if (info?.file?.status === 'done') {
                        onChange && onChange(info?.file);
                    }
                }}
                maxCount={1}
            >
                <Spin spinning={isLoading}>
                    <div
                        className={`relative h-full w-full ${
                            horizonUploadButton ? 'horizon-upload-button' : ''
                        }`}
                    >
                        {imageUrl && (
                            <img
                                src={imageUrl}
                                alt="img"
                                className="rounded-xl img w-auto h-full m-auto"
                            />
                        )}
                        {!!imageUrl && hideIconAfterUpload ? null : (
                            <div className="upload">
                                {horizonUploadButton && (
                                    <span className="text-add">Add Product Photo/Logo</span>
                                )}
                                <Button className="btn-upload">
                                    <CameraOutlined /> {uploadText}
                                </Button>
                            </div>
                        )}
                    </div>
                    {horizonUploadButton && (
                        <div className="mt-2">
                            <Hint
                                size={size}
                                width={width}
                                height={height}
                                fileType={['JPG', 'PNG']}
                            />
                        </div>
                    )}
                </Spin>
            </Upload>
        </ImgCrop>
    );
};
