import './styles.scss';
export const Chip: React.FC<{
    label?: string;
    type?: string;
}> = ({ label, type }) => {
    return (
        <span className="chip">
            <span className={`content ${type}`}>
                <span className="dot">&nbsp;</span>
                <span className="label">{label}</span>
            </span>
        </span>
    );
};
