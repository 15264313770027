import dayjs from 'dayjs';
import { createContext, FC, ReactNode, useContext, useState } from 'react';

export interface IFilter {
    date: string;
    dateRange: [string, string];
}

type FilterContextProps = {
    filter: IFilter;
    onSetDate: (date: string) => void;
    onSetDateRange: (dateRange: [string, string]) => void;
};

export const FilterContext = createContext<FilterContextProps | string>(
    'useContext should be used inside ContextProvider',
);

export const FilterContextProvider: FC<{
    children: ReactNode;
    defaultDateRange?: [string, string];
}> = ({ children, defaultDateRange }) => {
    const [filter, setFilter] = useState<IFilter>({
        date: dayjs().startOf('d').utc().toISOString(),
        dateRange: defaultDateRange
            ? defaultDateRange
            : [dayjs().startOf('d').toISOString(), dayjs().endOf('d').toISOString()],
    });

    const onSetDateRange = (dateRange: [string, string]) => {
        setFilter((filter) => {
            return { ...filter, ...{ dateRange } };
        });
    };

    const onSetDate = (date: string) => {
        setFilter((filter) => {
            return { ...filter, ...{ date } };
        });
    };

    const value: FilterContextProps = {
        filter,
        onSetDate,
        onSetDateRange,
    };

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilterContext = (): FilterContextProps => {
    const context = useContext(FilterContext);

    if (typeof context === 'string') {
        throw new Error(context);
    }

    return context;
};
