import './styles.scss';

import MainLogo from '@assets/logo.svg';
import { Loading } from '@components/layout/loading';
import VerifyUserRequest from '@data-access/requests/auth/verify-user.request.model';
import Response from '@data-access/responses/response';
import { TenantStatusEnum } from '@enums/tenant-status.enum';
import { UserStatusEnum } from '@enums/user-status.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IUser } from '@interfaces/user';
import { useKeycloak } from '@react-keycloak/web';
import {
    HttpError,
    IResourceComponentsProps,
    useApiUrl,
    useCustomMutation,
    useLogin,
    useLogout,
    useNavigation,
    useNotification,
    useTranslate,
} from '@refinedev/core';
import { Card, Col, Image, Row } from 'antd';
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VERIFIED_CODE = 'verified';

export const StepOneIndex: React.FC<IResourceComponentsProps<unknown>> = ({ options }) => {
    const { mutate: login } = useLogin();
    const { mutate: logout } = useLogout();
    const { open } = useNotification();
    const { search } = useLocation();
    const { to } = qs.parse(search, {
        ignoreQueryPrefix: true,
    });

    const { keycloak } = useKeycloak();
    const { push } = useNavigation();
    const t = useTranslate();

    const query = useQuery();

    const { mutate, isLoading, error } = useCustomMutation<any>();
    const { mutate: loginMutate } = useCustomMutation<
        Response<{ token: string; refreshToken: string }>,
        HttpError,
        any
    >();

    const apiUrl = useApiUrl();

    const code = query.get('code') || '';

    const completeStepOne = (accessToken: string) => {
        const url = `${apiUrl}/v1/auth/onboarding/tenant/step-three`;
        loginMutate(
            {
                url: url,
                method: 'post',
                values: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, variables, context) => {
                    const redirectUrl = (
                        to ? to : `${window.location.protocol}//${window.location.host}/dashboard`
                    ) as string;
                    login({
                        data: data.data.data,
                        redirectUrl,
                    });
                },
            },
        );
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            const { token } = keycloak;
            const url = `${apiUrl}/v1/auth/onboarding/tenant/step-one`;

            const request: VerifyUserRequest = {
                code: code,
                token: token,
            };
            mutate(
                {
                    url: url,
                    method: 'post',
                    values: request,
                    errorNotification: false,
                },
                {
                    onError: (error, variables, context) => {
                        // An error happened!
                        open &&
                            open({
                                type: 'error',
                                message: t('common:notifications.errors.' + error.message),
                            });
                        const redirectPath = `${window.location.protocol}//${window.location.host}/onboarding/step-one?code=${code}`;
                        setTimeout(() => {
                            logout({ redirectPath });
                        }, 2000);
                    },
                    onSuccess: (data, variables, context) => {
                        const token = data?.data?.data?.token;
                        const payload: Partial<IUser.IUserDto> = jwt_decode(token);
                        try {
                            login({
                                data: data.data.data,
                                preventKcLogout: true, //auto is true
                            });

                            //if user onboarding is cli or ap or tenant staff, skip step two
                            const skipStepTwo =
                                (payload.userType &&
                                    [
                                        UserTypeEnum.CLIENT,
                                        UserTypeEnum.AFFILIATE_PARTNER,
                                        UserTypeEnum.RESELLER,
                                    ].includes(payload.userType)) ||
                                payload?.isStaff;

                            if (skipStepTwo) {
                                completeStepOne(token);
                                return;
                            }

                            // payload status is active, skip step two (user is tenant or wl)
                            if (payload.paymentInfoId || payload.country) {
                                //complete onboarding before
                                push(`/dashboard`);
                                return;
                            }

                            // Apply when tenant is one of WL
                            if (payload.paymentIntentCode) {
                                //complete step two before
                                push(`/onboarding/step-three?code=${payload.paymentIntentCode}`);
                                return;
                            }
                        } catch (err) {
                            console.error(err);
                        }

                        if (payload?.status === UserStatusEnum?.INFOR_UPDATED) {
                            push(`/onboarding/step-three`);
                        } else {
                            push(`/onboarding/step-two`);
                        }
                    },
                },
            );
        } else if (!query?.get(`${VERIFIED_CODE}`)) {
            handleNuviooIdAuthentication();
        }
    }, [keycloak, keycloak.authenticated]);

    const handleNuviooIdAuthentication = () => {
        const redirectUri = `${window.location.protocol}//${window.location.host}/onboarding/step-one?code=${code}&${VERIFIED_CODE}=true`;

        const authType = query.get('authType') ?? 'register';
        switch (authType) {
            case 'login':
                keycloak?.login({ redirectUri: redirectUri });
                break;

            case 'register':
                keycloak?.register({ redirectUri: redirectUri });
                break;
        }
    };

    return (
        <div className="onboarding-step-one-layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col md={12}>
                    {!isLoading && (
                        <div className="onboarding-container">
                            <Card
                                title={<Image src={MainLogo} preview={false} />}
                                headStyle={{ borderBottom: 0 }}
                            >
                                <div className="title">Welcome to Nuvioo</div>
                                <div className="desc">
                                    Digital Transformation Solutions - Your gateway to The Nuvioo
                                    World
                                </div>
                                {/* <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                    onClick={handleNuviooIdAuthentication}
                                >
                                    {t('pages.login.signInId')}
                                </Button> */}
                            </Card>
                        </div>
                    )}
                    {isLoading && <Loading />}
                </Col>
            </Row>
        </div>
    );
};
