import './styles.scss';

import { ApplicationSubscriptionInvoiceList } from '@components/modules/applicaton-subscription-invoice';
import { PERMISSIONS } from '@constants/permission';
import { AppSubscriptionStatusEnum } from '@enums/app-subscription-status.enum';
import { SubscriptionInvoiceStatus } from '@enums/subscription-invoice-status.enum';
import { IMarketplace } from '@interfaces/marketplace';
import { Payment } from '@interfaces/payment';
import { DeleteButton, useModal } from '@refinedev/antd';
import {
    BaseKey,
    HttpError,
    useApiUrl,
    useCustom,
    useCustomMutation,
    useNavigation,
    useOne,
    usePermissions,
} from '@refinedev/core';
import Response from '@responses/response';
import { formatAppSubscriptionPeriodEnd, RECURRING_SUBSCRIPTION_SUBTRACT_DAY } from '@utils/date';
import { convertPrice, getPublicMediaUrl } from '@utils/resource';
import { Button, Card, Col, Modal, notification, Row, Spin, Tag } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isTenantAdmin } from '../../accessControlProvider';
import { authProvider } from '../../authProvider';

const APPLICATION_RESOURCE_URL = 'v1/application';
const APP_SUBSCRIPTION_ENPOINT = 'v1/application-subscriptions';

export const MarketplaceDetailIndex: React.FC = () => {
    const { t } = useTranslation(['common', 'marketplace']);
    const { id } = useParams();
    const { goBack, push } = useNavigation();
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<Payment.IPaymentMethod | null>(
        null,
    );
    const [planId, setPlanId] = useState<string | null>(null);
    const [selectedPlan, setSelectedPlan] = useState<IMarketplace.IApplicationPlan | null>(null);
    const { data: userRights } = usePermissions<string[]>();
    const { modalProps, show, close } = useModal({
        modalProps: {
            onCancel: () => {
                setSelectedPlan(null);
            },
        },
    });

    const [appSubscription, setAppSubscription] = useState<IMarketplace.ISubscription | null>(null);
    const [activePlan, setActivePlan] = useState<IMarketplace.IApplicationPlan | null>(null);
    const apiUrl = useApiUrl();

    const { data: applicationData, isLoading } = useOne<IMarketplace.IApplication>({
        resource: APPLICATION_RESOURCE_URL,
        id: id as BaseKey,
    });

    // Get application subscription
    const {
        data: appSubscriptionData,
        isLoading: isLoadingAppSubscription,
        refetch: appSubscriptionRefetch,
    } = useCustom<Response<IMarketplace.ISubscription>>({
        url: `${apiUrl}/${APP_SUBSCRIPTION_ENPOINT}/tenant/${id}`,
        method: 'get',
        queryOptions: {
            enabled: isTenantAdmin,
        },
    });

    const application: IMarketplace.IApplication | undefined = applicationData?.data;
    const [isLoadings, setIsLoadings] = useState<{
        getPaymentMethods: boolean;
    }>({ getPaymentMethods: false });

    const { mutate: makePaymentWithCard, isLoading: isLoadingMakePaymentWithCard } =
        useCustomMutation<
            Response<{ invoiceId?: string }>,
            HttpError,
            {
                planId: string;
            }
        >();

    const { mutate: refreshTokenMutate } = useCustomMutation<
        Response<{ token: string; refreshToken: string }>,
        HttpError,
        {
            refreshToken: string;
        }
    >();

    const { data: paymentMethods } = useCustom<Payment.IPaymentMethod[]>({
        url: `${apiUrl}/v1/users/payment-methods`,
        config: {
            filters: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: 'finix',
                },
            ],
        },
        method: 'get',
    });
    const onGetStart = async (planId: string) => {
        const plan = application?.plans?.find((item) => item.id === planId);
        setPlanId(planId);
        setSelectedPlan(plan || null);
        // setIsLoadings({
        //     ...isLoadings,
        //     getPaymentMethods: true,
        // });
        show();
        // setIsLoadings({
        //     ...isLoadings,
        //     getPaymentMethods: false,
        // });
    };

    const onChangePaymentMethod = () => {
        push('/setting/billing');
    };

    const handleSubmit = () => {
        if (!planId) {
            return;
        }
        makePaymentWithCard(
            {
                url: `${apiUrl}/${APP_SUBSCRIPTION_ENPOINT}`,
                method: 'post',
                values: {
                    planId,
                },
                errorNotification: false,
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    notification.error({
                        className: 'error-notification',
                        message: '',
                        description: t(`notifications.${error?.response?.data?.error}`, {
                            ns: 'marketplace',
                        }),
                    });
                    return;
                },
                onSuccess: (data, variables, context) => {
                    const result = data?.data;
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('notifications.subscribe_success', {
                                ns: 'marketplace',
                            }),
                        });

                        refreshTokenMutate(
                            {
                                method: 'post',
                                url: `${apiUrl}/v1/auth/refresh-token`,
                                values: {
                                    refreshToken: authProvider.getRefreshToken() as string,
                                },
                            },
                            {
                                onError: (error) => {
                                    // An error happened!
                                    console.error(error);
                                },
                                onSuccess: ({ data }) => {
                                    authProvider.refreshToken(data.data);
                                    close();
                                    window.location.reload();
                                },
                            },
                        );
                    }
                },
            },
        );
    };

    const canApplyTrial = useMemo(() => {
        if (selectedPlan?.hasTrial === true && appSubscription === null) {
            return true;
        }
        return false;
    }, [selectedPlan, appSubscription]);

    useEffect(() => {
        if (appSubscriptionData?.data?.data) {
            setAppSubscription(appSubscriptionData?.data?.data);

            if (
                [AppSubscriptionStatusEnum.Active, AppSubscriptionStatusEnum.Trailing].includes(
                    appSubscriptionData?.data?.data?.status,
                ) &&
                appSubscriptionData?.data?.data?.latestInvoice?.plan
            ) {
                setActivePlan(appSubscriptionData?.data?.data?.latestInvoice?.plan);
            }
        }
    }, [appSubscriptionData]);

    const onCancelSuccess = () => {
        appSubscriptionRefetch();
    };

    useEffect(() => {
        const defaultPaymentMethod = paymentMethods?.data?.find((item) => item.isDefault);
        setDefaultPaymentMethod(defaultPaymentMethod || null);
    }, [paymentMethods]);

    const latestInvoice = appSubscriptionData?.data?.data?.latestInvoice;
    return (
        <>
            <section className="application-detail-container">
                <div className="detail-application-wrapper">
                    <Spin spinning={isLoading || isLoadings.getPaymentMethods}>
                        <div className="back-to-list">
                            <img
                                src="/images/icons/back-to-list.svg"
                                alt="back-to-list"
                                className="back-to-list"
                                onClick={goBack}
                            />
                            <p onClick={goBack} className="text-back">
                                {t('actions.back', { ns: 'marketplace' })}
                            </p>
                        </div>
                        <div className="main-content-wrapper">
                            <Row className="detail-application">
                                <Col
                                    className="avatar-wrapper"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={8}
                                    xxl={6}
                                >
                                    <div className="avatar">
                                        <img
                                            src={getPublicMediaUrl(application?.avatar || '')}
                                            alt="avatar"
                                            className="avatar-image"
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={18}>
                                    <div className="application-information">
                                        <div className="header-card">
                                            <p className="title">{application?.title}</p>
                                            {(userRights || []).indexOf(
                                                PERMISSIONS.WL_MARKETPLACE_EDIT,
                                            ) !== -1 && (
                                                <div className="action">
                                                    <Button
                                                        type="primary"
                                                        onClick={() =>
                                                            push(`/marketplace/edit/${id}`)
                                                        }
                                                    >
                                                        {t('edit', { ns: 'common' })}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="category mt-2">
                                            <p className="name-category">
                                                {application?.category?.name}
                                            </p>
                                        </div>
                                        <div className="description">
                                            <p className="title-description">
                                                {t('marketplace.about', { ns: 'marketplace' })}
                                            </p>
                                            <div
                                                className="content-description"
                                                dangerouslySetInnerHTML={{
                                                    __html: application?.description || '',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="plan-service-wrapper section-item">
                                <div className="plan-service-header">
                                    <p className="plan-title">
                                        {t('marketplace.plan_packages', { ns: 'marketplace' })}
                                    </p>
                                </div>
                                <Row gutter={[32, 32]} className="list-plan">
                                    {application?.plans?.map((plan, i) => (
                                        <Col key={i} xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                                            <div className="item-plan card-item ">
                                                <div className="icon-plan">
                                                    <img
                                                        src="/images/icons/plan.svg"
                                                        alt="dots-vertical"
                                                        className="action-icon"
                                                    />
                                                </div>
                                                <p className="name-plan">{plan.title}</p>
                                                <p className="price">
                                                    {`${convertPrice(plan.pricing)}/${
                                                        plan.period !== 1
                                                            ? plan.period.toString() + ' '
                                                            : ''
                                                    }${plan.frequency.toString()}`}
                                                </p>
                                                {isTenantAdmin && (
                                                    <div className="action">
                                                        <Button
                                                            type="primary"
                                                            loading={isLoadings.getPaymentMethods}
                                                            disabled={
                                                                activePlan !== null ||
                                                                (latestInvoice?.status ===
                                                                    SubscriptionInvoiceStatus.Paid &&
                                                                    [
                                                                        AppSubscriptionStatusEnum.Active,
                                                                        AppSubscriptionStatusEnum.Trailing,
                                                                        AppSubscriptionStatusEnum.Open,
                                                                    ].includes(
                                                                        appSubscription?.status as AppSubscriptionStatusEnum,
                                                                    ))
                                                            }
                                                            onClick={() => onGetStart(plan.id)}
                                                            className={
                                                                activePlan?.id === plan.id
                                                                    ? 'active-subscription'
                                                                    : ''
                                                            }
                                                        >
                                                            {activePlan?.id === plan.id
                                                                ? t('buttons.actived')
                                                                : t('buttons.getStart')}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            {appSubscription && activePlan && (
                                <Card className="active-subscription-plan section-item">
                                    <Row gutter={[32, 16]} justify="space-between">
                                        <Col>
                                            <div className="card-header">
                                                <span className="mr-2">
                                                    {t('subscriptions.current_plan', {
                                                        ns: 'marketplace',
                                                    })}
                                                </span>
                                                {appSubscription.status ===
                                                AppSubscriptionStatusEnum.Trailing ? (
                                                    <Tag color="orange">
                                                        {t('subscriptions.trial', {
                                                            ns: 'marketplace',
                                                        })}
                                                    </Tag>
                                                ) : (
                                                    <Tag color="green">
                                                        {t('subscriptions.active', {
                                                            ns: 'marketplace',
                                                        })}
                                                    </Tag>
                                                )}
                                                {!appSubscription.cancelAtPeriodEnd && (
                                                    <DeleteButton
                                                        size="small"
                                                        successNotification={{
                                                            message: t(
                                                                'notifications.unsubscribed_success',
                                                                {
                                                                    ns: 'marketplace',
                                                                },
                                                            ),
                                                            type: 'success',
                                                        }}
                                                        icon={null}
                                                        confirmOkText={'Confirm'}
                                                        resource="v1/application-subscriptions/tenant"
                                                        recordItemId={id}
                                                        onSuccess={onCancelSuccess}
                                                    >
                                                        {t('buttons.unsubscribe')}
                                                    </DeleteButton>
                                                )}
                                            </div>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row gutter={[32, 16]}>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <span className="active-plan-heading">
                                                        {t('subscriptions.cycle', {
                                                            ns: 'marketplace',
                                                        })}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span className="active-plan-content">
                                                        {t(
                                                            `subscriptions.frequency.${activePlan.frequency.toString()}`,
                                                            { ns: 'marketplace' },
                                                        )}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <span className="active-plan-heading">
                                                        {appSubscription.cancelAtPeriodEnd
                                                            ? t('subscriptions.will_cancel_at', {
                                                                  ns: 'marketplace',
                                                              })
                                                            : t(
                                                                  'subscriptions.next_billing_period',
                                                                  {
                                                                      ns: 'marketplace',
                                                                  },
                                                              )}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span className="active-plan-content">
                                                        {/* System auto add extra 1 day to period end of recurring subscription 
                                                            So need to subtract 1 day when showing in UI for correct data
                                                        */}
                                                        {appSubscription.currentPeriodEnd
                                                            ? formatAppSubscriptionPeriodEnd(
                                                                  RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                                                  appSubscription.currentPeriodEnd as Date,
                                                                  appSubscription.latestInvoice,
                                                              )
                                                            : ''}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <span className="active-plan-heading">
                                                        {t('subscriptions.pricing', {
                                                            ns: 'marketplace',
                                                        })}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span className="active-plan-content">
                                                        {convertPrice(activePlan.pricing)}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            )}

                            {isTenantAdmin && (
                                <>
                                    <Card className="payment-method section-item">
                                        <Row gutter={[32, 16]}>
                                            <Col>
                                                <div className="card-header">
                                                    <span className="mr-2">
                                                        {t('marketplace.payment_method', {
                                                            ns: 'marketplace',
                                                        })}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} justify="space-between">
                                            <Col>
                                                {defaultPaymentMethod && (
                                                    <div className="user-card-item">
                                                        <div className="default-ribbon">
                                                            {t('marketplace.default', {
                                                                ns: 'marketplace',
                                                            })}
                                                        </div>
                                                        <div className="card-img ml-auto">
                                                            <img
                                                                src={`/images/payments/${defaultPaymentMethod.brand?.toLowerCase()}.svg`}
                                                            />
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-no">
                                                                **** **** ****{' '}
                                                                {defaultPaymentMethod.last4}
                                                            </div>
                                                            Ex: {defaultPaymentMethod.expMonth}/
                                                            {defaultPaymentMethod.expYear}
                                                        </div>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="default"
                                                    onClick={onChangePaymentMethod}
                                                >
                                                    {t('actions.change', {
                                                        ns: 'marketplace',
                                                    })}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <ApplicationSubscriptionInvoiceList
                                        appId={id}
                                    ></ApplicationSubscriptionInvoiceList>
                                </>
                            )}
                        </div>
                    </Spin>
                </div>
            </section>
            <Modal
                {...modalProps}
                title={t('marketplace.payment', {
                    ns: 'marketplace',
                })}
                maskClosable={false}
                footer={null}
                width={650}
                wrapClassName="payment-modal"
            >
                <Card className="active-subscription-plan">
                    <Row gutter={[32, 16]}>
                        <Col>
                            <span className="heading">{selectedPlan?.title}</span>
                        </Col>
                    </Row>
                    <Row gutter={[32, 16]}>
                        <Col>
                            <Row>
                                <Col>
                                    <span className="active-plan-heading">
                                        {t('marketplace.cycle', {
                                            ns: 'marketplace',
                                        })}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="active-plan-content">
                                        {t(
                                            `subscriptions.frequency.${selectedPlan?.frequency?.toString()}`,
                                            { ns: 'marketplace' },
                                        )}
                                    </span>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <Col>
                                    <span className="active-plan-heading">
                                        {t('marketplace.pricing', {
                                            ns: 'marketplace',
                                        })}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span
                                        className={`active-plan-content ${
                                            canApplyTrial ? 'line-through' : ''
                                        }`}
                                    >
                                        {convertPrice(selectedPlan?.pricing)}
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        {canApplyTrial && (
                            <Col>
                                <Row>
                                    <Col>
                                        <span className="active-plan-heading">
                                            {' '}
                                            {selectedPlan?.trialPeriodDays}{' '}
                                            {t('marketplace.days_trial', {
                                                ns: 'marketplace',
                                            })}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="active-plan-content">
                                            {convertPrice(0)}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Card>

                {defaultPaymentMethod && (
                    <Row gutter={16}>
                        <Col>
                            <h3 className="my-2">
                                {t('marketplace.payment_method', {
                                    ns: 'marketplace',
                                })}
                            </h3>
                            <div className="user-card-item">
                                <div className="default-ribbon">
                                    {' '}
                                    {t('marketplace.default', {
                                        ns: 'marketplace',
                                    })}
                                </div>
                                <div className="card-img ml-auto">
                                    <img
                                        src={`/images/payments/${defaultPaymentMethod.brand?.toLowerCase()}.svg`}
                                    />
                                </div>
                                <div className="card-content">
                                    <div className="card-no">
                                        **** **** **** {defaultPaymentMethod.last4}
                                    </div>
                                    Ex: {defaultPaymentMethod.expMonth}/
                                    {defaultPaymentMethod.expYear}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                <Row gutter={16} justify="end">
                    <Col>
                        {defaultPaymentMethod && (
                            <Button
                                className="mt-3 ml-auto"
                                type="primary"
                                loading={isLoadingMakePaymentWithCard}
                                onClick={handleSubmit}
                            >
                                {t('buttons.subscribe')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
