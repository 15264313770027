import { notification, Spin } from 'antd';
import { getEnvConfig } from 'getEnvConfig';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FinixPaymentService } from 'services/api/finix-payment.service';

type Props = {
    invoiceId: string;
    identity: string;
    transactionId: string;
    onClose: (value: boolean) => void;
};
const finixPaymentService = new FinixPaymentService();

export const FinixPayment: React.FC<Props> = ({ identity, transactionId, invoiceId, onClose }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { t, i18n } = useTranslation(['common']);

    useEffect(() => {
        if (transactionId) {
            const onSubmit = async () => {
                setLoading(true);

                form.submit(
                    getEnvConfig.FINIX_ENVIRONMENT as string,
                    getEnvConfig.FINIX_APPLICATION_ID as string,
                    async function (_, res: Finix.FormResponse) {
                        const tokenData = res.data || {};
                        const token = tokenData.id;
                        if (!token) {
                            notification.error({
                                message: 'Finix generate token error',
                            });
                        }
                        const paymentRes = await createPayment(token);
                        if (!paymentRes.result) {
                            const errorMsgKey = `crm_internal.finix.errors_code.${paymentRes.errorCode}`;
                            notification.error({
                                message: i18n.exists(errorMsgKey)
                                    ? t(errorMsgKey)
                                    : t(`crm_internal.finix.errors_code.DEFAULT`),
                            });
                        } else {
                            onClose(paymentRes?.result);
                        }
                        setLoading(false);
                    },
                );
            };
            const form = window.Finix.TokenForm('form', {
                showAddress: true,
                onSubmit,
            });
        }
    }, [transactionId]);

    // authorize & capture
    const createPayment = async (token: string) => {
        return await finixPaymentService.checkoutFinix({
            identity,
            transactionId,
            invoiceId,
            token,
        });
    };

    return (
        <Spin spinning={loading}>
            <div id="form"></div>
        </Spin>
    );
};
