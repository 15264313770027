import './styles.scss';

import { PartnerTypeEnum } from '@enums/partner-type.enum';
import { ITenant } from '@interfaces/tenant';
import { HttpError, useApiUrl, useCustom } from '@refinedev/core';
import { maskPhone } from '@utils/string';
import { DebounceAsyncValidator, validateEmail } from '@utils/validator';
import { Col, Form, FormProps, Input, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { DataProviderNameEnum } from 'dataProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Response from '../../../data-access/responses/response';

export type FormMode = 'show' | 'add' | 'edit';
type PartnerDetailFormProps = {
    formProps: FormProps;
    mode: FormMode;
    type: PartnerTypeEnum;
    onCancelEdit?: () => void;
    onChangeMode?: (mode: FormMode) => void;
};
export const PartnerDetailCrmForm: React.FC<PartnerDetailFormProps> = ({
    formProps,
    mode,
    type,
}) => {
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);

    const { t } = useTranslation(['partner', 'common']);

    const initialValues: ITenant.ITenantInfor = {
        ...formProps.initialValues,
    } as ITenant.ITenantInfor;
    const [email, setEmail] = useState('');

    const { refetch: checkUniqueEmail } = useCustom<
        Response<boolean>,
        HttpError,
        {
            id?: string;
            email: string;
        }
    >({
        url: `${apiUrl}/v1/partner/check-unique-email`,
        method: 'post',
        config: {
            payload: { email: email },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const asyncValidateUniqueEmail = DebounceAsyncValidator(400, async () => {
        const { data } = await checkUniqueEmail();

        if (data && data?.data?.data) {
            return true;
        }
        return false;
    });

    const renderFormItem = (isReadonly: boolean, formItem: JSX.Element, showItem: JSX.Element) => {
        if (isReadonly) {
            return showItem;
        }
        return formItem;
    };

    return (
        <Form
            className={`partner-detail ${mode === 'show' ? 'show-mode' : ''}`}
            {...formProps}
            onFinish={async (values) => {
                if (mode === 'edit') {
                    values.avatarUrl = values.avatarUrl ?? null;
                } else {
                    values.avatarUrl = values.avatarUrl?.length > 0 ? values.avatarUrl : null;
                }
                values.partnerType = type;
                formProps.onFinish && formProps.onFinish(values);
            }}
            layout="vertical"
            initialValues={{
                ...initialValues,
                phone: maskPhone(initialValues?.phone || ''),
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Form.Item
                        label={t('partners.fields.firstName.label')}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.firstName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input placeholder={t('partners.fields.firstName.placeholder')} />,
                            <span>{initialValues?.firstName}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.lastName.label')}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.lastName.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <Input placeholder={t('partners.fields.lastName.placeholder')} />,
                            <span>{initialValues?.lastName}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.email.label')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('partners.fields.email.required'),
                            },
                            {
                                type: 'email',
                                message: t('partners.fields.email.valid'),
                            },
                            {
                                validator: async (_, value) => {
                                    if (
                                        !value ||
                                        !validateEmail(value) ||
                                        value === initialValues.email
                                    ) {
                                        return;
                                    }

                                    const result = await asyncValidateUniqueEmail();
                                    if (result) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(t('partners.fields.email.unique')),
                                    );
                                },
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode !== 'add',
                            <Input
                                placeholder={t('partners.fields.email.placeholder')}
                                onChange={(e) => {
                                    if (e?.target?.value) {
                                        const _value = e.target.value.trim().toLowerCase();
                                        formProps.form?.setFieldsValue({ email: _value });
                                        setEmail(_value);
                                    }
                                }}
                            />,
                            <span>{initialValues?.email}</span>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t('partners.fields.phoneNumber.label')}
                        name="phone"
                        rules={[
                            {
                                pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                            {
                                required: true,
                                message: t('partners.fields.phoneNumber.required'),
                            },
                        ]}
                    >
                        {renderFormItem(
                            mode === 'show',
                            <MaskedInput
                                mask={
                                    //  https://imask.js.org/guide.html#masked-pattern
                                    '(000)-000-0000'
                                }
                            />,
                            <span>{maskPhone(initialValues?.phone || '')}</span>,
                        )}
                    </Form.Item>
                    <Form.Item hidden name="partnerType"></Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
