import { COLORS_CHART, DATE_FORMAT } from '@constants/index.constant';
import { convertPrice } from '@utils/resource';
import { DatePicker, Empty } from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

export type DataPieChart = {
    value: number;
    name: string;
};

export const PieCrmChart: React.FC<{
    data: DataPieChart[];
    title: string;
    valueDateRange: [dayjs.Dayjs, dayjs.Dayjs];
    onChangeDateRange: (dayString: string[]) => void;
}> = ({ data, title, onChangeDateRange, valueDateRange }) => {
    const { t: translate } = useTranslation(['common']);
    const ranges: any = {
        [translate('reports.rangeDate.yesterday')]: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],

        [translate('reports.rangeDate.today')]: [dayjs(), dayjs()],

        [translate('reports.rangeDate.last7Days')]: [dayjs().add(-7, 'd'), dayjs()],

        [translate('reports.rangeDate.last14Days')]: [dayjs().add(-14, 'd'), dayjs()],

        [translate('reports.rangeDate.last30Days')]: [dayjs().add(-30, 'd'), dayjs()],

        [translate('reports.rangeDate.last90Days')]: [dayjs().add(-90, 'd'), dayjs()],

        [translate('reports.rangeDate.thisWeek')]: [dayjs().startOf('week'), dayjs()],

        [translate('reports.rangeDate.thisMonth')]: [dayjs().startOf('month'), dayjs()],

        [translate('reports.rangeDate.thisYear')]: [dayjs().startOf('year'), dayjs()],
    };
    const options = {
        color: COLORS_CHART,

        grid: {
            left: 0,
            containLabel: true,
            top: 50,
            right: 0,
        },
        legend: {
            orient: 'vertical',
            left: 'left',
        },

        series: [
            {
                data: data,
                type: 'pie',
                smooth: true,
                radius: '50%',
                center: ['50%', '70%'],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
        tooltip: {
            trigger: 'item',
            valueFormatter: (value: number) => convertPrice(value),
        },
    };

    return (
        <>
            <h3 className="title-section">{title} </h3>
            <div className="filter-wrapper">
                <div className="filter-range-date" id="rangeDateArea">
                    <RangePicker
                        allowClear={false}
                        ranges={ranges}
                        disabledDate={(date) => +date > +dayjs()}
                        onChange={(e: any, dayString: string[]) => onChangeDateRange(dayString)}
                        value={valueDateRange}
                        getPopupContainer={() =>
                            document.getElementById('rangeDateArea') as HTMLElement
                        }
                        format={DATE_FORMAT}
                    />
                </div>
            </div>
            {data?.length ? <ReactECharts option={options} /> : <Empty />}
        </>
    );
};
