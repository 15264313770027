import './styles.scss';

import { TextEditor } from '@components/modules/editor';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { QuoteStatus } from '@enums/quote-status.enum';
import { BaseRecord, useApiUrl, useCreate, useNavigation } from '@refinedev/core';
import { Button, Col, Form, Row } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { Description } from 'pages/internal-crm/common';
import { BillInformation } from 'pages/internal-crm/common/bill-information';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { ItemsDetail } from 'pages/internal-crm/invoice/items-detail';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const InvoiceCreateCrmIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const [form] = Form.useForm();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const [orderId, setOrderId] = useState<string | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<QuoteStatus>(QuoteStatus.Draft);
    const [description, setDescription] = useState<string>('');
    const [termCondition, setTermCondition] = useState<string>('');

    const { mutate } = useCreate<BaseRecord>();
    const { goBack } = useNavigation();
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);

    const onChangeContact = (val: any) => {
        if (form.getFieldValue('orderId')) {
            form.resetFields(['items', 'orderId']);
            setOrderId(null);
        }
    };

    const onFinish = (values: any) => {
        setIsLoading(true);
        for (const item of values.items) {
            const basePrice: any = Number.parseFloat(item.unitPrice).toFixed(2);
            item.unitPrice = basePrice * 100;
            item.total = item?.unitPrice * item?.qty;
            item.frequency = ProductFrequencyEnum.ONE_TIME_PAYMENT;
        }
        values.status = status;
        values.description = description;
        values.termsAndConditions = termCondition;
        mutate(
            {
                resource: 'v1/invoices',
                values: values,
                successNotification: { message: 'Successfully created', type: 'success' },
                dataProviderName,
            },
            {
                onError: (_) => {
                    setIsLoading(false);
                },
                onSuccess: (_) => {
                    goBack();
                },
            },
        );
    };

    const submitForm = (status: QuoteStatus) => {
        setStatus(status);
        form.submit();
    };

    const onChangeDescription = (content: string) => {
        setDescription(content);
    };

    return (
        <PayoutContextProvider>
            <section className="invoice-container">
                <div className="block-heading edit-heading">
                    <span>{t('quotes.heading.createInvoiceTitle')}</span>
                    <div className="header-actions">
                        <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                        <Button
                            type="primary"
                            disabled={status === QuoteStatus.Draft && isLoading}
                            loading={status === QuoteStatus.Pending && isLoading}
                            onClick={() => submitForm(QuoteStatus.Pending)}
                        >
                            {t('quotes.actions.create')}
                        </Button>
                    </div>
                </div>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{ items: [{ qty: 1, margin: 0 }] }}
                    onFinish={onFinish}
                >
                    <div className="section  details-section">
                        <div className="section-header">
                            {t('quotes.heading.invoiceInformation')}
                        </div>

                        <BillInformation form={form} onChangeContact={onChangeContact} />
                    </div>
                    <div className="section">
                        <Row gutter={20}>
                            <Col md={24}>
                                <Form.Item label={t('quotes.fields.description.label')}>
                                    <Description onChangeDescription={onChangeDescription} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="section">
                        <ItemsDetail
                            form={form}
                            dataProviderName={dataProviderName}
                            apiUrl={apiUrl}
                            orderId={orderId}
                        />
                    </div>
                    <div className="section">
                        <div className="section-header">{t('quotes.fields.tc.label')}</div>
                        <Row gutter={20}>
                            <Col md={24}>
                                <Form.Item>
                                    <TextEditor
                                        onChange={(content, editor) => {
                                            const parser = new DOMParser();
                                            const doc = parser.parseFromString(
                                                content,
                                                'text/html',
                                            );
                                            const text = doc.body.textContent || '';
                                            setTermCondition(text);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </section>
        </PayoutContextProvider>
    );
};
