import { TextEditor } from '@components/modules/editor';
import { FC } from 'react';

type DescriptionProps = {
    onChangeDescription: (content: string) => void;
};

export const Description: FC<DescriptionProps> = ({ onChangeDescription }) => {
    return (
        <TextEditor
            onChange={(content, editor) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');
                const text = doc.body.textContent || '';
                onChangeDescription(text);
            }}
            height="300px"
        />
    );
};
