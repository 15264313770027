export enum IssueStatus {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum IssuePriority {
    CRITICAL = 'critical',
    HIGH = 'high',
    NORMAL = 'normal',
    LOW = 'low',
}

export enum HelpdeskHeaderAction {
    CREATE = 'create',
    SAVE = 'save',
    CANCEL = 'cancel',
}

export enum TicketTargetEnum {
    CONTACT = 'contact',
    STAFF = 'staff',
}
