import './styles.scss';

import { DOCUMENT_TYPE } from '@constants/index.constant';
import { useApiUrl } from '@refinedev/core';
import { Image, message } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import Upload, { UploadChangeParam } from 'antd/lib/upload';
import { useState } from 'react';

export interface IServeralImageUploadProps {
    onChange: (fileList: UploadFile) => void;
}

export interface IFileList {
    id: string;
    url: string;
}

export const ServeralFileUpload: React.FC<IServeralImageUploadProps> = ({ onChange }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const apiUrl = useApiUrl();
    const token = localStorage.getItem('access_token');
    const fileSize = 5;

    const beforeUpload = (file: RcFile) => {
        const isDocumentFile = DOCUMENT_TYPE.includes(file.type);
        if (!isDocumentFile) {
            message.error('You can only upload Word/PowerPoint/Excel/PDF file!');
        }
        const isLt = file.size / 1024 / 1024 < fileSize;
        if (isDocumentFile && !isLt) {
            message.error(`Document file must smaller than ${fileSize}MB!`);
        }

        if (!(isDocumentFile && isLt)) {
            return false;
        }

        return true;
    };

    const handleChange = (fileOrn: UploadChangeParam<UploadFile>) => {
        if (fileOrn.file.status === 'done') {
            onChange(fileOrn.file);
        }
        setFileList(fileOrn.fileList);
    };

    const uploadButton = (
        <Image className="icon-action mx-3" src={'/images/icons/attachment.svg'} preview={false} />
    );

    return (
        <>
            <Upload
                beforeUpload={beforeUpload}
                action={`${apiUrl}/v1/resource/upload-attachment`}
                listType="picture-card"
                onChange={(fileOrn) => handleChange(fileOrn)}
                headers={{ Authorization: `Bearer ${token}` }}
                fileList={fileList}
                accept={'.docx, .doc, .xlsx, .xls, .pdf, .ppt, .pptx'}
                showUploadList={false}
            >
                {fileList.length >= 6 ? null : uploadButton}
            </Upload>
        </>
    );
};
