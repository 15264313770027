import { AUTH_URL, TOKEN_KEY, authProvider } from './authProvider';
import { CrudFilters, CrudOperators, CrudSorting, DataProvider, HttpError } from '@refinedev/core';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { stringify } from 'query-string';
import { SERVER_IS_NOT_READY_MESSAGE } from '@constants/error-message';

const axiosInstance = axios.create();
let isRefreshing = false;

let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: AxiosRequestConfig) => {
        // Retrieve the token from local storage
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token') || localStorage.getItem(TOKEN_KEY);
        // Check if the header property exists
        if (request.headers) {
            if (!request.headers['Authorization']) {
                // Set the Authorization header if it exists
                request.headers['Authorization'] = `Bearer ${token}`;
            }
        } else {
            // Create the headers property if it does not exist
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }

        return request;
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const customError: HttpError = {
            ...error,
            message:
                error.response?.data?.message ||
                (error?.response?.status ? error.message : SERVER_IS_NOT_READY_MESSAGE),
            statusCode: (error?.response?.status as number) || 408,
        };

        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry) {
            if (originalRequest.url?.includes('auth/')) {
                isRefreshing = false;
                return Promise.reject(customError);
            }
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(() => {
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;

            const refreshToken = authProvider.getRefreshToken();
            if (refreshToken) {
                isRefreshing = true;
                return new Promise(function (resolve, reject) {
                    axios
                        .post(AUTH_URL.REFRESH_TOKEN, { refreshToken })
                        .then(({ data }) => {
                            authProvider.refreshToken(data.data);
                            processQueue(null, data.data.token);
                            resolve(axios(originalRequest));
                        })
                        .catch((err) => {
                            authProvider.logout(null);
                            processQueue(err, null);
                            reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }
        }

        return Promise.reject(customError);
    },
);

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case 'ne':
        case 'gte':
        case 'lte':
            return `_${operator}`;
        case 'contains':
            return '_like';
        case 'eq':
        default:
            return '';
    }
};

const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        const _sort: string[] = [];
        const _order: string[] = [];

        sort.map((item) => {
            _sort.push(item.field);
            _order.push(item.order);
        });

        return {
            _sort,
            _order,
        };
    }

    return;
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};

    if (filters) {
        filters.map((filter) => {
            if (filter.operator === 'or' || filter.operator === 'and') {
                throw new Error(
                    `[refine-simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`,
                );
            }

            if ('field' in filter) {
                const { field, operator, value } = filter;

                if (field === 'q') {
                    queryFilters[field] = value;
                    return;
                }

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};

const RestServer = (apiUrl: string, httpClient: AxiosInstance = axiosInstance): DataProvider => ({
    getList: async ({
        resource,
        hasPagination = true,
        pagination = { current: 1, pageSize: 10 },
        filters,
        sort,
    }) => {
        const url = `${apiUrl}/${resource}`;

        const { current = 1, pageSize = 10 } = pagination ?? {};

        const queryFilters = generateFilter(filters);

        const query: {
            pageIndex?: number;
            pageSize?: number;
            sort?: string;
            order?: string;
        } = hasPagination
            ? {
                  pageIndex: current,
                  pageSize: pageSize,
              }
            : {};

        const generatedSort = generateSort(sort);
        if (generatedSort) {
            const { _sort, _order } = generatedSort;
            query.sort = _sort.join(',');
            query.order = _order.join(',');
        }

        const { data, headers } = await httpClient.get(
            `${url}?${stringify(query)}&${stringify(queryFilters)}`,
        );

        return {
            data: data.data,
            total: data.total,
        };
    },

    getMany: async ({ resource, ids }) => {
        const { data } = await httpClient.get(`${apiUrl}/${resource}?${stringify({ id: ids })}`);

        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        const url = `${apiUrl}/${resource}`;

        const { data } = await httpClient.post(url, variables);

        return {
            data,
        };
    },

    createMany: async ({ resource, variables }) => {
        const response = await Promise.all(
            variables.map(async (param) => {
                const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
                return data;
            }),
        );

        return { data: response };
    },

    update: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.put(url, variables);

        return {
            data,
        };
    },

    updateMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.put(`${apiUrl}/${resource}/${id}`, variables);
                return data;
            }),
        );

        return { data: response };
    },

    getOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.get(url);

        return {
            data: data.data,
        };
    },

    deleteOne: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url);

        return {
            data,
        };
    },

    deleteMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        let requestUrl = `${url}?`;

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const { _sort, _order } = generatedSort;
                const sortQuery = {
                    sort: _sort.join(','),
                    order: _order.join(','),
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case 'put':
            case 'post':
            case 'patch':
                axiosResponse = await httpClient[method](url, payload);
                break;
            case 'delete':
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});
export enum DataProviderNameEnum {
    DEFAULT = 'default',
    NOTIFICATION = 'notification',
    INTERNAL_CRM = 'internalCrm',
}
export default RestServer;
