import './styles.scss';

import { BaseKey, HttpError, useList, useNavigation, useOne, useUpdate } from '@refinedev/core';
import { Button, Col, Form, Input, Row, Select, Spin, UploadFile } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TextEditor } from '../../components/modules/editor';
import { ImageUpload } from '../../components/modules/image-upload';
import Response from '../../data-access/responses/response';
import { IMarketplace } from '../../interfaces/marketplace';

const APPLICATION_RESOURCE_URL = 'v1/application';
export const MarketplaceEditIndex: React.FC = () => {
    const { t } = useTranslation(['common', 'marketplace']);
    const { id } = useParams();
    const { goBack, push } = useNavigation();
    const [formEditApplication] = Form.useForm();
    const [formEditPlan] = Form.useForm();
    const [isEditPlan, setIsEditPlan] = useState<boolean>(false);
    const [isUploadImage, setIsUploadImage] = useState<boolean>(false);
    const [pricePlan, setPricePlan] = useState<number>(0);

    const { mutate: updateNote, isLoading: isUpdating } = useUpdate<
        Response<IMarketplace.IApplication>,
        HttpError,
        IMarketplace.IApplication
    >();

    const { mutate: updatePlanNote, isLoading: isUpdatingPlan } = useUpdate<
        Response<IMarketplace.IApplicationPlan>,
        HttpError,
        { plans: IMarketplace.IApplicationPlan[] }
    >();

    const { data: applicationData, isLoading } = useOne<IMarketplace.IApplication>({
        resource: APPLICATION_RESOURCE_URL,
        id: id as BaseKey,
    });
    const { data: applicationCategoriesData } = useList<IMarketplace.IApplicationCategory>({
        resource: `${APPLICATION_RESOURCE_URL}/categories`,
    });

    const application: IMarketplace.IApplication | undefined = applicationData?.data;
    const categories: IMarketplace.IApplicationCategory[] | undefined =
        applicationCategoriesData?.data;

    const onUpdateApplication = (values: IMarketplace.IApplication) => {
        if (values.avatar.includes('fakepath')) {
            values.avatar = application?.avatar ?? '';
        }
        updateNote(
            {
                resource: APPLICATION_RESOURCE_URL,
                id: id as BaseKey,
                values: values,
                successNotification: false,
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                },
                onSuccess: (data, variables, context) => {
                    if (data?.data) {
                        setIsEditPlan(true);
                    }
                },
            },
        );
    };

    const onUpdatePlan = (values: IMarketplace.IApplicationPlan) => {
        values.currency = 'USD';
        values.pricing = Number(Number(values.pricing * 100).toFixed());
        const updatePlan = [{ ...values }];
        updatePlanNote(
            {
                resource: `${APPLICATION_RESOURCE_URL}/plan`,
                id: id || '',
                values: { plans: updatePlan },
                successNotification: { message: 'Successfully update', type: 'success' },
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                },
                onSuccess: (data, variables, context) => {
                    if (data?.data) {
                        push('/marketplace');
                        setIsEditPlan(false);
                    }
                },
            },
        );
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        const validPattern = reg.test(inputValue);
        if (validPattern) {
            setPricePlan(Number(inputValue));
        } else {
            formEditPlan.setFieldsValue({
                pricing: pricePlan,
            });
        }
    };

    useEffect(() => {
        if (application) {
            formEditApplication.setFieldsValue({
                avatar: application.avatar,
                title: application.title,
                categoryId: application.category.id,
                description: application.description,
            });

            const plan = application.plans[0];
            const price = (plan.pricing / 100).toFixed(2);
            setPricePlan(Number(price));
            formEditPlan.setFieldsValue({
                id: plan.id,
                title: plan.title,
                frequency: plan.frequency,
                pricing: price,
            });
        }
    }, [application]);

    const onChangeImage = (file: UploadFile<any>) => {
        if (file.status === 'uploading') {
            setIsUploadImage(true);
        } else {
            setIsUploadImage(false);
        }
        if (file?.response && file?.status === 'done') {
            formEditApplication?.setFieldsValue({ avatar: file.response });
        }
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select defaultValue="USD" style={{ width: 70 }}>
                <Select.Option value="USD">US</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <>
            <Spin spinning={isLoading || isUpdating || isUpdatingPlan}>
                {!isEditPlan ? (
                    <Form
                        form={formEditApplication}
                        name="edit-application "
                        layout="vertical"
                        key="1"
                        onFinish={onUpdateApplication}
                        autoComplete="off"
                    >
                        <section className="application-edit-container">
                            <div className="edit-application-wrapper">
                                <div className="header-section">
                                    <div className="title">
                                        <img
                                            src="/images/icons/overview.svg"
                                            alt="overview"
                                            className="icon-overview"
                                        />
                                        <p className="text-title">Overview</p>
                                    </div>
                                    <div className="action-section">
                                        <Button className="btn-cancel" onClick={goBack}>
                                            {t('buttons.cancel', { ns: 'common' })}
                                        </Button>
                                        <Button
                                            className="btn-next"
                                            type="primary"
                                            htmlType="submit"
                                            disabled={isUploadImage}
                                        >
                                            {t('buttons.next', { ns: 'common' })}
                                        </Button>
                                    </div>
                                </div>

                                <div className="main-content-wrapper">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={18} xxl={18}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <Form.Item name="avatar">
                                                        <div className="avatar-image">
                                                            <ImageUpload
                                                                width={96}
                                                                height={96}
                                                                onChange={(fileOrn) => {
                                                                    if (fileOrn) {
                                                                        onChangeImage(fileOrn);
                                                                    }
                                                                }}
                                                                uploadText="Add Photo"
                                                                value={application?.avatar}
                                                                horizonUploadButton={true}
                                                                isLoading={isUploadImage}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                                <Col md={24} lg={24} xl={12}>
                                                    <Form.Item
                                                        label="Title"
                                                        name="title"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please input application title',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={24} lg={24} xl={12}>
                                                    <Form.Item
                                                        label="Type"
                                                        name="categoryId"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder={t(
                                                                'editApplication.typePlaceholder',
                                                                { ns: 'marketplace' },
                                                            )}
                                                            showArrow
                                                            suffixIcon={
                                                                <img src="/images/icons/arrow-down-icon.svg" />
                                                            }
                                                        >
                                                            {categories?.map((category, index) => (
                                                                <Select.Option
                                                                    key={index}
                                                                    value={category.id}
                                                                >
                                                                    {category?.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="Description"
                                                        name="description"
                                                    >
                                                        <TextEditor height="300px" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </Form>
                ) : (
                    <Form
                        form={formEditPlan}
                        name="edit-plan"
                        layout="vertical"
                        onFinish={onUpdatePlan}
                        autoComplete="off"
                        key="2"
                    >
                        <section className="application-edit-container">
                            <div className="edit-application-wrapper">
                                <div className="header-section">
                                    <div className="title">
                                        <img
                                            src="/images/icons/overview.svg"
                                            alt="overview"
                                            className="icon-overview"
                                        />
                                        <p className="text-title">
                                            {t('editApplication.titleEditPlan', {
                                                ns: 'marketplace',
                                            })}
                                        </p>
                                    </div>
                                    <div className="action-section">
                                        <Button className="btn-cancel" onClick={goBack}>
                                            {t('buttons.cancel', { ns: 'common' })}
                                        </Button>
                                        <Button
                                            className="btn-next"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {t('buttons.save', { ns: 'common' })}
                                        </Button>
                                    </div>
                                </div>

                                <div className="main-content-wrapper">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <Form.Item name="id" hidden={true}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Plan Title"
                                                        name="title"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input plan title',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={24} lg={24} xl={8}>
                                                    <Form.Item
                                                        label="Frequency"
                                                        name="frequency"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder={t(
                                                                'editApplication.typePlaceholder',
                                                                { ns: 'marketplace' },
                                                            )}
                                                            showArrow
                                                            suffixIcon={
                                                                <img src="/images/icons/arrow-down-icon.svg" />
                                                            }
                                                        >
                                                            <Select.Option value={'month'}>
                                                                {'Monthly'}
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col md={24} lg={24} xl={16}>
                                                    <Form.Item
                                                        label="Pricing"
                                                        name="pricing"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please input plan pricing',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            prefix="$"
                                                            addonBefore={prefixSelector}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => handlePriceChange(e)}
                                                            value={pricePlan}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </Form>
                )}
            </Spin>
        </>
    );
};
