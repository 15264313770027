import './styles.scss';

import { CustomPagination } from '@components/modules/pagination';
import { OTP_RESOURCE_NAME } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { SettingsNavigation } from '@enums/settings.enum';
import { Otp } from '@interfaces/otp';
import { Payment } from '@interfaces/payment';
import { Payout } from '@interfaces/payouts';
import { IUser } from '@interfaces/user';
import { CreateButton } from '@refinedev/antd';
import {
    BaseRecord,
    useCreate,
    useGetIdentity,
    useNavigation,
    useOne,
    usePermissions,
    useTable,
    useTranslate,
} from '@refinedev/core';
import Response from '@responses/response';
import { Button, Col, notification, Popconfirm, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { OtpConfirm } from 'components/modules/otp-confirm';
import React, { useEffect, useState } from 'react';
import { convertPrice } from 'utils/resource';

interface IProps {
    name?: string;
}

type PayoutData = {
    payouts: Payout.IPayout[];
    totalPayout: number;
} & BaseRecord[];

enum WithdrawStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export const PAYOUT_RESOURCE_API = 'v1/payouts';

const PAYOUT_ACCOUNT_RESOURCE_API = 'v1/payments';

export const PayoutListIndex: React.FC<IProps> = () => {
    const translate = useTranslate();
    const { push } = useNavigation();

    const { data: permissions } = usePermissions<string[]>();
    const {
        tableQueryResult: payoutData,
        current,
        setCurrent,
        pageSize,
        setPageSize,
    } = useTable({
        resource: PAYOUT_RESOURCE_API,
    });

    const { data: paymentAccount, isFetched } = useOne<Payment.IPaymentAccount>({
        resource: `${PAYOUT_ACCOUNT_RESOURCE_API}/account-link`,
        id: '',
    });

    const [activeWithdraw, setActiveWithdraw] = useState<WithdrawStatus | null>(null);

    const { mutate, isLoading } = useCreate();

    const [isOpenOtp, setIsOpenOtp] = useState<boolean>(false);
    const { mutate: sendOtp, isLoading: isSendingOtp } = useCreate<Response<string>>();

    const { data: currentUser } = useGetIdentity<IUser.IUserDto>();

    useEffect(() => {
        if (isFetched) {
            if (
                paymentAccount?.data &&
                paymentAccount?.data?.payoutsEnabled &&
                paymentAccount?.data?.capabilities?.cardPayments === 'active' &&
                paymentAccount?.data?.capabilities?.transfers === 'active'
            ) {
                setActiveWithdraw(WithdrawStatus.ACTIVE);
            } else {
                setActiveWithdraw(WithdrawStatus.INACTIVE);
            }
        }
    }, [paymentAccount?.data]);

    const columns: ColumnsType<Payout.IPayout> = [
        {
            title: translate('payouts.invoice_id'),
            dataIndex: 'invoiceIncrementId',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.invoiceIncrementId}</div>;
            },
        },
        {
            title: translate('payouts.product_name'),
            dataIndex: 'productName',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.productName}</div>;
            },
        },
        {
            title: translate('payouts.amount'),
            dataIndex: 'amount',
            render: (_, item) => {
                return <div className="table-tbody-text">{convertPrice(item?.amount)}</div>;
            },
        },
        {
            title: translate('payouts.status'),
            dataIndex: 'status',
            render: (_, item) => {
                return (
                    <div className="table-tbody-text">
                        {translate('payouts.status_enum.' + item?.status)}
                    </div>
                );
            },
        },
    ];

    const createWithdraw = () => {
        mutate(
            {
                resource: `${PAYOUT_RESOURCE_API}/withdraw`,
                values: {},
                successNotification: {
                    message: translate('payouts.withdraw_successfully'),
                    type: 'success',
                },
                errorNotification: false,
            },
            {
                onError(error, variables, context) {
                    if (error?.message) {
                        notification.error({
                            message: error?.message
                                ? error?.message
                                : translate('payouts.withdraw_failed'),
                            type: 'error',
                        });
                    }
                },
                onSuccess(data, variables, context) {
                    payoutData?.refetch();
                },
            },
        );
    };

    const setupAccount = () => {
        push(SettingsNavigation.Payout);
    };

    const handleSendOtp = () => {
        const payload: Otp.ISendOtp = {
            id: currentUser?.id ?? '',
            resourceName: OTP_RESOURCE_NAME.PAYOUT,
            email: currentUser?.email ?? '',
        };
        sendOtp(
            {
                resource: 'v1/otp/code',
                values: payload,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    setIsOpenOtp(true);
                },
                onError: (error, _, __) => {
                    if (error.statusCode === 401) {
                        notification.error({
                            message: translate('otp.errors.item_is_unauthorized', { ns: 'common' }),
                            type: 'error',
                        });
                        return;
                    }

                    if (error.message === 'can_not_create_otp_code') {
                        notification.error({
                            message: translate('otp.errors.can_not_create_otp_code', {
                                ns: 'common',
                            }),
                            type: 'error',
                        });
                        return;
                    }
                    notification.error({
                        message: translate('otp.errors.send_otp_failed', { ns: 'common' }),
                        type: 'error',
                    });
                },
            },
        );
    };

    const handleAfterVerifyOtp = () => {
        createWithdraw();
    };

    const _payoutData = payoutData?.data?.data as PayoutData;
    return (
        <>
            <section className="payouts-container w-full h-full">
                {activeWithdraw === WithdrawStatus.INACTIVE ? (
                    <>
                        <div className="payout-notification restricted w-full mb-6">
                            <span className="text-base notification-text">
                                {translate('payouts.payout_setup_required')}
                            </span>
                        </div>
                        <Button type="primary" onClick={setupAccount}>
                            {translate('payouts.set_up_account')}
                        </Button>
                    </>
                ) : (
                    <>
                        {_payoutData?.payouts?.length ? (
                            <Row className="payouts-row mb-6 items-center">
                                <Col xs={24} sm={12} className="flex justify-start">
                                    <div className="total-invoices-amount flex text-lg font-bold">
                                        <span className="mr-2">
                                            {translate('payouts.total_payout_amount')}:{' '}
                                        </span>
                                        <span>{convertPrice(_payoutData?.totalPayout ?? 0)}</span>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} className="flex justify-end btn-withdraw">
                                    {activeWithdraw &&
                                    (permissions || []).includes(PERMISSIONS.WL_PAYOUTS_CREATE) ? (
                                        <Popconfirm
                                            placement="bottomRight"
                                            title={translate('payouts.withdraw_confirm')}
                                            onConfirm={handleSendOtp}
                                            okText={translate('payouts.yes')}
                                            cancelText={translate('payouts.cancel')}
                                        >
                                            <CreateButton
                                                icon={false}
                                                type="primary"
                                                loading={isLoading}
                                            >
                                                {translate('payouts.withdraw')}
                                            </CreateButton>
                                        </Popconfirm>
                                    ) : null}
                                </Col>
                            </Row>
                        ) : null}
                        <Row className="payouts-table-container">
                            <Col span={24} className="flex flex-col">
                                <Table
                                    className="payouts-table"
                                    columns={columns}
                                    dataSource={
                                        _payoutData?.payouts.filter((item) => item.amount > 0) || []
                                    }
                                    loading={payoutData?.isLoading}
                                    pagination={false}
                                    scroll={{ x: '700px', y: '100%' }}
                                />
                            </Col>
                            <Col span={24}>
                                <div className="pagination-container pt-3 pb-4 px-6">
                                    <CustomPagination
                                        pageSize={pageSize}
                                        total={payoutData?.data?.total}
                                        current={current}
                                        onChange={(value: any, size: any) => {
                                            setCurrent(value);
                                            setPageSize(size);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </section>
            {isOpenOtp ? (
                <OtpConfirm
                    title={translate('otp.withdraw', { ns: 'common' })}
                    visible={isOpenOtp}
                    isSendingOtp={isSendingOtp}
                    confirmData={{
                        email: currentUser?.email ?? '',
                        resourceName: OTP_RESOURCE_NAME.PAYOUT,
                        id: currentUser?.id ?? '',
                    }}
                    setVisible={setIsOpenOtp}
                    onClose={(isVerified: boolean) => {
                        if (!isVerified) {
                            return;
                        }
                        handleAfterVerifyOtp();
                    }}
                    onResend={() => handleSendOtp()}
                />
            ) : null}
        </>
    );
};
