export default class Response<T> {
    public data: T;
    public errors?: string[];
    public isSuccess?: boolean;

    constructor(data: T, errors?: string[], isSuccess?: boolean) {
        this.data = data;
        this.errors = errors;
        this.isSuccess = isSuccess;
    }
}
