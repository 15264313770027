import './styles.scss';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import { CustomSearch } from '@components/modules/custom-search';
import { FilterStatus } from '@components/modules/filter-status';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { QuoteStatus } from '@enums/quote-status.enum';
import { IQuote } from '@interfaces/quote';
import {
    CreateButton,
    DeleteButton,
    EditButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
} from '@refinedev/antd';
import {
    LogicalFilter,
    useApiUrl,
    useCustomMutation,
    useNotification,
    usePermissions,
    useTable,
} from '@refinedev/core';
import { convertPrice, formatDate, getPublicMediaUrl } from '@utils/resource';
import { Avatar, Dropdown, Menu, notification, Spin, Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { DataProviderNameEnum } from 'dataProvider';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const QuoteCrmIndex: React.FC = () => {
    const { t } = useTranslation(['quote']);
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const [loading, setLoading] = useState(false);

    const { mutate } = useCustomMutation<any>();
    const { data: permissionsData } = usePermissions<string[]>();
    const { open: openNotification } = useNotification();

    const LIST_FILTER_STATUS = Object.keys(QuoteStatus)
        .filter((key) => isNaN(Number(key)))
        .map((key) => ({
            label: t(`crm_internal.quote.status.${key.toLowerCase()}`, { ns: 'common' }),
            value: QuoteStatus[key as keyof typeof QuoteStatus].toString(),
        }));

    const {
        setFilters,
        filters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<IQuote.QuoteList>({
        dataProviderName: DataProviderNameEnum.INTERNAL_CRM,
        resource: 'v1/quotes',
        sorters: { initial: [{ field: 'incrementId', order: 'desc' }] },
        pagination: { pageSize: PAGE_SIZE, current: 1 },
        syncWithLocation: true,
    });

    // Render dropdown menu for quotes
    const renderMenu = (id: string, record: IQuote.QuoteList) => (
        <Menu mode="vertical">
            {record?.status === QuoteStatus.Pending &&
                permissionsData?.includes(PERMISSIONS.WL_QUOTE_CREATE) && (
                    <Menu.Item
                        key="send"
                        icon={
                            <img
                                src="/images/icons/send.svg"
                                alt="send"
                                className="cursor-pointer mr-3"
                            />
                        }
                        onClick={() => handleSendQuote(id)}
                    >
                        {t('actions.send')}
                    </Menu.Item>
                )}
            <Menu.Item
                key="show"
                className="p-0"
                icon={<ShowButton recordItemId={id} />}
            ></Menu.Item>
            {(record?.status === QuoteStatus.Draft || record?.status === QuoteStatus.Pending) &&
                permissionsData?.includes(PERMISSIONS.WL_QUOTE_EDIT) && (
                    <Menu.Item key="edit" icon={<EditButton recordItemId={id} />} />
                )}
            {record?.status !== QuoteStatus.Paid &&
                permissionsData?.includes(PERMISSIONS.WL_QUOTE_DELETE) &&
                record?.status !== QuoteStatus.Void && (
                    <Menu.Item
                        key="delete"
                        icon={
                            <DeleteButton
                                dataProviderName={dataProviderName}
                                resource="v1/quotes"
                                recordItemId={id}
                                errorNotification={(error: any) => {
                                    // if error contain _ show error msg, else show default error msg
                                    const _error = error?.response?.data?.error;
                                    if (_error?.includes('_')) {
                                        return {
                                            type: 'error',
                                            message: t(`crm_internal.quote.${_error}`, {
                                                ns: 'common',
                                            }),
                                        };
                                    } else {
                                        return {
                                            type: 'error',
                                            message: t(`crm_internal.quote.delete_quote_fail`, {
                                                ns: 'common',
                                            }),
                                        };
                                    }
                                }}
                                successNotification={{
                                    message: 'Successfully deleted',
                                    type: 'success',
                                }}
                            />
                        }
                    />
                )}
        </Menu>
    );

    // Handle table sorting and pagination
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, any>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter) {
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const onChangeFilter = (event: any) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'filter');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = event.target.value;
            } else {
                newFilter.push({
                    field: 'filter',
                    operator: 'eq',
                    value: event.target.value,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeStatus = (status: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'status');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = status;
            } else {
                newFilter.push({
                    field: 'status',
                    operator: 'eq',
                    value: status,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };
    const handleSendQuote = (id: string) => {
        setLoading(true);
        mutate(
            {
                dataProviderName: DataProviderNameEnum.INTERNAL_CRM,
                url: `${apiUrl}/v1/quotes/${id}/send`,
                method: 'post',
                values: {},
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    setLoading(false);
                    openNotification?.({
                        message: t('crm_internal.quote.successfully_sent_email_quote', {
                            ns: 'common',
                        }),
                        type: 'success',
                    });
                },
                onError: (error) => {
                    setLoading(false);
                    console.error(error);
                    openNotification?.({
                        message: t('crm_internal.quote.failed_send_email_quote', {
                            ns: 'common',
                        }),
                        type: 'error',
                    });
                },
            },
        );
    };

    return (
        <PayoutContextProvider>
            <Spin spinning={loading}>
                <section className="item-list-container quote-page-wrapper">
                    <div className="list-header">
                        <div className="filter-wrapper">
                            <CustomSearch
                                placeholder={t('quotes.fields.search.quote_placeholder')}
                                className={'search-item'}
                                onChange={(event: any) => onChangeFilter(event)}
                                defaultValue={
                                    filters?.find((f: any) => f.field === 'filter')?.value
                                }
                                allowClear={true}
                            />
                            <FilterStatus
                                placeholder={t('quotes.fields.search.status_placeholder')}
                                listStatus={LIST_FILTER_STATUS}
                                className="search-status"
                                defaultValue={
                                    filters?.find((f: any) => f.field === 'status')?.value
                                }
                                onChangeStatus={(status: string) => onChangeStatus(status)}
                            />
                        </div>
                        {permissionsData?.includes(PERMISSIONS.WL_QUOTE_CREATE) && (
                            <CreateButton
                                className="btn-add-new"
                                type="primary"
                                icon={<PlusOutlined />}
                            >
                                {t('add_new', { ns: 'common' })}
                            </CreateButton>
                        )}
                    </div>
                    <div className="list-content table-wrapper">
                        <Table<IQuote.QuoteList>
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={handleTableChange}
                            pagination={false}
                            rowKey="id"
                            className="quotes-table"
                            scroll={{ x: 900, y: '100%' }}
                        >
                            <Table.Column<IQuote.QuoteList>
                                sorter
                                defaultSortOrder={getDefaultSortOrder('incrementId', sorters)}
                                title={t('quotes.table.number')}
                                dataIndex="incrementId"
                                render={(text) => <p className="table-tbody-text">{text}</p>}
                                width={80}
                            />
                            <Table.Column<IQuote.QuoteList>
                                sorter
                                title={t('quotes.table.billTo')}
                                dataIndex="contactName"
                                width={300}
                                render={(row, record) => (
                                    <div className="flex items-center">
                                        <Avatar
                                            size={40}
                                            src={
                                                record.contactAvatarUrl
                                                    ? getPublicMediaUrl(record.contactAvatarUrl)
                                                    : undefined
                                            }
                                            icon={<AntDesignOutlined />}
                                        />
                                        <p className="table-tbody-text ml-2">{row}</p>
                                    </div>
                                )}
                            />
                            <Table.Column<IQuote.QuoteList>
                                sorter
                                title={t('quotes.table.status')}
                                dataIndex="status"
                                render={(status) => (
                                    <p className={`status-${status}`}>
                                        {t(`quotes.status.${status}`)}
                                    </p>
                                )}
                                width={120}
                            />
                            <Table.Column<IQuote.QuoteList>
                                sorter
                                title={t('quotes.table.amount')}
                                dataIndex="amount"
                                render={(text) => (
                                    <p className="table-tbody-text">{convertPrice(text)}</p>
                                )}
                                width={120}
                            />
                            <Table.Column<IQuote.QuoteList>
                                title={t('quotes.table.frequency')}
                                dataIndex="frequencies"
                                render={(frequencies) =>
                                    frequencies
                                        .map((f: string) => t(`quotes.frequency.${f}`))
                                        .join(', ')
                                }
                                width={200}
                            />
                            <Table.Column<IQuote.QuoteList>
                                sorter
                                title={t('quotes.table.expected_payment_date')}
                                dataIndex="date"
                                render={(text) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                                width={200}
                            />
                            <Table.Column<IQuote.QuoteList>
                                sorter
                                title={t('quotes.table.dueDate')}
                                dataIndex="dueDate"
                                render={(text) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                                width={200}
                            />
                            <Table.Column<IQuote.QuoteList>
                                key="action"
                                dataIndex="id"
                                width={100}
                                render={(id, record) => (
                                    <div
                                        className="flex justify-between items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Dropdown
                                            overlay={renderMenu(id, record)}
                                            placement="bottomRight"
                                            className="w-5 ml-auto"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <img
                                                src="/images/icons/dots-vertical.svg"
                                                alt="more"
                                                className="cursor-pointer"
                                            />
                                        </Dropdown>
                                    </div>
                                )}
                                fixed="right"
                            />
                        </Table>
                    </div>
                    <div className="pagination-container pt-3 pb-4 px-6">
                        <CustomPagination
                            pageSize={pageSize}
                            total={tableQueryResult?.data?.total}
                            current={current}
                            onChange={(value, size) => {
                                setCurrent(value);
                                setPageSize(size);
                            }}
                        />
                    </div>
                </section>
            </Spin>
        </PayoutContextProvider>
    );
};
