import './styles.scss';

import { PaymentMethod } from '@components/setting/payment-method';
import React from 'react';

interface IGeneralSettingProps {
    name?: string;
}

export const PaymentMethodSetting: React.FC<IGeneralSettingProps> = () => {
    return (
        <>
            <PaymentMethod />
        </>
    );
};
