import { CustomPagination } from '@components/modules/pagination';
import { IMarketplace } from '@interfaces/marketplace';
import { ISubscription } from '@interfaces/subscription';
import { formatAppSubscriptionPeriodEnd, RECURRING_SUBSCRIPTION_SUBTRACT_DAY } from '@utils/date';
import { convertPrice } from '@utils/resource';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

type SubscriptionTabProps = {
    total: number;
    current: number;
    cols: ISubscription.SubscriptionList[];
    tableResult: any;
    setCurrent: (v: number) => void;
    pageSize: number;
    subscriptionLoading: boolean;
};

export const ContactSubscriptionTabContent: React.FC<SubscriptionTabProps> = ({
    pageSize,
    cols,
    current,
    tableResult,
    setCurrent,
    subscriptionLoading,
}) => {
    const { t } = useTranslation(['quote', 'common']);

    return (
        <div className="overflow-hidden">
            <div className="list-content table-wrapper">
                <Table
                    dataSource={cols}
                    pagination={false}
                    tableLayout="fixed"
                    scroll={{ x: '900px' }}
                    rowKey="id"
                    loading={subscriptionLoading}
                >
                    <Table.Column
                        title={<>{t('subscriptions.invoiceId', { ns: 'common' })}</>}
                        dataIndex="invoice"
                        key="incrementId"
                        width={140}
                        render={(invoice, _) => {
                            const incrementId = invoice.incrementId;

                            return <p className="table-tbody-text">{incrementId}</p>;
                        }}
                    />
                    <Table.Column
                        title={<>{t('subscriptions.statusTitle', { ns: 'common' })}</>}
                        dataIndex="status"
                        key="status"
                        width={120}
                        render={(status, _) => {
                            return (
                                <p className="table-tbody-text">
                                    {t('subscriptions.status.' + status.toString(), {
                                        ns: 'common',
                                    })}
                                </p>
                            );
                        }}
                    />
                    <Table.Column
                        title={<>{t('subscriptions.productName', { ns: 'common' })}</>}
                        dataIndex="invoiceItem"
                        key="productName"
                        width={300}
                        render={(invoiceItem, _) => {
                            const productName = invoiceItem.productName;

                            return (
                                <p className="table-tbody-text">
                                    <Tooltip title={productName}>{productName}</Tooltip>
                                </p>
                            );
                        }}
                    />
                    <Table.Column
                        title={<>{t('subscriptions.nextInvoiceDate', { ns: 'common' })}</>}
                        dataIndex="currentPeriodEnd"
                        key="nextInvoiceDate"
                        width={200}
                        render={(nextInvoiceDate, record: IMarketplace.ISubscriptionInvoice) => {
                            return (
                                <p className="table-tbody-text">
                                    {formatAppSubscriptionPeriodEnd(
                                        RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                        nextInvoiceDate as Date,
                                        record,
                                    )}
                                </p>
                            );
                        }}
                    />
                    <Table.Column
                        title={<>{t('subscriptions.price', { ns: 'common' })}</>}
                        dataIndex="invoiceItem"
                        key="price"
                        width={150}
                        render={(invoiceItem, _) => {
                            const price = invoiceItem.total;

                            return <p className="table-tbody-text">{convertPrice(price)}</p>;
                        }}
                    />
                    <Table.Column
                        title={<>{t('subscriptions.frequencyTitle', { ns: 'common' })}</>}
                        dataIndex="invoiceItem"
                        key="frequency"
                        width={150}
                        render={(invoiceItem, _) => {
                            const frequency = t(
                                'subscriptions.frequency.' + invoiceItem?.frequency.toString(),
                                { ns: 'common' },
                            );

                            return <p className="table-tbody-text">{frequency}</p>;
                        }}
                    />
                </Table>
            </div>
            <div className="pagination-container pt-3 pb-4 px-6">
                <CustomPagination
                    pageSize={pageSize}
                    total={tableResult?.total}
                    current={current}
                    onChange={(value: any) => {
                        setCurrent(value);
                    }}
                    showSizeChanger={false}
                    showLessItems
                />
            </div>
        </div>
    );
};
