import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { notification } from 'antd';

import { TOKEN_KEY } from '../../authProvider';
import { getEnvConfig } from '../../getEnvConfig';
import Response from '@responses/response';

export default class BaseApi {
    private httpClient: AxiosInstance;

    private config = getEnvConfig;

    constructor() {
        const { API_URL } = getEnvConfig;

        this.httpClient = axios.create({
            baseURL: API_URL || '',
            withCredentials: false,
            timeout: 60 * 1000,
        });

        this.httpClient.interceptors.request = axios.interceptors.request;

        this.httpClient.interceptors.request.use((request) => {
            if (request.headers) {
                const token = this.getAccessToken();
                request.headers['Authorization'] = `Bearer ${token}`;
            }
            return request;
        });
    }

    public async get<T>(
        endPoint: string,
        params?: {
            [param: string]: string | string[] | undefined;
        },
        headers?: {
            [header: string]: string;
        },
    ): Promise<Response<T | null>> {
        return (
            this.httpClient
                .get<T>(endPoint, { params: params, headers: headers })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response: any) => {
                    const result = response.data;
                    return result;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    return new Response(null, [error], false);
                })
        );
    }

    public async delete<T>(
        endPoint: string,
        params?: {
            [param: string]: string | string[];
        },
        headers?: {
            [header: string]: string;
        },
    ): Promise<Response<T | null>> {
        return (
            this.httpClient
                .delete<T>(`${endPoint}`, { params: params, headers: headers })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response: any) => {
                    const result = response.data;
                    return new Response(result, [], true);
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    return new Response(null, [error], false);
                })
        );
    }
    public async post<T>(
        endPoint: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: Record<string, any>,
        params?: {
            [param: string]: string | string[];
        },
        headers?: AxiosRequestHeaders,
    ): Promise<Response<T | null>> {
        return (
            this.httpClient
                .post<T>(`${endPoint}`, data, {
                    params: params,
                    headers: headers,
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response: any) => {
                    const result = response.data;
                    return result;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    return new Response(null, [error], false);
                })
        );
    }

    public async put<T>(
        endPoint: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: Record<string, any>,
        params?: {
            [param: string]: string | string[];
        },
        headers?: {
            [header: string]: string;
        },
    ): Promise<Response<T | null>> {
        return (
            this.httpClient
                .put<T>(`${endPoint}`, data, {
                    params: params,
                    headers: headers,
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response: any) => {
                    const result = response.data;
                    return result;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    return new Response(null, [error], false);
                })
        );
    }

    public makeFormData(params: any) {
        const formData = new FormData();
        for (const prop in params) {
            if (params[prop] === null || params[prop] === undefined) {
                continue;
            }
            if (Array.isArray(params[prop])) {
                const arr = params[prop] as string[];

                arr.forEach((value) => {
                    formData.append(prop, value);
                });
            } else {
                formData.append(prop, params[prop]);
            }
        }
        return formData;
    }

    public createSuccessNotification(successMessage: string | React.ReactNode): void {
        notification.success({
            className: 'success-notification',
            message: '',
            description: successMessage,
        });
    }

    public createErrorNotification(successMessage: string | React.ReactNode): void {
        notification.error({
            className: 'error-notification',
            message: '',
            description: successMessage,
        });
    }

    public getAccessToken = (): string | null => {
        const accessToken = localStorage.getItem(TOKEN_KEY) as string;
        return accessToken;
    };

    public getPublicMediaUrl(mediaUrl?: string | null): string | null {
        if (!mediaUrl) {
            return null;
        }

        const { CDN } = this.config;
        return `${CDN}/${mediaUrl}`;
    }
}
