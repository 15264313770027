import { useTranslate } from '@refinedev/core';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button, Form } from 'antd';
import { useState } from 'react';

type PaymentFormProps = {
    onSkip: () => void;
};

const PaymentForm: React.FC<PaymentFormProps> = ({ onSkip }) => {
    const stripe = useStripe();
    const elements = useElements();
    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        const result = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
        });
        if (result.error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(result.error.message as string);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setLoading(false);
    };

    return (
        <Form onFinish={handleSubmit}>
            <PaymentElement />
            <Form.Item>
                <Button
                    className="w-full center mt-3"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                >
                    {t('onboarding.next')}
                </Button>
            </Form.Item>
            <a className="skip-add-payment" onClick={() => onSkip && onSkip()}>
                {t('onboarding.skip')}
            </a>
            {errorMessage && (
                <div className="mt-4">
                    <Alert message={errorMessage} type="error" />
                </div>
            )}
        </Form>
    );
};

export default PaymentForm;
