export const WlInvoicesMenuSidebarIcon: React.FC = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 0C16 0.552 15.552 1 15 1C14.448 1 14 0.552 14 0H12C12 0.552 11.552 1 11 1C10.448 1 10 0.552 10 0H8C8 0.552 7.552 1 7 1C6.448 1 6 0.552 6 0H4C4 0.552 3.552 1 3 1C2.448 1 2 0.552 2 0H0V18C0 19.105 0.895 20 2 20H16C17.105 20 18 19.105 18 18V0H16ZM10 16H4V14H10V16ZM10 12H4V10H10V12ZM14 16H12V14H14V16ZM14 12H12V10H14V12ZM14 7H4V5H14V7ZM16 18H2V3H16V18Z"
                fill="#7A838C"
            />
        </svg>
    );
};
