import { InfoCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT, DATE_FORMAT_TIME } from '@constants/index.constant';
import { diffTime, UnitEnum } from '@utils/date';
import { DatePicker, Tooltip } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

export const SECOND_PER_DAY = 24 * 60 * 60;
export const VALID_RANGE_TIME = 31 * SECOND_PER_DAY;

export const DateRangeFilter: React.FC<{
    dateRange: [string, string];
    setDateRange: (dateRange: [string, string]) => void;
    showTime?: boolean;
    allowClear?: boolean;
    setIsValidDay?: (value: boolean) => void;
}> = ({ dateRange, setDateRange, showTime = false, allowClear = false, setIsValidDay }) => {
    const { t } = useTranslation('common');
    const [error, setError] = useState<string>();

    const ranges: any = {
        [t('yesterday')]: [dayjs().add(-1, 'd').startOf('d'), dayjs().add(-1, 'd').endOf('d')],
        [t('today')]: [dayjs().startOf('d'), dayjs().endOf('d')],
        [t('last_7_days')]: [dayjs().add(-7, 'd').startOf('d'), dayjs().endOf('d')],
        [t('last_14_days')]: [dayjs().add(-14, 'd').startOf('d'), dayjs().endOf('d')],
        [t('last_30_days')]: [dayjs().add(-29, 'd').startOf('d'), dayjs().endOf('d')],
    };

    const setDateRangeValue = useCallback(
        debounce(
            (range: [string, string]) => {
                const format = showTime ? DATE_FORMAT_TIME : DATE_FORMAT;
                const startDate = dayjs(range[0], format);
                const endDate = dayjs(range[1], format);
                const isValid = isValidRange([startDate, endDate]);
                if (!isValid) return;

                if (showTime) {
                    setDateRange([startDate.toISOString(), endDate.toISOString()]);
                } else {
                    setDateRange([
                        startDate.startOf('d').toISOString(),
                        endDate.endOf('d').toISOString(),
                    ]);
                }
            },
            500,
            { leading: true },
        ),
        [showTime, error],
    );

    const isValidRange = (dates: Dayjs[]): boolean => {
        const time = diffTime([dates[0], dates[1]], UnitEnum.SECOND);

        if (time > VALID_RANGE_TIME) {
            setError(t('order.tooltip_date_range'));
            setIsValidDay && setIsValidDay(false);
            return false;
        }
        setError(undefined);
        setIsValidDay && setIsValidDay(true);
        return true;
    };

    return (
        <section className="filter">
            <div className="flex mb-2">
                <h3 className="mr-2">{t('filter_by_range', { ns: 'common' })}</h3>
                <Tooltip title={t('tooltip_date_range')}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <div className={`${error ? s.errorRange : s.filterRangeDate} custom-date-range`}>
                <RangePicker
                    className={`h-[42px] select-range-date`}
                    ranges={ranges}
                    disabledDate={(date) => date && date.isAfter(dayjs(), 'day')}
                    defaultValue={dateRange && [dayjs(dateRange[0]), dayjs(dateRange[1])]}
                    format={showTime ? DATE_FORMAT_TIME : DATE_FORMAT}
                    onChange={(dates: null | (Dayjs | null)[], dateString: [string, string]) => {
                        if (dates) {
                            setDateRangeValue(dateString);
                        }
                    }}
                    getPopupContainer={(trigger) => trigger}
                    showTime={showTime}
                    allowClear={allowClear}
                />
                {error && <p>{error}</p>}
            </div>
        </section>
    );
};
