import './styles.scss';

import MainLogo from '@assets/logo.svg';
import { Loading } from '@components/layout/loading';
import { IUser } from '@interfaces/user';
import { useKeycloak } from '@react-keycloak/web';
import { useForm } from '@refinedev/antd';
import {
    IResourceComponentsProps,
    useApiUrl,
    useCustomMutation,
    useNavigation,
    useTranslate,
} from '@refinedev/core';
import { validatePhoneNumber, validateZipCode } from '@utils/validator';
import { Button, Card, Col, Form, Image, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import React, { useEffect, useState } from 'react';

import { TOKEN_KEY } from '../../../authProvider';

export const StepTwoIndex: React.FC<IResourceComponentsProps<unknown>> = () => {
    const t = useTranslate();
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = useState<boolean>(false);
    const { formProps } = useForm<IUser.IPartner>();
    const { mutate } = useCustomMutation<any>();
    const apiUrl = useApiUrl();
    const { push } = useNavigation();
    const accessToken = localStorage.getItem(TOKEN_KEY);

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            const { tokenParsed } = keycloak;
            formProps.form?.setFieldsValue({ fullname: tokenParsed?.name });
        }
    }, [keycloak]);

    const partnerClasses: IUser.IPartnerClass[] = [
        {
            value: '1-2',
            label: '1-2',
        },
        {
            value: '3-49',
            label: '3-49',
        },
        {
            value: '50-99',
            label: '50-99',
        },
        {
            value: '100-',
            label: '100+',
        },
    ];

    const onFinish = (values: any) => {
        setLoading(true);
        const url = `${apiUrl}/v1/auth/onboarding/tenant/step-two`;
        mutate(
            {
                url: url,
                method: 'post',
                values: values,
                config: {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            },
            {
                onError: (error) => {
                    setLoading(false);
                },
                onSuccess: (data) => {
                    if (data && data.data) {
                        const paymentSecret = data.data?.data.paymentSecret;
                        push(
                            `/onboarding/step-three?${
                                paymentSecret ? 'code=' + data.data?.data.paymentSecret : ''
                            }`,
                        );
                    }
                },
            },
        );
    };

    return (
        <div className="onboarding-step-two-layout">
            <Row justify="center" align="middle">
                <Col md={24}>
                    {!loading && (
                        <div className="onboarding-container md:w-100 sm:w-100">
                            <Card
                                title={<Image src={MainLogo} preview={false} />}
                                headStyle={{ borderBottom: 0 }}
                            >
                                <div className="org-info">
                                    <div className="title">Welcome to Nuvioo</div>
                                    <div className="desc">
                                        Digital Transformation Solutions - Your gateway to The
                                        Nuvioo World
                                    </div>
                                </div>

                                <Form {...formProps} onFinish={onFinish} layout="vertical">
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('onboarding.validation.name')}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            required={true}
                                            maxLength={50}
                                            placeholder={`${t('onboarding.tenantName')}`}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="numberOfEmployees"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t(
                                                    'onboarding.validation.numberOfEmployees',
                                                )}`,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={`${t('onboarding.numberOfEmployees')}*`}
                                            options={partnerClasses}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="country"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('onboarding.validation.country')}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            required={true}
                                            maxLength={10}
                                            placeholder={`${t('onboarding.country')}*`}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="zipcode"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('onboarding.validation.zipcode')}`,
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (value && !validateZipCode(value)) {
                                                        return Promise.reject(
                                                            t(
                                                                'onboarding.validation.valid_zipcode',
                                                            ),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            required={true}
                                            maxLength={20}
                                            placeholder={`${t('onboarding.zipcode')}*`}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (
                                                        value &&
                                                        value !== '(___)-___-____' &&
                                                        !validatePhoneNumber(value)
                                                    ) {
                                                        return Promise.reject(
                                                            t('onboarding.validation.phone'),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <MaskedInput
                                            mask={
                                                //  https://imask.js.org/guide.html#masked-pattern
                                                '(000)-000-0000'
                                            }
                                            placeholder={`${t('onboarding.phone')}`}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className="w-full center"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {t('onboarding.next')}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </div>
                    )}
                    {loading && <Loading />}
                </Col>
            </Row>
        </div>
    );
};
