/* eslint-disable @typescript-eslint/no-var-requires */
const pdfMake = require('pdfmake');
import { Content, ContentTable, ContentText, StyleDictionary } from 'pdfmake/interfaces';
import { formatDate, getAssetPath } from './resource';

pdfMake.fonts = {
    Roboto: {
        normal: `${getAssetPath()}/fonts/Roboto-Regular.ttf`,
        bold: `${getAssetPath()}/fonts/Roboto-Bold.ttf`,
    },
};
export const COLOR_GRAY_STROKE = '#E7E7E7';
export const COLOR_NEUTRAL_LIGHT = '#F5F5F5';
export const COLOR_NEUTRAL_DARK = '#101010';
export const COLOR_SUBTITLE = '#707070';

export const headerBody = (
    fn: (rs: string, obj: any) => string,
    contactName?: string,
    paymentDate?: string,
    status?: string,
) => {
    const columnGap = 6;

    return [
        {
            columns: [
                contactName && {
                    width: '100%',
                    stack: [
                        contactName && {
                            columnGap: columnGap,
                            columns: [
                                {
                                    width: 'auto',
                                    text: `${fn('contacts.pdf.invoice.contact_name', {
                                        ns: 'contact',
                                    })}: `,
                                    style: 'label',
                                },
                                {
                                    width: '*',
                                    text: `${contactName}`,
                                    style: 'value',
                                },
                            ],
                        },
                    ],
                },
                paymentDate && {
                    width: '100%',
                    stack: [
                        paymentDate && {
                            columnGap: columnGap,
                            columns: [
                                {
                                    width: 'auto',
                                    text: `${fn('quotes.fields.paymentDate.label', {
                                        ns: 'quote',
                                    })} - `,
                                    style: 'label',
                                },
                                {
                                    width: '*',
                                    text: `${formatDate(paymentDate, 'MMM DD, YYYY')} - ${fn(
                                        `stripe.${status}`,
                                        {
                                            ns: 'common',
                                        },
                                    )}`,
                                    style: 'value',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};

export const renderTitleTable = (title: string): ContentText => {
    return {
        text: `#${title}`,
        style: 'tableTitle',
    };
};

export const assignLayoutTable = (tableBody: Content) => {
    Object.assign(tableBody, {
        layout: {
            fillColor: function (rowIndex: number) {
                if (rowIndex === 0) {
                    return COLOR_GRAY_STROKE;
                }
                return rowIndex % 2 === 0 ? COLOR_NEUTRAL_LIGHT : null;
            },
            vLineWidth: function (i: number, node: ContentTable) {
                return i === 0 || i === node.table.widths?.length ? 1 : 0;
            },
            hLineWidth: function (i: number, node: ContentTable) {
                return i === 0 || i === node.table.body.length ? 1 : 0;
            },
            hLineColor: function (i: number, node: ContentTable) {
                return i === 0 || i === node.table.body.length ? COLOR_GRAY_STROKE : null;
            },
            vLineColor: function (i: number, node: ContentTable) {
                return i === 0 || i === node.table.widths?.length ? COLOR_GRAY_STROKE : null;
            },
        },
    });
};

export const header = (title: string, logo: string): Content => {
    return [
        {
            stack: [
                {
                    columns: [
                        {
                            text: `${title}`,
                            style: 'title',
                            alignment: 'right',
                            bold: true,
                        },
                    ],
                    style: 'header',
                },

                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 40,
                            y1: 16,
                            x2: 555,
                            y2: 16,
                            lineWidth: 8,
                            lineColor: COLOR_GRAY_STROKE,
                        },
                    ],
                },
            ],
        },
    ];
};

export const footer = (currentPage: number): Content => {
    return [
        {
            canvas: [
                {
                    type: 'line',
                    x1: 40,
                    y1: 20,
                    x2: 555,
                    y2: 20,
                    lineWidth: 1,
                    lineColor: COLOR_GRAY_STROKE,
                },
            ],
            margin: [0, 10],
        },
        {
            text: currentPage.toString(),
            style: 'pageIndex',
            alignment: 'right',
            margin: [0, 12, 40, 20],
        },
    ];
};

export const styles = (): StyleDictionary => {
    return {
        title: {
            fontSize: 16,
            bold: true,
            color: COLOR_NEUTRAL_DARK,
        },
        header: {
            fontSize: 18,
            bold: true,
            margin: [40, 10, 40, 10],
        },
        subheader: {
            fontSize: 15,
            bold: true,
        },
        heading: {
            bold: true,
            marginTop: 12,
            fontSize: 12,
            marginBottom: 8,
        },
        headingWithMargin: {
            bold: true,
            marginTop: 12,
            fontSize: 12,
            marginBottom: -4,
        },
        tableExample: {
            marginBottom: 12,
        },
        tableTitle: {
            fontSize: 14,
            color: COLOR_NEUTRAL_DARK,
            margin: [0, 6],
            bold: true,
        },
        tableHeader: {
            fontSize: 12,
            bold: true,
            color: COLOR_SUBTITLE,
            margin: [6, 6],
        },
        normalText: {
            fontSize: 12,
            color: COLOR_NEUTRAL_DARK,
            margin: [6, 6],
        },
        boldText: {
            bold: true,
            fontSize: 12,
            color: COLOR_NEUTRAL_DARK,
            margin: [6, 6],
        },
        numberText: {
            fontSize: 12,
            color: COLOR_SUBTITLE,
            margin: [12, 6],
        },
        pageIndex: {
            fontSize: 8,
            color: COLOR_NEUTRAL_DARK,
        },
        label: {
            bold: false,
            color: COLOR_SUBTITLE,
            fontSize: 12,
        },
        value: {
            bold: true,
            color: COLOR_NEUTRAL_DARK,
            fontSize: 12,
            lineHeight: 1.8,
        },
        totalNumber: {
            bold: true,
            color: COLOR_NEUTRAL_DARK,
            fontSize: 32,
            lineHeight: 1.2,
        },
        totalLabel: {
            fontSize: 16,
            lineHeight: 1.4,
            color: COLOR_SUBTITLE,
        },
    };
};

export const defaultStyle = () => {
    return {
        font: 'Roboto',
    };
};
