import './styles.scss';

import { Select } from 'antd';

type FilterStatusProps = {
    listStatus: { label: string; value: number | string }[];
    placeholder: string;
    className?: string;
    defaultValue?: string;
    onChangeStatus: (value: string) => void;
};

export const FilterStatus: React.FC<FilterStatusProps> = (props) => {
    const { listStatus, onChangeStatus, placeholder, className, defaultValue } = props;
    const onChange = (value: string) => {
        onChangeStatus(value);
    };

    return (
        <>
            <Select
                allowClear
                placeholder={placeholder}
                onChange={onChange}
                options={listStatus}
                defaultValue={defaultValue || null}
                className={`filter-status-component ${className}`}
                getPopupContainer={(e) => {
                    return e.parentNode;
                }}
            />
        </>
    );
};
