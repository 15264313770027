import { AuthBindings } from '@refinedev/core';
import { IUser } from './interfaces/user';
import { getEnvConfig } from './getEnvConfig';
import jwt_decode from 'jwt-decode';
import keycloak from './authentication/keycloak';
export const TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const USER_TYPE = 'user_type';
export const KL_TOKEN_KEY = 'kl_access_token';
export const KL_REFRESH_TOKEN_KEY = 'kl_refresh_token';
export const ID_TOKEN_KEY = 'id_token';
export const USER_INFO = 'auth';
export const USER_RIGHTS = 'user_rights';
export const NO_PERMISSION_ITEMS = ['dashboard', 'notification', 'tenant_helpdesk'];
export const AUTH_URL = {
    LOGIN: getEnvConfig.API_URL + '/v1/auth/login',
    REFRESH_TOKEN: getEnvConfig.API_URL + '/v1/auth/refresh-token',
};
export const authProvider: AuthBindings & {
    getToken: () => string | null;
    getRefreshToken: () => string | null;
    getKcRefreshToken: () => string | null;
    getIdToken: () => string | null;
    getKlToken: () => string | null;
    refreshToken: (payload: { token: string; refreshToken: string }) => void;
} = {
    onError: async (err) => {
        if (err.status === 401 || err.status === 403) {
            return {
                redirectTo: '/login',
                logout: true,
                error: err,
            };
        }

        return {};
    },
    login: async (loginPayload) => {
        if (loginPayload?.data?.token) {
            const { token, refreshToken, idToken } = keycloak;
            localStorage.setItem(TOKEN_KEY, loginPayload.data?.token as string);
            localStorage.setItem(REFRESH_TOKEN_KEY, loginPayload.data?.refreshToken as string);
            localStorage.setItem(KL_TOKEN_KEY, token as string);
            localStorage.setItem(KL_REFRESH_TOKEN_KEY, refreshToken as string);
            localStorage.setItem(ID_TOKEN_KEY, idToken as string);

            if (keycloak.authenticated && !loginPayload?.preventKcLogout) {
                await keycloak.logout({ redirectUri: loginPayload?.redirectUrl });
            }

            if (loginPayload?.redirectUrl && window?.location) {
                window.location.href = loginPayload?.redirectUrl;
            }

            return Promise.resolve({ success: false });
        }
        return Promise.reject();
    },
    logout: async (payload: { redirectPath?: string }) => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(KL_TOKEN_KEY);
        localStorage.removeItem(KL_REFRESH_TOKEN_KEY);
        localStorage.removeItem(ID_TOKEN_KEY);
        localStorage.removeItem(USER_RIGHTS);
        localStorage.removeItem(USER_INFO);
        if (payload?.redirectPath) {
            await keycloak.logout({ redirectUri: payload.redirectPath });
        } else {
            await keycloak.logout();
        }
        return Promise.resolve({ success: false });
    },
    refreshToken: async (payload: { token: string; refreshToken: string }) => {
        localStorage.setItem(TOKEN_KEY, payload.token as string);
        localStorage.setItem(REFRESH_TOKEN_KEY, payload.refreshToken as string);
    },
    check: () => {
        // return Promise.resolve();
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            return Promise.resolve({ authenticated: true });
        }

        return Promise.reject();
    },
    getPermissions: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }
        const user: IUser.IUserIdentity = jwt_decode(token);
        const permissions = user.rights || [];

        if (!permissions) {
            return Promise.reject();
        }

        return Promise.resolve(permissions);
    },
    getIdentity: async (): Promise<IUser.IUserIdentity> => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }

        try {
            const user: IUser.IUserIdentity = jwt_decode(token);
            return Promise.resolve(user);
        } catch (err) {
            return Promise.reject();
        }
    },
    getToken: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return null;
        }

        return token;
    },
    getRefreshToken: () => {
        const token = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (!token) {
            return null;
        }

        return token;
    },
    getKcRefreshToken: () => {
        const token = localStorage.getItem(KL_REFRESH_TOKEN_KEY);
        if (!token) {
            return null;
        }

        return token;
    },
    getIdToken: () => {
        const token = localStorage.getItem(ID_TOKEN_KEY);
        if (!token) {
            return null;
        }

        return token;
    },
    getKlToken: () => {
        const token = localStorage.getItem(KL_TOKEN_KEY);
        if (!token) {
            return null;
        }

        return token;
    },
};
