import './styles.scss';

import { DownloadPrint } from '@components/internal-crm/contact/download-pdf';
import { DateRangeFilter } from '@components/internal-crm/reports/settlements/date-range-filter';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { ExportTypeEnum } from '@enums/export-file.enum';
import { Payout } from '@interfaces/payouts';
import { BaseRecord, useList, useTranslate } from '@refinedev/core';
import { Col, Row, Space, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useFilterContext } from 'contexts/filter.context';
import { DataProviderNameEnum } from 'dataProvider';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { convertPrice, formatDate } from 'utils/resource';

interface IProps {
    name?: string;
}

export const PAYOUT_RESOURCE_API = 'v1/report/payouts';

export const Payouts: React.FC<IProps> = () => {
    const translate = useTranslate();
    const { filter, onSetDateRange } = useFilterContext();
    const timeZone = moment.tz.guess();
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const {
        data: payoutData,
        isFetching,
        refetch: getPayouts,
    } = useList<any>({
        dataProviderName,
        resource: PAYOUT_RESOURCE_API,
        queryOptions: { enabled: false },
        config: {
            hasPagination: false,
            filters: [
                { field: 'dateRange', operator: 'eq', value: filter.dateRange },
                { field: 'timeZone', operator: 'eq', value: timeZone },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: pageSize,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
            ],
        },
    });

    const columns: ColumnsType<Payout.IPayoutInternalCrm> = [
        {
            title: translate('payouts.created_at'),
            dataIndex: 'createdAt',
            sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (_, item) => {
                return <div className="table-tbody-text">{formatDate(item?.createdAt)}</div>;
            },
        },
        {
            title: translate('payouts.invoice_id'),
            dataIndex: 'invoiceIncrementId',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.invoiceIncrementId}</div>;
            },
        },
        {
            title: translate('payouts.product_name'),
            dataIndex: 'productName',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.productName}</div>;
            },
        },
        {
            title: translate('payouts.amount'),
            dataIndex: 'amount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
            render: (_, item) => {
                return <div className="table-tbody-text">{convertPrice(item?.totalAmount)}</div>;
            },
        },
    ];

    useEffect(() => {
        setPageIndex(1);
        getPayouts();
    }, [filter.dateRange]);

    useEffect(() => {
        getPayouts();
    }, [pageIndex, pageSize]);

    const _payoutData = payoutData?.data;
    return (
        <Spin spinning={isFetching}>
            <section className="payouts-container w-full h-full">
                <div className="flex justify-between filter-wrapper-container">
                    <Space size={16} direction="horizontal" wrap={true} className="mb-8">
                        <DateRangeFilter
                            dateRange={filter?.dateRange}
                            setDateRange={onSetDateRange}
                        />
                    </Space>

                    <DownloadPrint
                        title={translate('payouts.title', { ns: 'dashboard' })}
                        dateRange={filter?.dateRange}
                        reportType={ExportTypeEnum.PAYOUTS}
                        timeZone={timeZone}
                        isExportExcel
                    />
                </div>
                <Row className="payouts-table-container">
                    <Col span={24} className="flex flex-col">
                        <Table
                            className="payouts-table"
                            columns={columns}
                            dataSource={_payoutData || []}
                            loading={payoutData?.isLoading}
                            pagination={false}
                            scroll={{ x: '700px', y: '100%' }}
                        />
                    </Col>
                    <Col span={24}>
                        <div className="pagination-container pt-3 pb-4 px-6">
                            <CustomPagination
                                pageSize={pageSize}
                                total={payoutData?.total}
                                current={pageIndex}
                                onChange={(value: any, size: any) => {
                                    setPageIndex(value);
                                    setPageSize(size);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </section>
        </Spin>
    );
};
