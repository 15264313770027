import { Col, Form, Input, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const InformationForm: FC<{
    onChangeInformationForm: (type: string, value: string) => void;
}> = ({ onChangeInformationForm }) => {
    const { t } = useTranslation(['common']);

    return (
        <Row gutter={24}>
            <Col span={24} md={12}>
                <Form.Item
                    name="firstName"
                    label={t('crm_internal.payout_setting.first_name')}
                    rules={[
                        {
                            required: true,
                            message: t('crm_internal.payout_setting.first_name_required'),
                        },
                        {
                            max: 100,
                            message: t('crm_internal.payout_setting.first_name_max_length'),
                        },
                    ]}
                >
                    <Input
                        placeholder={t('crm_internal.payout_setting.first_name_placeholder', {
                            ns: 'common',
                        })}
                        onChange={(e) => {
                            onChangeInformationForm('firstName', e.target.value);
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <Form.Item
                    name="lastName"
                    label={t('crm_internal.payout_setting.last_name')}
                    rules={[
                        {
                            required: true,
                            message: t('crm_internal.payout_setting.last_name_required'),
                        },
                        {
                            max: 100,
                            message: t('crm_internal.payout_setting.last_name_max_length'),
                        },
                    ]}
                >
                    <Input
                        placeholder={t('crm_internal.payout_setting.last_name_placeholder', {
                            ns: 'common',
                        })}
                        onChange={(e) => {
                            onChangeInformationForm('lastName', e.target.value);
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="email"
                    label={t('crm_internal.payout_setting.email')}
                    rules={[
                        {
                            required: true,
                            message: t('crm_internal.payout_setting.email_required'),
                        },
                        {
                            type: 'email',
                            message: t('crm_internal.payout_setting.email_invalid'),
                        },
                    ]}
                >
                    <Input
                        placeholder={t('crm_internal.payout_setting.email_placeholder')}
                        onChange={(e) => {
                            onChangeInformationForm('email', e.target.value);
                        }}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};
