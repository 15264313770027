import { Image } from 'antd';
import React from 'react';

import MainLogo from '../assets/logo.svg';

export const Loading: React.FC = () => {
    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Image preview={false} src={MainLogo} />
        </div>
    );
};
