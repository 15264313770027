import './styles.scss';

import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IService } from '@interfaces/service';
import { ITenant } from '@interfaces/tenant';
import { IUser } from '@interfaces/user';
import { DeleteButton } from '@refinedev/antd';
import {
    BaseKey,
    useGetIdentity,
    useNavigation,
    useOne,
    usePermissions,
    useTranslate,
} from '@refinedev/core';
import { getPublicMediaUrl } from '@utils/resource';
import { Badge, Card, Divider, Dropdown, Image, Menu, Typography } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useEffect, useState } from 'react';

const { Paragraph } = Typography;

type ServiceItemProps = {
    item: IService;
    editShow: (id?: BaseKey) => void;
};

export const ServiceCrmItem: React.FC<ServiceItemProps> = ({ item, editShow }) => {
    const t = useTranslate();
    const { data: rights } = usePermissions<string[]>();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const { push } = useNavigation();

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const [serviceName, setServiceName] = useState<string>('');
    const { data: profileData, refetch: refetchProfile } = useOne<ITenant.ITenantDetail>({
        resource: 'v1/tenant',
        id: item.tenantId as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        refetchProfile();
    }, [item]);

    useEffect(() => {
        if (profileData?.data) {
            setServiceName(profileData.data.name);
        }
    }, [profileData?.data]);

    return (
        <Badge.Ribbon
            className="name-service"
            text={serviceName}
            style={{
                display: `${userIdentity?.userType == UserTypeEnum.CLIENT ? 'none' : 'block'}`,
            }}
            color="green"
        >
            <Card className="service-item">
                <div className="service-item-wrapper">
                    <div
                        style={{ textAlign: 'center' }}
                        className="cursor-pointer"
                        onClick={() => push(`/sale/services/show/${item.id}`)}
                    >
                        <Image
                            preview={false}
                            src={getPublicMediaUrl(item.featureImageUrl)}
                            alt={item.name}
                        />
                    </div>
                    <Paragraph ellipsis={{ rows: 2, tooltip: true }} className="service-name h-16">
                        {item.name}
                    </Paragraph>

                    <Divider />

                    <div className="actions">
                        <div className="type-service">
                            <span className="chip">
                                <span className={`content ${item.isActive ? '' : 'inactive'}`}>
                                    <span className="dot">&nbsp;</span>
                                    <span className="label">
                                        {item.isActive
                                            ? t('services.isActive')
                                            : t('services.inactive')}
                                    </span>
                                </span>
                            </span>
                        </div>
                        {item.tenantId === userIdentity?.tenantId &&
                        (rights || []).some((right) =>
                            [PERMISSIONS.WL_SERVICE_EDIT, PERMISSIONS.WL_SERVICE_DELETE].includes(
                                right,
                            ),
                        ) ? (
                            <div className="service-action">
                                <Dropdown
                                    overlayClassName="service-dropdown-container"
                                    overlay={
                                        <Menu mode="vertical">
                                            {(rights || []).indexOf(PERMISSIONS.WL_SERVICE_EDIT) >=
                                                0 && (
                                                <Menu.Item
                                                    key="1"
                                                    style={{
                                                        fontWeight: 500,
                                                    }}
                                                    icon={
                                                        <FormOutlined
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    }
                                                    onClick={() => editShow(item.id)}
                                                >
                                                    {t('services.buttons.edit')}
                                                </Menu.Item>
                                            )}
                                            {(rights || []).indexOf(
                                                PERMISSIONS.WL_SERVICE_DELETE,
                                            ) >= 0 && (
                                                <Menu.Item
                                                    key="2"
                                                    style={{ padding: '0px' }}
                                                    icon={
                                                        <DeleteButton
                                                            dataProviderName={dataProviderName}
                                                            resource="v1/services"
                                                            recordItemId={item.id}
                                                            successNotification={(data: any) => {
                                                                if (data.data.errors[0] === '403') {
                                                                    return {
                                                                        message: `${t(
                                                                            'services.notification.delete_empty_service',
                                                                        )}`,
                                                                        type: 'error',
                                                                    };
                                                                }
                                                                if (!data.data.data) {
                                                                    return {
                                                                        message: `${t(
                                                                            'services.notification.delete_fail',
                                                                        )}`,
                                                                        type: 'error',
                                                                    };
                                                                }
                                                                return {
                                                                    message: `${t(
                                                                        'services.notification.delete_success',
                                                                    )}`,
                                                                    type: 'success',
                                                                };
                                                            }}
                                                            style={{
                                                                padding: '5px 12px',
                                                                margin: '0px',
                                                            }}
                                                        />
                                                    }
                                                ></Menu.Item>
                                            )}
                                        </Menu>
                                    }
                                    trigger={['click']}
                                >
                                    <EllipsisOutlined
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Card>
        </Badge.Ribbon>
    );
};
