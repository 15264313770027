import Settlements from '@components/internal-crm/reports/settlements';
import { IResourceComponentsProps } from '@refinedev/core';
import { FilterContextProvider } from 'contexts/filter.context';

export const SettlementsCrmIndex: React.FC<IResourceComponentsProps> = () => {
    return (
        <FilterContextProvider>
            <Settlements />
        </FilterContextProvider>
    );
};
