import './styles.scss';

import React from 'react';

import { BillingPaymentMethod } from '../../../components/setting/billing-payment-method/billing-payment-method';

interface IGeneralSettingProps {
    name?: string;
}

export const BillingSetting: React.FC<IGeneralSettingProps> = () => {
    return (
        <>
            <BillingPaymentMethod />
        </>
    );
};
