import './styles.scss';

import { ITenant } from '@interfaces/tenant';
import { useModal } from '@refinedev/antd';
import { useCreate } from '@refinedev/core';
import Response from '@responses/response';
import { errorNotification } from '@utils/noti';
import { Form, Input, Modal, Spin } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IPayload {
    tenantId: string;
    userId?: string;
}

interface IPinConfirmProps {
    visible: boolean;
    confirmData: IPayload;
    title: string;
    setVisible: (visible: boolean) => void;
    onClose?: (data: ITenant.ILoginOnBehalf | null) => void;
    dataProviderName?: DataProviderNameEnum;
}

interface IPinForm {
    userId: string;
    pin: string;
}

export const PINConfirm: React.FC<IPinConfirmProps> = ({
    visible,
    confirmData,
    setVisible,
    onClose,
    title,
    dataProviderName = DataProviderNameEnum.DEFAULT,
}) => {
    const { t: translate } = useTranslation('common');
    const { modalProps, close, show } = useModal();

    const [form] = Form.useForm<IPinForm>();
    const { mutate: verify, isLoading: isVerifying } =
        useCreate<Response<ITenant.ILoginOnBehalf>>();

    useMemo(() => {
        if (visible) {
            show();
        }
    }, [visible]);

    const onSubmit = async () => {
        try {
            const isValid = await form.validateFields();
            if (isValid) {
                const values = form.getFieldsValue();
                const payload = { ...values, tenantId: confirmData?.tenantId };
                if (confirmData?.userId) {
                    payload.userId = confirmData.userId;
                }
                verify(
                    {
                        resource: 'v1/auth/login-on-behalf',
                        values: payload,
                        errorNotification: false,
                        successNotification: false,
                        dataProviderName,
                    },
                    {
                        onSuccess: (res) => {
                            if (res?.data?.data) {
                                setVisible(false);
                                close();
                                onClose && onClose(res?.data?.data);
                            } else {
                                errorNotification(translate('pin.errors.login_on_behalf_failed'));
                            }
                        },
                        onError: (err) => {
                            switch (err.message) {
                                case 'nuvioo_domain_config_not_set':
                                    errorNotification(
                                        translate('pin.errors.nuvioo_domain_config_not_set'),
                                    );
                                    break;

                                case 'user_is_not_found':
                                    errorNotification(translate('pin.errors.user_is_not_found'));
                                    break;

                                case 'pin_or_user_id_is_empty':
                                    errorNotification(
                                        translate('pin.errors.pin_or_user_id_is_empty'),
                                    );
                                    break;

                                case 'current_user_is_not_nuvioo_user':
                                    errorNotification(
                                        translate('pin.errors.current_user_is_not_nuvioo_user'),
                                    );
                                    break;

                                case 'pin_or_tenant_id_is_empty':
                                    errorNotification(
                                        translate('pin.errors.pin_or_tenant_id_is_empty'),
                                    );
                                    break;

                                case 'tenant_is_not_found':
                                    errorNotification(translate('pin.errors.tenant_is_not_found'));
                                    break;

                                case 'pin_is_null_or_incorrect':
                                    errorNotification(
                                        translate('pin.errors.pin_is_null_or_incorrect'),
                                    );
                                    break;

                                case 'tenant_is_not_active':
                                    errorNotification(translate('pin.errors.tenant_is_not_active'));
                                    break;

                                case 'current_user_is_not_enough_login_on_behalf_permission':
                                    errorNotification(
                                        translate(
                                            'pin.errors.current_user_is_not_enough_login_on_behalf_permission',
                                        ),
                                    );
                                    break;

                                default:
                                    errorNotification(
                                        translate('pin.errors.login_on_behalf_failed'),
                                    );
                            }
                        },
                    },
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Modal
                {...modalProps}
                className="pin-confirm-container"
                title={title ? <span>{title}</span> : ''}
                onOk={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                confirmLoading={isVerifying}
                onCancel={() => {
                    setVisible(false);
                    close();
                    // some callback after modal close
                    onClose && onClose(null);
                }}
                okText={translate('pin.confirm')}
                cancelText={translate('buttons.cancel')}
                maskClosable={false}
                closable={false}
            >
                {confirmData?.tenantId ? (
                    <Form form={form} layout="vertical" name="form-pin" className="form-pin mt-4">
                        <Form.Item
                            name="pin"
                            label="PIN"
                            rules={[
                                {
                                    required: true,
                                    message: translate('pin.errors.pin_required'),
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Form>
                ) : (
                    <Spin></Spin>
                )}
            </Modal>
        </>
    );
};
