import './styles.scss';

import { DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import { OpenLinkIcon } from '@components/layout/sidebar/icons/open-link-icon';
import { PERMISSIONS } from '@constants/permission';
import { DocumentTypeEnum } from '@enums/Document-type.enum';
import { IDocument } from '@interfaces/document';
import { DeleteButton } from '@refinedev/antd';
import { BaseKey, useOne, usePermissions, useTranslate } from '@refinedev/core';
import { formatDate, getPublicMediaUrl } from '@utils/resource';
import { Dropdown, Menu, notification, Tooltip } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';

type FileItemProps = {
    file: IDocument;
    onDeleteSuccess?: (id?: string) => void;
};

export const FileItemCrm: React.FC<FileItemProps> = (props) => {
    const { file, onDeleteSuccess } = props;
    const { data: rights } = usePermissions<string[]>();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const t = useTranslate();
    const { refetch: refetchDocument } = useOne<IDocument>({
        dataProviderName,
        resource: 'v1/documents',
        id: file.id as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    const download = async () => {
        const { data } = await refetchDocument();
        if (!data?.data) {
            notification.error({
                message: 'Resource has been deleted, please reload the page to continue ',
            });
            return;
        }
        const fileUrl = getPublicMediaUrl(data.data.url);
        fetch(fileUrl)
            .then((res) => res.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = data.data.name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => console.warn(err));
    };

    const openInNewTab = async () => {
        const { data } = await refetchDocument();
        if (!data?.data) {
            notification.error({
                message: 'Resource has been deleted, please reload the page to continue ',
            });
            return;
        }
        window.open(data.data.url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <Tooltip title={file.name}>
                <div className="item-details cursor-pointer">
                    <div className="flex items-center">
                        <div className="img">
                            {file.type.indexOf('doc') > 0 && <img src="/images/icons/word.svg" />}
                            {file.type.indexOf('xls') > 0 && <img src="/images/icons/excel.svg" />}
                            {file.type.indexOf('ppt') > 0 && (
                                <img src="/images/icons/powerpoint.svg" />
                            )}
                            {file.type.indexOf('pdf') > 0 && <img src="/images/icons/pdf.svg" />}
                            {file.type === DocumentTypeEnum.LINK && (
                                <img src="/images/icons/link-folder.svg" />
                            )}
                        </div>
                        <div className="ml-auto">{formatDate(file.createdAt)}</div>
                    </div>

                    <div className="overflow-clip whitespace-nowrap text-ellipsis mt-3">
                        {file.name}
                    </div>
                    <div className="action mt-3">
                        <div className="flex">
                            {file.type === DocumentTypeEnum.LINK ? (
                                <div onClick={() => openInNewTab()} className="open-url">
                                    <OpenLinkIcon />
                                    {t('resource.label.open')}
                                </div>
                            ) : (
                                <div className="download" onClick={() => download()}>
                                    <DownloadOutlined /> {t('resource.label.download')}
                                </div>
                            )}

                            <div className="ml-auto">
                                {(rights || []).indexOf(PERMISSIONS.WL_RESOURCE_DELETE) >= 0 && (
                                    <Dropdown
                                        overlayClassName="service-dropdown-container"
                                        overlay={
                                            <Menu mode="vertical">
                                                <Menu.Item
                                                    key="1"
                                                    icon={
                                                        <DeleteButton
                                                            dataProviderName={dataProviderName}
                                                            successNotification={{
                                                                message: 'Successfully deleted',
                                                                type: 'success',
                                                            }}
                                                            resource="v1/documents"
                                                            recordItemId={file.id}
                                                            onSuccess={(value) => {
                                                                if (onDeleteSuccess) {
                                                                    onDeleteSuccess(file.id);
                                                                }
                                                            }}
                                                            style={{
                                                                margin: 0,
                                                                padding: '5px 12px',
                                                            }}
                                                        />
                                                    }
                                                    style={{ padding: 0 }}
                                                ></Menu.Item>
                                            </Menu>
                                        }
                                        trigger={['click']}
                                    >
                                        <EllipsisOutlined
                                            style={{
                                                fontSize: 24,
                                            }}
                                        />
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Tooltip>
        </>
    );
};
