import { PlusOutlined } from '@ant-design/icons';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { useSelect } from '@refinedev/antd';
import { useCustom, useGetIdentity } from '@refinedev/core';
import { formatPrice } from '@utils/resource';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { keyBy } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    form: any;
    dataProviderName: DataProviderNameEnum;
    orderId: string | null;
    apiUrl: string;
    id?: string;
}
export const ItemsDetail: FC<IProps> = (props) => {
    const { form, dataProviderName, apiUrl, id } = props;

    const { t } = useTranslation(['quote', 'common']);
    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
    const [items, setItems] = useState<any>([]);
    const [totalOrder, setTotalOrder] = useState<number>(0);
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const isReseller = userIdentity?.userType === UserTypeEnum.RESELLER;

    const { data: saleEarning, refetch: getSaleEarnings } = useCustom({
        url: `${apiUrl}/v1/sales/sale-earning`,
        method: 'post',
        queryOptions: {
            enabled: false,
        },
        config: {
            payload: {
                items,
            },
        },
    });

    useEffect(() => {
        onChangeItems();
        onCalculateTotalPrice();
    }, [form.getFieldValue('items')]);

    useEffect(() => {
        if (items.some((item: any) => !item.productId || !item.qty)) return;
        isReseller && getSaleEarnings();
    }, [items]);

    const { selectProps: serviceSelectProps, queryResult } = useSelect<IService>({
        dataProviderName,
        resource: 'v1/services/active-services',
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            mode: 'server',
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_services', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_services_failed', { ns: 'common' }),
                };
            }
        },
    });

    const services: any = keyBy(queryResult.data?.data, 'id');

    const onChangeProduct = (productId: string, index: number) => {
        const items = form.getFieldValue('items');
        const products = services[items[index].serviceId].products;
        const selectedProduct = products.find((p: any) => p.id === productId);

        items[index].productName = selectedProduct.name;
        items[index].unitPrice = selectedProduct.price;
        items[index].qty = 1;
        items[index].frequency = selectedProduct.frequency;
        items[index].margin = selectedProduct.margin;

        form.setFieldsValue({ items: items });
        onChangeItems();
        onCalculateTotalPrice();
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeItems = () => {
        const items = form.getFieldValue('items');

        const itemCalculateSaleEarning = items.map((item: any) => {
            return {
                productId: item.productId,
                qty: item.qty,
            };
        });

        setItems(itemCalculateSaleEarning);
    };

    const onCalculateTotalPrice = () => {
        const items = form?.getFieldValue('items');
        let totalPrice = 0;
        if (items) {
            let total = 0;
            for (const item of items) {
                total += item?.qty * item?.unitPrice || 0;
            }

            totalPrice = total;
        }
        setTotalOrder(totalPrice);
    };

    const onChangeService = (serviceId: string, index: number) => {
        const items = form.getFieldValue('items');
        items[index].productId = undefined;
        items[index].productName = null;

        items[index].unitPrice = null;
        items[index].qty = items[index].qty || 1;
        items[index].frequency = null;
        items[index].margin = 0;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeQty = () => {
        setServiceUpdated(!serviceUpdated);
        onChangeMargin();
        onChangeItems();
    };

    const onChangeMargin = () => {
        onCalculateTotalPrice();
        setServiceUpdated(!serviceUpdated);
    };

    return (
        <div>
            <div className="section-header">{t('quotes.heading.itemDetails')}</div>
            <Row className="item-list">
                <Col span={24}>
                    <Row
                        className={` ${
                            window.screen.width < 578 ? 'table-header-none' : ''
                        } table-header`}
                    >
                        <Col span={6}>{t('quotes.fields.item.label')}</Col>
                        <Col span={6}>{t('quotes.fields.unitPrice.label')}</Col>
                        <Col span={4}>{t('quotes.fields.qty.label')}</Col>
                        <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                        <Col span={4}>{t('quotes.fields.total.label')}</Col>
                    </Row>
                    <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index: number) => {
                                    const formItem = form.getFieldValue('items')[index];
                                    const total = formItem?.unitPrice * formItem?.qty || 0;
                                    // Check if the item is an existing one by looking for the id
                                    const isExistingItem = !!formItem.id;
                                    const canDelete = form.getFieldValue('items')?.length > 1;

                                    return (
                                        <Row className="list-product-item item-custom" key={key}>
                                            <Col xs={24} sm={6}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'serviceId']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.item.required',
                                                            ),
                                                        },
                                                    ]}
                                                    label={t('quotes.fields.item.label')}
                                                >
                                                    <Select
                                                        getPopupContainer={(trigger) =>
                                                            trigger.parentNode
                                                        }
                                                        options={[
                                                            ...(serviceSelectProps.options || []),
                                                        ]}
                                                        showSearch={false}
                                                        onChange={(item: any) =>
                                                            onChangeService(item, index)
                                                        }
                                                        placeholder={'Select service'}
                                                        disabled={isExistingItem}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'productId']}
                                                    label={t('quotes.fields.product.label')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.product.required',
                                                            ),
                                                        },
                                                    ]}
                                                    className="product-custom"
                                                >
                                                    <Select
                                                        getPopupContainer={(trigger) =>
                                                            trigger.parentNode
                                                        }
                                                        showSearch={false}
                                                        placeholder={t(
                                                            'quotes.fields.product.placeholder',
                                                        )}
                                                        onChange={(item) =>
                                                            onChangeProduct(item, index)
                                                        }
                                                        disabled={isExistingItem}
                                                    >
                                                        {services[
                                                            formItem?.serviceId
                                                        ]?.products.map((product: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={product.id}
                                                                    value={product.id}
                                                                >
                                                                    {product.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={6}>
                                                <div className="price-field cursor-pointer">
                                                    {formatPrice(formItem?.unitPrice * 1)}
                                                    {isReseller && formItem.margin ? (
                                                        <span className="charge">
                                                            <b>
                                                                {t('quotes.fields.margin.label')} :{' '}
                                                            </b>
                                                            {`${formItem.margin}%`}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col xs={24} sm={4}>
                                                <Form.Item
                                                    {...restField}
                                                    label={t('quotes.fields.qty.label')}
                                                    name={[name, 'qty']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.qty.required',
                                                            ),
                                                        },
                                                        {
                                                            min: 1,
                                                            type: 'number',
                                                            message:
                                                                'Quantity cannot be less than 1',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        defaultValue={1}
                                                        step={1}
                                                        precision={0}
                                                        onChange={onChangeQty}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'frequency']}
                                                    label={t('quotes.fields.frequency.label')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.frequency.required',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <span>
                                                        {t(
                                                            'services.frequency.' +
                                                                ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                            { ns: 'common' },
                                                        )}
                                                    </span>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={4} className="total-wrapper ">
                                                <span className="total-price">
                                                    {t('quotes.fields.total.label')}
                                                </span>
                                                <div className="total-container">
                                                    <span>{formatPrice(total)}</span>
                                                    {canDelete && (
                                                        <img
                                                            className="ml-auto"
                                                            src="/images/icons/remove.svg"
                                                            onClick={() => {
                                                                remove(name);
                                                                onChangeQty();
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                                <div className="btn-add-container">
                                    <Button
                                        onClick={() => add({ qty: 1 })}
                                        type="link"
                                        icon={<PlusOutlined />}
                                    >
                                        {t('quotes.actions.addItem')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <div className="sum-total flex">
                <span>{t('quotes.heading.totalPrice')}</span>
                <span className="ml-auto">{formatPrice(totalOrder)}</span>
            </div>
            {isReseller && (
                <div className="sum-total flex">
                    <span>{t('quotes.heading.earnings')}</span>
                    <span className="ml-auto">{formatPrice(saleEarning?.data?.data || 0)}</span>
                </div>
            )}
        </div>
    );
};
