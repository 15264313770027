import env from '@beam-australia/react-env';

export const getEnvConfig = {
    API_URL: env('API_URL'),
    PORTAL_URL: env('PORTAL_API_URL'),
    KEYCLOAK_URL: env('KEYCLOAK_URL'),
    KEYCLOAK_REALM: env('KEYCLOAK_REALM'),
    KEYCLOAK_CLIEND_ID: env('KEYCLOAK_CLIEND_ID'),
    CDN: env('CDN'),
    DOMAIN: env('DOMAIN'),
    SMARTLOOK: env('SMARTLOOK'),
    NODE_ENV: env('NODE_ENV'),
    STRIPE_PUBLIC_KEY: env('STRIPE_PUBLIC_KEY'),
    MQTT_URL: env('MQTT_URL'),
    ROOT_URL: env('ROOT_URL'),
    NOTIFICATION_URL: env('NOTIFICATION_API_URL'),
    SENTRY_URL: env('SENTRY_URL'),
    SENTRY_ENABLE: env('SENTRY_ENABLE'),
    COMMON_API_URL: env('COMMON_API_URL'),
    CAPTCHA_SITE_KEY: env('CAPTCHA_SITE_KEY'),
    PUBLIC_URL: process.env.PUBLIC_URL,
    GRAFANA_REPORT_URL: env('GRAFANA_REPORT_URL'),
    FINIX_ENVIRONMENT: env('FINIX_ENVIRONMENT'),
    FINIX_APPLICATION_ID: env('FINIX_APPLICATION_ID'),
    INTERNAL_CRM_API_URL: env('INTERNAL_CRM_API_URL'),
};
