export const EditActionIcon: React.FC<{ color?: string }> = ({ color }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5245_118989)">
                <path
                    d="M9.16675 3.33332H5.66675C4.26662 3.33332 3.56655 3.33332 3.03177 3.6058C2.56137 3.84549 2.17892 4.22794 1.93923 4.69834C1.66675 5.23312 1.66675 5.93319 1.66675 7.33332V14.3333C1.66675 15.7335 1.66675 16.4335 1.93923 16.9683C2.17892 17.4387 2.56137 17.8212 3.03177 18.0608C3.56655 18.3333 4.26662 18.3333 5.66675 18.3333H12.6667C14.0669 18.3333 14.7669 18.3333 15.3017 18.0608C15.7721 17.8212 16.1546 17.4387 16.3943 16.9683C16.6667 16.4335 16.6667 15.7335 16.6667 14.3333V10.8333M6.66673 13.3333H8.06218C8.46983 13.3333 8.67366 13.3333 8.86547 13.2873C9.03553 13.2464 9.1981 13.1791 9.34722 13.0877C9.51542 12.9847 9.65954 12.8405 9.9478 12.5523L17.9167 4.58332C18.6071 3.89296 18.6071 2.77368 17.9167 2.08332C17.2264 1.39296 16.1071 1.39296 15.4167 2.08332L7.44778 10.0523C7.15952 10.3405 7.0154 10.4847 6.91233 10.6528C6.82094 10.802 6.7536 10.9645 6.71278 11.1346C6.66673 11.3264 6.66673 11.5302 6.66673 11.9379V13.3333Z"
                    stroke={color ?? '#4E5A65'}
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5245_118989">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
