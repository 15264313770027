import './styles.scss';

import { getEnvConfig } from 'getEnvConfig';

import { SaRevenueDefaultIndex } from './default';
import { GrafanaIframe } from './grafana';

export const SaRevenueIndex: React.FC = () => {
    const url = getEnvConfig.GRAFANA_REPORT_URL;
    console.log(url);

    return url ? <GrafanaIframe /> : <SaRevenueDefaultIndex />;
};
