import dayjs from 'dayjs';
import { ContentTable } from 'pdfmake/interfaces';
import { DATE_FORMAT } from '@constants/index.constant';
import { formatDate, formatPriceBaseOnCurrency } from '@utils/resource';
import { getLocalTime } from '@utils/date';
import { QuoteStatus } from '@enums/quote-status.enum';
import { IInvoice } from '@interfaces/invoice';
import { StripeStatus } from '@enums/stripe-status.enum';

export const renderInvoiceSummary = (
    data: IInvoice.Invoice[],
    fn: (rs: string, obj: any) => string,
) => {
    const columnGap = 6;

    return [
        {
            columns: [
                {
                    width: '100%',
                    stack: [
                        {
                            columnGap: columnGap,
                            columns: [
                                {
                                    width: 'auto',
                                    text: `${fn(`contacts.pdf.invoice.total_invoice`, {
                                        ns: 'contact',
                                    })}: `,
                                    style: 'label',
                                },
                                {
                                    width: '*',
                                    text: `${data.length || 0}`,
                                    style: 'value',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};

export const renderInvoiceTable = (data: any[], fn: (rs: string, obj: any) => string) => {
    const renderStatus = (code: QuoteStatus) => {
        const label = fn(`quotes.status.${code}`, {
            ns: 'quote',
        });
        return label;
    };

    const tableData: ContentTable = {
        style: 'tableExample',
        table: {
            headerRows: 1,
            widths: ['*', 'auto', '*', '*', 'auto'],
            body: [
                [
                    {
                        text: fn('quotes.table.date', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('contact.no.', { ns: 'common' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('quotes.table.amount', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('quotes.table.status', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('quotes.table.description', { ns: 'quote' }),
                        style: 'tableHeader',
                    },
                ],
            ],
        },
    };

    data.forEach((item: any) => {
        tableData?.table.body.push([
            //
            {
                text: formatDate(item.createdAt),
                style: 'normalText',
            },
            //
            {
                text: item.incrementId,
                style: 'normalText',
            },
            //
            {
                text: formatPriceBaseOnCurrency({
                    price: +item.amount || 0,
                }),
                style: 'normalText',
            },
            //
            {
                text: renderStatus(item.status),
                style: 'normalText',
            },
            //
            {
                text: item.description ?? '-',
                style: 'normalText',
            },
        ]);
    });
    return tableData;
};
