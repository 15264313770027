export enum SettingsMenu {
    Profile = 'profile',
    Notifications = 'notifications',
    Integrations = 'integrations',
    Users = 'users',
    Business = 'business',
    Tenants = 'tenants',
    General = 'general',
    Payout = 'payout',
}

export enum SettingsNavigation {
    Profile = '/setting/profile',
    Notifications = '/setting/notifications',
    Integrations = '/setting/integrations',
    Users = '/setting/users',
    Business = '/setting/business',
    Tenants = '/setting/tenants',
    General = '/setting/general',
    Payout = '/wl/payout',
}
export enum BrandSettings {
    COLOR = 'color',
    BRAND_IMAGE = 'brand_image',
}
