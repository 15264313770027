export const DuplicateActionIcon: React.FC = () => {
    return (
        <svg
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5 10H12.5V8H14.5V6H12.5V4H10.5V6H8.5V8H10.5V10ZM8.5 14H14.5V12H8.5V14ZM6 18C5.45 18 4.97917 17.8042 4.5875 17.4125C4.19583 17.0208 4 16.55 4 16V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H13L19 6V16C19 16.55 18.8042 17.0208 18.4125 17.4125C18.0208 17.8042 17.55 18 17 18H6ZM6 16H17V7L12 2H6V16ZM2 22C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V6H2V20H13V22H2Z"
                fill="#7A838C"
            />
        </svg>
    );
};
