import './styles.scss';

import { DATE_FORMAT, PAGE_SIZE } from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { useTable } from '@refinedev/antd';
import { convertPrice } from '@utils/resource';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    formatAppSubscriptionPeriodEnd,
    formatDate,
    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
} from 'utils/date';

export const ApplicationSubscriptionInvoiceList: React.FC<{ appId?: string }> = ({ appId }) => {
    const { tableProps } = useTable<IMarketplace.ISubscriptionInvoice>({
        resource: 'v1/application-subscriptions/invoices',
        sorters: {
            initial: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
        filters: {
            initial: appId
                ? [
                      {
                          field: 'appId',
                          operator: 'eq',
                          value: appId,
                      },
                  ]
                : [],
        },
        pagination: {
            pageSize: PAGE_SIZE,
        },
    });

    const { t } = useTranslation(['marketplace']);

    return (
        <>
            <section className="item-list-container invoice-page-wrapper">
                <div className="list-header">
                    <h3>{t('subscriptions.invoice_history', { ns: 'common' })}</h3>
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table {...tableProps} rowKey="id">
                            <Table.Column
                                title={<>{t('invoices.table.number')}</>}
                                dataIndex="incrementId"
                                key="incrementId"
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                title={<>{t('invoices.table.date')}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        {formatDate(text, DATE_FORMAT)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('invoices.table.status')}</>}
                                dataIndex="status"
                                key="status"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        {t('invoices.statuses.' + text)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('invoices.table.amount')}</>}
                                dataIndex="amount"
                                key="amount"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{convertPrice(text)}</p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('invoices.table.period')}</>}
                                dataIndex="period"
                                key="period"
                                render={(period, _) => <p className="table-tbody-text">{period}</p>}
                            />
                            <Table.Column
                                title={<>{t('invoices.table.frequency')}</>}
                                dataIndex="frequency"
                                key="frequency"
                                render={(frequency, _) => (
                                    <p className="table-tbody-text">{frequency}</p>
                                )}
                            />

                            <Table.Column
                                title={<>{t('invoices.table.start')}</>}
                                dataIndex="periodStart"
                                key="periodStart"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        {formatDate(text, DATE_FORMAT)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('invoices.table.end')}</>}
                                dataIndex="periodEnd"
                                key="periodEnd"
                                render={(periodEnd, record: IMarketplace.ISubscriptionInvoice) => (
                                    <p className="table-tbody-text">
                                        {formatAppSubscriptionPeriodEnd(
                                            RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                            periodEnd as Date,
                                            record,
                                        )}
                                    </p>
                                )}
                            />
                        </Table>
                    </div>
                </div>
            </section>
        </>
    );
};
