import './styles.scss';

import { ISubscription } from '@interfaces/subscription';
import { formatAppSubscriptionPeriodEnd, RECURRING_SUBSCRIPTION_SUBTRACT_DAY } from '@utils/date';
import { convertPrice } from '@utils/resource';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const RecurringInvoice: React.FC<{
    latestExpirationSubscription: ISubscription.SubscriptionList;
}> = ({ latestExpirationSubscription }) => {
    const { t } = useTranslation(['common']);
    return (
        // Latest expiration date
        <div className="note-list-wrapper last-expiration-subscription">
            <span>
                {t('contact.current_invoice')}:
                <Link
                    to={`/wl/invoices/show/${latestExpirationSubscription.invoice?.id}`}
                    className="table-tbody-text"
                >
                    <u> # {latestExpirationSubscription.invoice?.incrementId}</u>
                </Link>
            </span>
            <span>
                {t('contact.product_name')}:{' '}
                {latestExpirationSubscription.invoiceItem?.productName ?? ' - -'}
            </span>
            <span>
                {t('contact.billing_monthly')}{' '}
                {formatAppSubscriptionPeriodEnd(
                    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                    latestExpirationSubscription.currentPeriodEnd as Date,
                    latestExpirationSubscription?.invoice as any,
                )}
            </span>
            <span>
                {t('contact.price')}:{' '}
                {convertPrice(latestExpirationSubscription.invoiceItem?.total)}
            </span>
        </div>
    );
};
