import { Chip } from '@components/modules/chip';
import { CustomPagination } from '@components/modules/pagination';
import { QuoteStatus } from '@enums/quote-status.enum';
import { IInvoice } from '@interfaces/invoice';
import { IOrder } from '@interfaces/order';
import { formatDate } from '@utils/date';
import { convertPrice } from '@utils/resource';
import { Table } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type InvoiceTabProps = {
    current: number;
    cols?: IInvoice.Invoice[];
    tableResult: any;
    setCurrent: (v: number) => void;
    pageSize: number;
    setDataToPrint?: (value: IInvoice.Invoice[]) => void;
    invoiceLoading: boolean;
};
export const ContactInvoiceTabContent: React.FC<InvoiceTabProps> = ({
    cols,
    current,
    tableResult,
    setCurrent,
    pageSize,
    setDataToPrint,
    invoiceLoading,
}) => {
    const { t } = useTranslation(['quote', 'common']);

    const renderStatus = (code: QuoteStatus) => {
        const label = t(`quotes.status.${code}`, {
            ns: 'quote',
        });
        let type = '';
        switch (code) {
            //Pending
            case QuoteStatus.Draft:
                type = 'warning';
                break;
            //Paid
            case QuoteStatus.Paid:
                type = 'success';
                break;
            //default
            default:
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    useEffect(() => {
        if (tableResult?.data) {
            setDataToPrint?.(tableResult.data);
        }
    }, [tableResult?.data]);

    return (
        <div className="overflow-hidden">
            <div className="list-content table-wrapper">
                <Table
                    rowKey="id"
                    dataSource={cols}
                    pagination={false}
                    scroll={{ x: '870px' }}
                    loading={invoiceLoading}
                >
                    <Table.Column
                        width={120}
                        title={<>{t('quotes.table.date')}</>}
                        dataIndex="createdAt"
                        key="createdAt"
                        render={(text, _, __) => (
                            <p className="table-tbody-text">{formatDate(text)}</p>
                        )}
                    />
                    <Table.Column
                        title={<>{t('contact.no.', { ns: 'common' })}</>}
                        dataIndex="incrementId"
                        key="incrementId"
                        width={100}
                        render={(text, record: IInvoice.Invoice, _) => (
                            <Link
                                to={`/wl/invoices/show/${record.id}`}
                                className="table-tbody-text"
                            >
                                <u> # {text}</u>
                            </Link>
                        )}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.amount')}</>}
                        dataIndex="amount"
                        key="amount"
                        width={120}
                        render={(text, _: IOrder.OrderList, __) => (
                            <p className="table-tbody-text">{convertPrice(text)}</p>
                        )}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.status')}</>}
                        dataIndex="status"
                        key="status"
                        width={120}
                        render={(text, _, __) => (
                            <p className={`table-tbody-text status-${text}`}>
                                {text ? renderStatus(text) : null}
                            </p>
                        )}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.description')}</>}
                        dataIndex="description"
                        key="description"
                        width={120}
                        render={(text, _, __) => (
                            <p className={`table-tbody-text status-${text}`}>{text || '-'}</p>
                        )}
                    />
                </Table>
            </div>
            <div className="pagination-container pt-3 pb-4 px-6">
                <CustomPagination
                    pageSize={pageSize}
                    total={tableResult?.total}
                    current={current}
                    onChange={(value: any, _: any) => {
                        setCurrent(value);
                    }}
                    showSizeChanger={false}
                    showLessItems
                />
            </div>
        </div>
    );
};
