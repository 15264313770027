import './styles.scss';

import { Col, Image, Row, Spin } from 'antd';
import { BaseKey, useOne } from '@refinedev/core';
import { useTranslation } from 'react-i18next';

import { ITenant } from '../../../interfaces/tenant';

const TENANT_RESOURCE_URL = 'v1/tenant';

export const IntroduceIndex: React.FC = () => {
    const { t } = useTranslation('common');
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') || {};
    const currTenantId = auth?.tenantId;

    const { data: tenant, isLoading } = useOne<ITenant.ITenantDetail>({
        resource: TENANT_RESOURCE_URL,
        id: currTenantId as BaseKey,
    });

    return (
        <>
            <section className="introduce-container">
                {tenant && (
                    <Spin spinning={isLoading}>
                        <Row>
                            <Col xs={12} className="h-auto welcome-col">
                                <div className="h-full flex items-center">
                                    <div>
                                        <h1 className="welcome-text">
                                            {`${t('dashboard.welcome_to')} ${tenant?.data?.name}`}
                                        </h1>
                                        <span className="description-text">
                                            {t('dashboard.description')}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} className="h-auto image-col flex justify-end">
                                <img
                                    className="image-dashboard"
                                    src={'/images/dashboard-image.svg'}
                                />

                                <Image
                                    className="banner-dashboard"
                                    src={'/images/dashboard-banner.png'}
                                    preview={false}
                                />
                            </Col>
                        </Row>
                    </Spin>
                )}
            </section>
        </>
    );
};
