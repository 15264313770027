import { Editor } from '@tinymce/tinymce-react';
import React, { useId, useRef } from 'react';

export const TextEditor: React.FC<{
    onChange?: (text: string, editor: any) => void;
    value?: string;
    height?: string;
    menubar?: boolean;
}> = ({ onChange, value, height, menubar }) => {
    const editorRef = useRef(null);
    const id = useId();
    return (
        <>
            <Editor
                id={id}
                tinymceScriptSrc={'/js/tinymce/tinymce.min.js'}
                onInit={(evt, editor: any) => (editorRef.current = editor)}
                value={value}
                onEditorChange={onChange}
                init={{
                    height: height || 500,
                    menubar: menubar,
                    language: 'en',
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'wordcount',
                        'help',
                    ],
                    toolbar:
                        'bold italic forecolor | fontsizeselect |' +
                        'alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | image | undo redo | blocks',
                    content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    paste_data_images: true,
                    fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
                    file_picker_types: 'image',
                    images_file_types: 'jpeg,jpg,png',
                    automatic_uploads: true,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // images_upload_handler: async function (blobInfo, success, failure) {
                    //     if (blobInfo.blob().size / 1000 / 1000 > 10) {
                    //         failure("Chỉ hỗ trợ tối đa hình ảnh có kích thước dưới 10MB");
                    //     } else {
                    //         const fileApi = new FileApi();
                    //         const file = await fileApi.uploadFile(blobInfo.blob());
                    //         return success(`${getPublicMediaUrl(file.data as string)}`);
                    //     }
                    // },
                }}
            />
        </>
    );
};
