import './styles.scss';

import { MQTT_TOPIC } from '@constants/mqtt-topic';
import { PERMISSIONS } from '@constants/permission';
import { IssueStatus } from '@enums/helpdesk.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IHelpdesk } from '@interfaces/helpdesk';
import { useSubscription } from '@nuvioo/core-ui';
import {
    BaseKey,
    useNavigation,
    useOne,
    usePermissions,
    useTranslate,
    useUpdate,
} from '@refinedev/core';
import { getFullNameUser, getPublicMediaUrl } from '@utils/resource';
import { Avatar, Button, Col, Image, Row, Spin, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface IHelpdeskActionProps {
    ticket?: IHelpdesk.Ticket;
}

export const HeaderTicketMessageComponent: React.FC<IHelpdeskActionProps> = ({ ticket }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') || {};
    const currTenantId = auth?.tenantId;
    const { list } = useNavigation();
    const translate = useTranslate();
    const { id } = useParams();
    const { mutate: closeTicket, isLoading } = useUpdate();
    const { message: mqttCloseTicket } = useSubscription.default(MQTT_TOPIC.CLOSE_TICKET);
    const { message: mqttTicket } = useSubscription.default(`${MQTT_TOPIC.TICKET}${currTenantId}`);
    const [disabledCloseTicketBtn, setDisabledCloseTicketBtn] = useState<boolean>(false);
    const [hideCloseTicketBtn, setHideCloseTicketBtn] = useState<boolean>(false);
    const { data: userRights } = usePermissions<string[]>();

    useMemo(() => {
        if (userRights?.length) {
            const canCompleteTicket = userRights.includes(PERMISSIONS.WL_HELPDESK_EDIT);
            if (!canCompleteTicket) {
                setHideCloseTicketBtn(true);
            }
        }
    }, [userRights]);

    const { data, refetch } = useOne<IHelpdesk.Ticket>({
        resource: 'v1/tickets',
        queryOptions: {
            enabled: false,
        },
        id: id as BaseKey,
    });

    useEffect(() => {
        if (ticket) {
            setDisabledCloseTicketBtn(ticket?.status === IssueStatus.CLOSED);
        } else handleDisableCloseTicketBtn();
    }, [ticket, id]);

    const handleDisableCloseTicketBtn = async () => {
        const { data } = await refetch();
        setDisabledCloseTicketBtn(data?.data?.status === IssueStatus.CLOSED);
    };

    useEffect(() => {
        if (mqttCloseTicket) {
            if (mqttCloseTicket.message) {
                const newTicket = JSON.parse(mqttCloseTicket.message.toString());
                setDisabledCloseTicketBtn(newTicket.status === IssueStatus.CLOSED);
            }
        }
    }, [mqttCloseTicket]);

    useEffect(() => {
        if (mqttTicket) {
            if (mqttTicket.message) {
                const newTicket = JSON.parse(mqttTicket.message.toString());
                setDisabledCloseTicketBtn(newTicket.status === IssueStatus.CLOSED);
            }
        }
    }, [mqttTicket]);

    const handleCloseTicket = () => {
        closeTicket({
            resource: 'v1/tickets/close',
            id: id || '',
            values: '',
            successNotification: () => {
                return {
                    message: translate('helpdesk.close_ticket_successfully'),
                    type: 'success',
                };
            },
            errorNotification: () => {
                return {
                    message: 'helpdesk.close_ticket_fail',
                    type: 'error',
                };
            },
        });
    };

    return (
        <div className="helpdesk-action-container w-ful">
            <Spin spinning={isLoading}>
                <Row className="helpdesk-action-row w-full h-full">
                    <Col xs={22} className="helpdesk-action-col-flex items-center">
                        {ticket ? (
                            <Avatar
                                className="helpdesk-action-avatar ml-3"
                                size="large"
                                icon={
                                    ticket?.user?.avatarUrl ? (
                                        <Image
                                            className="customer-cell-avatar"
                                            src={getPublicMediaUrl(ticket?.user?.avatarUrl)}
                                            preview={false}
                                        />
                                    ) : (
                                        <Avatar
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            size="large"
                                        >
                                            {ticket?.user && ticket?.user?.firstName.charAt(0)}
                                        </Avatar>
                                    )
                                }
                            />
                        ) : (
                            <Avatar
                                className="helpdesk-action-avatar ml-3"
                                size="large"
                                icon={
                                    data?.data?.user?.avatarUrl ? (
                                        <Image
                                            className="customer-cell-avatar"
                                            src={getPublicMediaUrl(data?.data?.user?.avatarUrl)}
                                            preview={false}
                                        />
                                    ) : (
                                        <Avatar
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            size="large"
                                        >
                                            {data?.data?.user?.firstName.charAt(0)}
                                        </Avatar>
                                    )
                                }
                            />
                        )}

                        {data && (
                            <div className="info-container flex flex-col h-full ml-3">
                                {ticket ? (
                                    <div className="flex items-center">
                                        <Tooltip
                                            title={`${ticket?.user?.firstName} ${ticket?.user?.lastName}`}
                                        >
                                            <span className="helpdesk-action-user-name">
                                                {`${ticket?.user?.firstName} ${ticket?.user?.lastName}`}
                                            </span>
                                        </Tooltip>
                                        <div className="partner-name ml-2">
                                            <Tooltip
                                                title={
                                                    ticket?.partner?.userType ===
                                                    UserTypeEnum.AFFILIATE_PARTNER
                                                        ? getFullNameUser(ticket?.partner)
                                                        : ticket?.partner?.name
                                                }
                                            >
                                                {ticket?.partner?.userType ===
                                                UserTypeEnum.AFFILIATE_PARTNER
                                                    ? getFullNameUser(ticket?.partner)
                                                    : ticket?.partner?.name}
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <Tooltip
                                            title={`${data?.data?.user?.firstName} ${data?.data?.user?.lastName}`}
                                        >
                                            <span className="helpdesk-action-user-name">
                                                {`${data?.data?.user?.firstName} ${data?.data?.user?.lastName}`}
                                            </span>
                                        </Tooltip>
                                        <div className="partner-name ml-2">
                                            <Tooltip
                                                title={
                                                    data?.data?.partner?.userType ===
                                                    UserTypeEnum.AFFILIATE_PARTNER
                                                        ? getFullNameUser(data?.data?.partner)
                                                        : data?.data?.partner?.name
                                                }
                                            >
                                                {data?.data?.partner?.userType ===
                                                UserTypeEnum.AFFILIATE_PARTNER
                                                    ? getFullNameUser(data?.data?.partner)
                                                    : data?.data?.partner?.name}
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}

                                <span className="helpdesk-action-user-email">
                                    {ticket ? ticket?.user?.email : data?.data?.user?.email}
                                </span>
                            </div>
                        )}
                    </Col>

                    <Col xs={2} className="helpdesk-action-col-flex items-center justify-end">
                        <Image
                            src={'/images/icons/x-close.svg'}
                            preview={false}
                            className="icon-action cursor-pointer"
                            onClick={() => list('wl_helpdesk')}
                        />
                    </Col>
                </Row>
                <Row className="edit-helpdesk-row edit-helpdesk-row-subject">
                    <Col
                        span={24}
                        className="px-6 py-2 edit-helpdesk-subject-col items-center justify-between"
                    >
                        <span className="edit-helpdesk-subject-text">
                            {ticket ? ticket?.subject : data?.data?.subject}
                        </span>
                        {!hideCloseTicketBtn && !disabledCloseTicketBtn && (
                            <Button
                                className={`mark-complete-button flex items-center`}
                                onClick={handleCloseTicket}
                                disabled={disabledCloseTicketBtn}
                            >
                                <Image
                                    className="mark-complete-icon"
                                    src={'/images/icons/check.svg'}
                                    preview={false}
                                />
                                <span className="ml-2.5">
                                    {translate('helpdesk.mark_it_complete')}
                                </span>
                            </Button>
                        )}
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};
