import './styles.scss';

import { IHelpdesk } from '@interfaces/helpdesk';
import { IResourceComponentsProps, useGetIdentity, useNavigation } from '@refinedev/core';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { MQTT_TOPIC } from '../../../constants/mqtt-topic';
import { IUser } from '../../../interfaces/user';
import { HelpdeskListComponent } from './ticket-list';
import { TicketMessageDetailComponent } from './ticket-message';

export const NuviooHelpdeskDetail: React.FC<IResourceComponentsProps> = () => {
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    // const userType = userIdentity?.userType;
    // const currUserId = auth?.id;
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') || {};
    const currTenantId = auth?.tenantId;
    const [ticket, setTicket] = useState<IHelpdesk.Ticket>();
    const { push } = useNavigation();
    const [topic, setTopic] = useState<string>();

    useEffect(() => {
        setTopic(`${MQTT_TOPIC.TICKET}${currTenantId}`);
    }, [userIdentity?.userType]);

    const handleClickTicket = (ticket: IHelpdesk.Ticket) => {
        setTicket(ticket);
        push(`/tenant/helpdesk/show/${ticket?.id}?status=${ticket?.status}`);
    };

    return (
        <section className="edit-helpdesk-container w-full h-full">
            {topic && (
                <Row className="w-full h-full">
                    <Col xs={7} className="h-full edit-helpdesk-col">
                        <HelpdeskListComponent
                            topic={topic}
                            handleClickTicket={handleClickTicket}
                        />
                    </Col>
                    <Col xs={17} className="h-full edit-helpdesk-col content-col flex-col">
                        <TicketMessageDetailComponent ticket={ticket} isRoot={true} />
                    </Col>
                </Row>
            )}
        </section>
    );
};
