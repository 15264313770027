export const EmbedLinkIcon: React.FC = () => {
    return (
        <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.99992 0.833496C2.69909 0.833496 0.833252 2.69933 0.833252 5.00016C0.833252 7.301 2.69909 9.16683 4.99992 9.16683H8.33325V7.50016H4.99992C3.61909 7.50016 2.49992 6.381 2.49992 5.00016C2.49992 3.61933 3.61909 2.50016 4.99992 2.50016H8.33325V0.833496H4.99992ZM11.6666 0.833496V2.50016H14.9999C16.3808 2.50016 17.4999 3.61933 17.4999 5.00016C17.4999 6.381 16.3808 7.50016 14.9999 7.50016H11.6666V9.16683H14.9999C17.3008 9.16683 19.1666 7.301 19.1666 5.00016C19.1666 2.69933 17.3008 0.833496 14.9999 0.833496H11.6666ZM5.83325 4.16683V5.8335H14.1666V4.16683H5.83325Z"
                fill="#6B6CFF"
            />
        </svg>
    );
};
