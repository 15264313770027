import './styles.scss';

import { ITenant } from '@interfaces/tenant';
import { useSelect } from '@refinedev/antd';
import { CrudFilters } from '@refinedev/core';
import { Empty, Select, SelectProps, Spin } from 'antd';
import { TenantTypeEnum } from 'enums/tenant-type.enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectTenant: React.FC<
    SelectProps & {
        isActiveTenant?: boolean;
        selectedTenant?: ITenant.ITenantInfor;
        onSelectedTenant?: (partner: ITenant.ITenantInfor) => void;
        clearTenant?: () => void;
    }
> = (props) => {
    const { t } = useTranslation(['common']);
    const [pageIndex, setPageIndex] = useState(1);
    const [currentData, setCurrentData] = useState<ITenant.ITenantInfor[]>([]);
    const { selectedTenant, onSelectedTenant, clearTenant, isActiveTenant } = props;

    const [filters, setFilters] = useState<CrudFilters>([
        {
            field: 'isActive',
            operator: 'eq',
            value: !!isActiveTenant,
        },
        {
            field: 'type',
            operator: 'eq',
            value: TenantTypeEnum.TENANT,
        },
    ]);
    const { selectProps, queryResult } = useSelect<ITenant.ITenantInfor>({
        resource: 'v1/tenant',
        debounce: 500,
        onSearch: (value: string) => {
            setPageIndex(1);
            setCurrentData([]);
            setFilters([
                {
                    field: 'filter',
                    operator: 'eq',
                    value,
                },
                {
                    field: 'isActive',
                    operator: 'eq',
                    value: !!isActiveTenant,
                },
                {
                    field: 'type',
                    operator: 'eq',
                    value: TenantTypeEnum.TENANT,
                },
            ]);
            return [];
        },
        sort: [
            {
                field: 'name',
                order: 'asc',
            },
        ],

        pagination: {
            mode: 'server',
            current: pageIndex,
        },
        filters: filters,
        queryOptions: {
            onSuccess: (data) => {
                setCurrentData(currentData.concat(data?.data));
            },
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_tenants'),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_tenants_failed'),
                };
            }
        },
    });

    const onChangeTenant = (value: string) => {
        const tenant = currentData.find((item) => item.id === value);
        if (!!tenant && !!onSelectedTenant) {
            onSelectedTenant(tenant);
        }
    };

    return (
        <>
            <Select
                {...selectProps}
                {...props}
                getPopupContainer={(trigger) => trigger.parentNode}
                notFoundContent={queryResult.isFetching ? <Spin size="small" /> : <Empty />}
                placeholder={t('tenant.select_tenant')}
                allowClear
                className="select-tenant"
                defaultValue={
                    selectedTenant
                        ? {
                              value: selectedTenant?.id || '',
                              label: selectedTenant?.name || '',
                          }
                        : null
                }
                onPopupScroll={async (e: any) => {
                    const { target } = e;
                    if (
                        (target as any).scrollTop + (target as any).offsetHeight ===
                        (target as any).scrollHeight
                    ) {
                        if (
                            queryResult.data &&
                            currentData.length < queryResult.data?.total &&
                            !queryResult.isRefetching &&
                            !queryResult.isFetching
                        ) {
                            setPageIndex(pageIndex + 1);
                            await queryResult.refetch();
                        }
                    }
                }}
                options={currentData.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                loading={queryResult.isRefetching || queryResult.isFetching}
                onClear={() => {
                    setPageIndex(1);
                    setCurrentData([]);
                    setFilters([
                        {
                            field: 'isActive',
                            operator: 'eq',
                            value: !!isActiveTenant,
                        },
                        {
                            field: 'type',
                            operator: 'eq',
                            value: TenantTypeEnum.TENANT,
                        },
                    ]);
                    clearTenant && clearTenant();
                    if (!filters.length) {
                        queryResult.refetch();
                    }
                }}
                onChange={(value) => onChangeTenant(value)}
            />
        </>
    );
};
