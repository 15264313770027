import './styles.scss';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import {
    DeleteButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
    useDrawerForm,
} from '@refinedev/antd';
import {
    BaseKey,
    useApiUrl,
    useCustomMutation,
    useGetIdentity,
    useNavigation,
    useOne,
    usePermissions,
    useTable,
} from '@refinedev/core';
import {
    Avatar,
    Button,
    Dropdown,
    Menu,
    notification,
    Space,
    Table,
    TablePaginationConfig,
    Tooltip,
} from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactDetail } from '../../components/contact';
import { Chip } from '../../components/modules/chip';
import { CustomSearch } from '../../components/modules/custom-search';
import { CustomPagination } from '../../components/modules/pagination';
import { SelectPartner } from '../../components/modules/select-partner';
import { PAGE_SIZE } from '../../constants/index.constant';
import { UserStatusEnum } from '../../enums/user-status.enum';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { IContact } from '../../interfaces/contact';
import { IPartner } from '../../interfaces/partner';
import { IUser } from '../../interfaces/user';
import { getPublicMediaUrl } from '../../utils/resource';
import { maskPhone } from '../../utils/string';

export const ContactIndex: React.FC = () => {
    const [partnerId, setPartnerId] = useState<string>();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const isSa = userIdentity?.userType === UserTypeEnum.TENANT;
    const {
        setFilters,
        filters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<IContact.IContact>({
        resource: 'v1/contacts',
        sorters: {
            initial: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        syncWithLocation: true,
    });

    const { data: selectedPartner, refetch: partnerRefetch } = useOne<IPartner.IPartnerInfo>({
        resource: 'v1/partner/byId',
        id: partnerId as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        if (filters) {
            filters.map((item) => {
                if ('field' in item) {
                    const { field, value } = item;
                    if (field === 'partnerId') {
                        setPartnerId(value);
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        if (partnerId) {
            partnerRefetch();
        }
    }, [partnerId]);

    const { show: showDetail } = useNavigation();
    const { data: permissions } = usePermissions<string[]>();

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IContact.IContact>({
        action: 'create',
        resource: 'v1/contacts',
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
    });
    const { mutate } = useCustomMutation();
    const { t } = useTranslation(['contact', 'common']);
    const apiUrl = useApiUrl();

    const sendEmail = (id: string) => {
        mutate(
            {
                url: `${apiUrl}/v1/contacts/${id}/invite`,
                method: 'post',
                values: {},
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, _, __) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('contacts.messages.send_invitation_success'),
                        });
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('contacts.errors.send_invitation_failed'),
                        });
                    }
                },
            },
        );
    };

    const showContact = (id: string) => {
        if (!id || !permissions?.includes(PERMISSIONS.WL_CONTACT_SHOW)) return;
        showDetail('wl_contact', id);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const handleChangeFilter = (partnerId: string) => {
        setFilters([
            {
                field: 'partnerId',
                operator: 'eq',
                value: partnerId,
            },
        ]);
        setCurrent(1);
    };

    const onClearPartner = () => {
        setFilters([
            {
                field: 'partnerId',
                operator: 'eq',
                value: undefined,
            },
        ]);
        setCurrent(1);
    };

    const renderUserStatus = (status: UserStatusEnum | null) => {
        const label = status
            ? t('contacts.userStatus.' + status.toString())
            : t('contacts.noAccount');
        let type = 'warning';

        switch (status) {
            case UserStatusEnum.PENDING:
                type = 'warning';
                break;
            case UserStatusEnum.ACTIVE:
            case UserStatusEnum.PAYMENT_CONNECTED:
                type = 'success';
                break;
            case UserStatusEnum.DEACTIVATED:
                type = 'disable';
                break;
            default:
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    return (
        <>
            <ContactDetail
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
            />
            <section className="item-list-container">
                <div className="list-header">
                    <Space>
                        <CustomSearch
                            placeholder={t('contacts.fields.search.placeholder_contact')}
                            className={'search-item'}
                            defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                            onChange={(event: any) => {
                                setFilters([
                                    {
                                        field: 'filter',
                                        operator: 'eq',
                                        value: event.target.value,
                                    },
                                ]);
                                setCurrent(1);
                            }}
                            allowClear={true}
                        />
                        {isSa ? (
                            selectedPartner?.data ? (
                                <SelectPartner
                                    key="1"
                                    onChange={(option, _) => {
                                        handleChangeFilter(option);
                                    }}
                                    allowClear
                                    placeholder={t('contacts.fields.search.placeholder_partner')}
                                    selectedPartner={selectedPartner?.data}
                                    getSelectedPartner={(partner) => {
                                        if (partner) {
                                            handleChangeFilter(partner.id ?? '');
                                        }
                                    }}
                                    clearPartner={onClearPartner}
                                />
                            ) : (
                                <SelectPartner
                                    key="2"
                                    onChange={(option, _) => {
                                        handleChangeFilter(option);
                                    }}
                                    allowClear
                                    placeholder={t('contacts.fields.search.placeholder_partner')}
                                    getSelectedPartner={(partner) => {
                                        if (partner) {
                                            handleChangeFilter(partner.id ?? '');
                                        }
                                    }}
                                />
                            )
                        ) : null}
                    </Space>
                    {(permissions || []).includes(PERMISSIONS.WL_CONTACT_CREATE) ? (
                        <Button className="btn-add-new" type="primary" onClick={() => createShow()}>
                            <PlusOutlined />
                            {t('add_new', { ns: 'common' })}
                        </Button>
                    ) : null}
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            pagination={false}
                            rowKey="id"
                            tableLayout="fixed"
                            scroll={{ x: '900px', y: '100%' }}
                        >
                            <Table.Column
                                title={<>{t('contacts.customer')}</>}
                                dataIndex="fullName"
                                key="name"
                                sorter
                                defaultSortOrder={getDefaultSortOrder('name', sorters)}
                                width={300}
                                render={(row, record: IContact.IContact) => {
                                    const hasPermission = (permissions || []).includes(
                                        PERMISSIONS.WL_CONTACT_SHOW,
                                    );
                                    return (
                                        <div className="flex justify-between items-center ">
                                            <div className="flex justify-start items-center">
                                                <Avatar
                                                    size={40}
                                                    src={
                                                        record.avatarUrl
                                                            ? getPublicMediaUrl(record.avatarUrl)
                                                            : null
                                                    }
                                                    icon={<AntDesignOutlined />}
                                                />
                                                <p className="table-tbody-text ml-2 item-name pd-0">
                                                    <Tooltip
                                                        title={
                                                            hasPermission ? (
                                                                <ShowButton
                                                                    className="p-0 table-clickable-link"
                                                                    type="link"
                                                                    icon={false}
                                                                    recordItemId={record.id}
                                                                >
                                                                    {row}
                                                                </ShowButton>
                                                            ) : (
                                                                row
                                                            )
                                                        }
                                                    >
                                                        {row}
                                                    </Tooltip>
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('email', sorters)}
                                title={<>{t('contacts.email')}</>}
                                dataIndex="email"
                                width={250}
                                key="email"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        <Tooltip title={text}>{text}</Tooltip>
                                    </p>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('phoneNumber', sorters)}
                                title={<>{t('contacts.phoneNumber')}</>}
                                dataIndex="phoneNumber"
                                width={200}
                                key="phoneNumber"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        <Tooltip title={maskPhone(text)}>{maskPhone(text)}</Tooltip>
                                    </p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('contacts.company')}</>}
                                dataIndex="company"
                                key="company"
                                width={200}
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                title={<>{t('contacts.contactOwner')}</>}
                                dataIndex="contactOwner"
                                key="contactOwner"
                                width={200}
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                title={<>{t('contacts.associatedPartner')}</>}
                                dataIndex="associatedPartner"
                                key="associatedPartner"
                                width={200}
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                title={<>{t('contacts.accountStatus')}</>}
                                dataIndex="userStatus"
                                key="userStatus"
                                width={200}
                                render={(userStatus: UserStatusEnum, _) =>
                                    renderUserStatus(userStatus)
                                }
                            />
                            <Table.Column
                                title={''}
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(id, record: IContact.IContact) => (
                                    <div
                                        className="flex justify-between items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {(permissions || []).some((p) =>
                                            [
                                                PERMISSIONS.WL_CONTACT_CREATE,
                                                PERMISSIONS.WL_CONTACT_SHOW,
                                                PERMISSIONS.WL_CONTACT_DELETE,
                                            ].includes(p),
                                        ) ? (
                                            <Dropdown
                                                className="w-8 ml-auto "
                                                overlay={
                                                    <Menu mode="vertical">
                                                        {(!record.userStatus ||
                                                            record.userStatus ===
                                                                UserStatusEnum.PENDING) &&
                                                        (permissions || []).includes(
                                                            PERMISSIONS.WL_CONTACT_CREATE,
                                                        ) ? (
                                                            <Menu.Item
                                                                key="0"
                                                                onClick={() => sendEmail(id)}
                                                                icon={
                                                                    <img
                                                                        src="/images/icons/send.svg"
                                                                        alt="more"
                                                                        className="cursor-pointer"
                                                                    />
                                                                }
                                                            >
                                                                Invite
                                                            </Menu.Item>
                                                        ) : null}

                                                        {(permissions || []).includes(
                                                            PERMISSIONS.WL_CONTACT_SHOW,
                                                        ) ? (
                                                            <Menu.Item
                                                                key="1"
                                                                icon={
                                                                    <ShowButton
                                                                        onClick={() =>
                                                                            showContact(id)
                                                                        }
                                                                        style={{
                                                                            padding:
                                                                                '5px 16px 5px 12px',
                                                                            margin: '0px',
                                                                        }}
                                                                    />
                                                                }
                                                                style={{ padding: 0 }}
                                                            ></Menu.Item>
                                                        ) : null}
                                                        {(permissions || []).includes(
                                                            PERMISSIONS.WL_CONTACT_DELETE,
                                                        ) ? (
                                                            <Menu.Item
                                                                key="2"
                                                                style={{ padding: 0 }}
                                                                icon={
                                                                    <DeleteButton
                                                                        successNotification={{
                                                                            message:
                                                                                'Successfully deleted',
                                                                            type: 'success',
                                                                        }}
                                                                        resource="v1/contacts"
                                                                        recordItemId={id}
                                                                        style={{
                                                                            padding: '5px 12px',
                                                                            margin: '0px',
                                                                        }}
                                                                    />
                                                                }
                                                            ></Menu.Item>
                                                        ) : null}
                                                    </Menu>
                                                }
                                                placement="bottomRight"
                                                arrow
                                                overlayClassName="contact-dropdown-container"
                                                trigger={['click']}
                                            >
                                                <div>
                                                    <img
                                                        src="/images/icons/dots-vertical.svg"
                                                        alt="more"
                                                        className="cursor-pointer"
                                                    />
                                                </div>
                                            </Dropdown>
                                        ) : null}
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
