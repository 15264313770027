import './styles.scss';

import { TENANT_BUSINESS_SETTING_RESOURCE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { useCreate, usePermissions, useTranslate } from '@refinedev/core';
import { Button, Col, Form, notification, Row, Spin } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { ImageUpload } from 'components/modules/image-upload';
import Response from 'data-access/responses/response';
import { useEffect, useState } from 'react';

import { Setting } from '../../../interfaces/setting';
import { Hint } from '../../modules/hint';

enum CompanyBrandingFields {
    LOGO = 'logo',
    FAVICON = 'favicon',
}

interface IImageFile extends RcFile {
    url: string;
}

export enum BrandSettings {
    COLOR = 'color',
    BRAND_IMAGE = 'brand_image',
}

interface CompanyBrandingProps {
    url: string;
    data?: Response<Setting.IBrandSetting[]>;
}

export const CompanyBrandingSetting: React.FC<CompanyBrandingProps> = ({ url, data }) => {
    const translate = useTranslate();
    const [loading, setLoading] = useState<boolean>(true);
    const [form] = Form.useForm();
    const [imageSetting, setImageSetting] = useState<Record<string, string> | null>(null);
    const [isUpdateImage, setIsUpdateImage] = useState<boolean>(true);
    const { mutate } = useCreate();
    const { data: permissions } = usePermissions<string[]>();

    useEffect(() => {
        if (data?.isSuccess) {
            const settingDatas = (data.data || []).filter(
                (item) => item.type === BrandSettings.BRAND_IMAGE,
            );
            if (settingDatas.length) {
                const settingsData = settingDatas[0].data;
                setImageSetting(settingsData);
                const initFormValue: { [name: string]: string } = {};
                for (const label in settingsData) {
                    initFormValue[label] = settingsData[label] as string;
                }
                form.setFieldsValue(initFormValue);
            }
        }
        setLoading(false);
    }, [data]);

    const onFinish = (formValue: any) => {
        const values = {
            type: BrandSettings.BRAND_IMAGE,
            data: {
                [CompanyBrandingFields.LOGO]: formValue[CompanyBrandingFields.LOGO],
                [CompanyBrandingFields.FAVICON]: formValue[CompanyBrandingFields.FAVICON],
            },
        };

        mutate(
            {
                resource: TENANT_BUSINESS_SETTING_RESOURCE,
                values: values,
                errorNotification: false,
                successNotification: false,
            },
            {
                onSuccess: (data) => {
                    const response = data.data;
                    if (response.isSuccess) {
                        window?.location?.reload();
                    } else {
                        notification.error({ message: translate('update_err') });
                    }
                    setLoading(false);
                },
                onError: () => {
                    setLoading(false);
                },
            },
        );
    };

    const changeImage = (e: any, field: CompanyBrandingFields) => {
        if (!isUpdateImage) {
            form.setFieldsValue({
                [field]:
                    field === CompanyBrandingFields.LOGO
                        ? imageSetting?.logo
                        : imageSetting?.favicon,
            });
        } else {
            // response is the url of file has been upload if successfully
            if (e?.response && e?.status === 'done') {
                form.setFieldsValue({ [field]: e?.response });
            }
        }
    };

    return (
        <Row className="w-full tenant-setting-containers" id="tenantSettingContainers">
            <Col span={24}>
                <Row className="tenant-setting-header-row px-6">
                    <Col className="tenant-setting-header-col w-full h-full items-center" span={24}>
                        <h2 className="tenant-setting-header-title">
                            {translate('settings.company_branding')}
                        </h2>
                    </Col>
                </Row>

                <Spin spinning={loading}>
                    <Form form={form} onFinish={onFinish} labelAlign={'right'} layout={'vertical'}>
                        <Row className="tenant-setting-form-row px-6 pt-6 pb-8">
                            <Col span={24}>
                                <Row className="tenant-setting-form-item-row gap-7" gutter={33}>
                                    {Object.values(CompanyBrandingFields).map((item) => (
                                        <Col xs={24} lg={12} className={`flex `} key={item}>
                                            <div className="flex gap-7 items-center brand-item">
                                                <Form.Item
                                                    name={item}
                                                    className="brand-item-image"
                                                    label={
                                                        <h1 className="tenant-setting-form-item-label">
                                                            {translate(
                                                                `settings.brand_image.${item}`,
                                                            )}
                                                        </h1>
                                                    }
                                                >
                                                    <div className="logo">
                                                        {item === CompanyBrandingFields.LOGO ? (
                                                            <>
                                                                <ImageUpload
                                                                    width={215}
                                                                    height={40}
                                                                    uploadText={''}
                                                                    shape={'rect'}
                                                                    isTemporary={false}
                                                                    hideIconAfterUpload={true}
                                                                    onChange={(e) =>
                                                                        changeImage(
                                                                            e,
                                                                            CompanyBrandingFields.LOGO,
                                                                        )
                                                                    }
                                                                    value={
                                                                        imageSetting
                                                                            ? imageSetting[
                                                                                  CompanyBrandingFields
                                                                                      .LOGO
                                                                              ]
                                                                            : undefined
                                                                    }
                                                                    setIsUpdateImage={
                                                                        setIsUpdateImage
                                                                    }
                                                                />
                                                                <div className="mt-2">
                                                                    <Hint
                                                                        width={215}
                                                                        height={40}
                                                                        fileType={['JPG', 'PNG']}
                                                                        size={5}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    <div className="favicon">
                                                        {item === CompanyBrandingFields.FAVICON ? (
                                                            <>
                                                                <ImageUpload
                                                                    width={40}
                                                                    height={40}
                                                                    uploadText={''}
                                                                    shape={'rect'}
                                                                    isTemporary={false}
                                                                    hideIconAfterUpload={true}
                                                                    onChange={(e) =>
                                                                        changeImage(
                                                                            e,
                                                                            CompanyBrandingFields.FAVICON,
                                                                        )
                                                                    }
                                                                    value={
                                                                        imageSetting
                                                                            ? imageSetting[
                                                                                  CompanyBrandingFields
                                                                                      .FAVICON
                                                                              ]
                                                                            : undefined
                                                                    }
                                                                    setIsUpdateImage={
                                                                        setIsUpdateImage
                                                                    }
                                                                />
                                                                <div className="mt-2">
                                                                    <Hint
                                                                        width={40}
                                                                        height={40}
                                                                        fileType={['JPG', 'PNG']}
                                                                        size={5}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            {(permissions || []).includes(PERMISSIONS.SETTING_EDIT) ? (
                                <Col span={24} className="">
                                    <Row className="tenant-setting-button-group mt-16">
                                        <Button type="primary" htmlType="submit">
                                            {translate('buttons.save')}
                                        </Button>
                                    </Row>
                                </Col>
                            ) : null}
                        </Row>
                    </Form>
                </Spin>
            </Col>
        </Row>
    );
};
