export enum SubscriptionStatus {
    Cancel = 0,
    Active,
    PaymentFailed,
}

export enum SubscriptionStripeStatus {
    SUCCEED = 'succeed',
    CANCELED = 'canceled',
}
