export const TwitterIcon: React.FC = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.71523 16.3843C5.93685 16.3843 5.13568 16.3213 4.32969 16.1949C2.91013 15.9722 1.47312 15.2296 1.06649 15.0073L0 14.4242L1.15466 14.0445C2.41685 13.6295 3.18465 13.372 4.1351 12.9688C3.18341 12.5079 2.44994 11.6789 2.09687 10.6071L1.82825 9.79168L2.04853 9.82533C1.83965 9.6148 1.67472 9.40112 1.54687 9.2068C1.09217 8.51617 0.851437 7.67228 0.902661 6.94911L0.953197 6.23733L1.37933 6.4024C1.19956 6.06292 1.06965 5.70037 0.993297 5.32038C0.807217 4.39369 0.962948 3.40917 1.43192 2.54826L1.80312 1.86683L2.29957 2.46311C3.86964 4.34919 5.8583 5.46801 8.21856 5.79554C8.12216 5.13101 8.19439 4.48968 8.43348 3.91482C8.71185 3.24548 9.20706 2.6779 9.86513 2.27333C10.596 1.82412 11.4823 1.60646 12.3608 1.66043C13.2929 1.71769 14.1391 2.06692 14.8112 2.67103C15.1394 2.58575 15.3812 2.4947 15.7081 2.37165C15.9047 2.29763 16.1277 2.21359 16.4068 2.11746L17.4351 1.76315L16.7645 3.67834C16.8087 3.67463 16.8543 3.67147 16.902 3.66941L18.0001 3.6197L17.351 4.50671C17.3138 4.55752 17.3044 4.57194 17.2912 4.59185C17.2389 4.67068 17.1738 4.76873 16.2829 5.95828C16.0599 6.25614 15.9485 6.6441 15.9693 7.05087C16.0482 8.59664 15.8586 9.9952 15.4052 11.2075C14.9765 12.3545 14.3121 13.3386 13.4307 14.1325C12.3401 15.1147 10.9493 15.7872 9.29701 16.1311C8.48649 16.2997 7.6161 16.3843 6.71523 16.3843Z"
                fill="#C2CFE0"
            />
        </svg>
    );
};
