import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { ServiceCrmDetail, ServiceCrmItem } from '@components/internal-crm/service';
import { PERMISSIONS } from '@constants/permission';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { CreateButton, useDrawerForm, useSimpleList } from '@refinedev/antd';
import {
    HttpError,
    IResourceComponentsProps,
    useGetIdentity,
    usePermissions,
    useTranslate,
} from '@refinedev/core';
import { Col, Form, List, Row } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';

export const SERVICES_RESOURCE = 'v1/services';

export const ServiceCrmIndex: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const { data: rights } = usePermissions<string[]>();

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const { listProps, searchFormProps } = useSimpleList<IService, HttpError, { name: string }>({
        dataProviderName,
        pagination: { pageSize: 12, current: 1 },
        resource: SERVICES_RESOURCE,
    });

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IService>({
        dataProviderName,
        action: 'create',
        resource: 'v1/services',
        successNotification: {
            message: t('crm_internal.service.create_service_success'),
            type: 'success',
        },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IService>({
        dataProviderName,
        action: 'edit',
        resource: 'v1/services',
        successNotification: {
            message: t('crm_internal.service.edit_service_success'),
            type: 'success',
        },
        redirect: false,
        onMutationSuccess: () => {
            editFormProps.form.resetFields();
        },
    });

    return (
        <>
            <Form
                {...searchFormProps}
                onValuesChange={() => {
                    searchFormProps.form?.submit();
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="product-wrapper">
                            <label className="product-header-section">
                                {t('crm_internal.service.title')}
                            </label>
                            {(rights || []).indexOf(PERMISSIONS.WL_SERVICE_CREATE) >= 0 && (
                                <CreateButton
                                    type="primary"
                                    icon={false}
                                    onClick={() => createShow()}
                                >
                                    <PlusOutlined />
                                    {t('services.buttons.addService')}
                                </CreateButton>
                            )}
                        </div>
                        <List
                            grid={{
                                gutter: 20,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 3,
                                xl: 3,
                                xxl: 4,
                            }}
                            {...listProps}
                            renderItem={(item) => (
                                <ServiceCrmItem key={item.id} item={item} editShow={editShow} />
                            )}
                        />
                    </Col>
                </Row>
            </Form>
            <ServiceCrmDetail
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
            />
            <ServiceCrmDetail
                isEditMode={true}
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />
        </>
    );
};
