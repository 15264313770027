import './styles.scss';

import { Button, Col, Form, Input, Radio, Row, Space, Spin } from 'antd';
import { BaseRecord, useCreate, useNavigation, useTranslate } from '@refinedev/core';
import { IssuePriority } from 'enums/helpdesk.enum';
import { useState } from 'react';

enum CreateHelpdeskFormEnum {
    Subject = 'subject',
    Content = 'content',
    Priority = 'priority',
    Target = 'target',
}

interface ICreateHelpdesk {
    subject: string;
    priority: IssuePriority;
    message: string;
    isRoot: boolean;
}

export const NuviooHelpdeskCreate: React.FC = () => {
    const [form] = Form.useForm<ICreateHelpdesk>();
    const translate = useTranslate();
    const { mutate } = useCreate<BaseRecord>();
    const { goBack } = useNavigation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onCreate = async () => {
        const isValid = await form.validateFields();
        if (isValid) {
            setIsLoading(true);
            const formValue = form.getFieldsValue();
            formValue.isRoot = true;
            mutate(
                {
                    resource: 'v1/tickets/tenant',
                    values: formValue,
                    successNotification: { message: 'Successfully created', type: 'success' },
                },
                {
                    onError: (_) => {
                        setIsLoading(false);
                    },
                    onSuccess: (_) => {
                        setIsLoading(false);
                        goBack();
                    },
                },
            );
        }
    };

    return (
        <section className="create-helpdesk-container w-full h-full">
            <Spin spinning={isLoading}>
                <Row className="w-full h-full">
                    <Col xs={24} className="create-helpdesk-col h-full">
                        <Row>
                            <Col span={24}>
                                <section className="helpdesk-action-container w-ful">
                                    <Row className="helpdesk-action-row w-full h-full">
                                        <Col
                                            xs={12}
                                            className="helpdesk-action-col-flex items-center"
                                        >
                                            <span className="create-helpdesk-title">
                                                {translate('helpdesk.create_issue')}
                                            </span>
                                        </Col>
                                        <Col
                                            xs={12}
                                            className="helpdesk-action-col-flex items-center justify-end"
                                        >
                                            <Button onClick={goBack}>
                                                {translate('helpdesk.cancel')}
                                            </Button>
                                            <Button
                                                className="action-button create-btn ml-4"
                                                type="primary"
                                                onClick={onCreate}
                                            >
                                                {translate('helpdesk.create')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </section>
                            </Col>
                        </Row>
                        <Form
                            form={form}
                            id="createHelpdeskForm"
                            name="create-helpdesk"
                            layout="vertical"
                            className="create-helpdesk-form w-full"
                        >
                            <Row className="create-helpdesk-form-row w-full h-full">
                                <Col xs={16} className="create-helpdesk-form-col col-left">
                                    <Form.Item
                                        className="helpdesk-form-item form-item-subject"
                                        label={translate('helpdesk.subject')}
                                        name={CreateHelpdeskFormEnum.Subject}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.subject_required'),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="helpdesk-form-item form-item-message"
                                        label={translate('helpdesk.message')}
                                        name={CreateHelpdeskFormEnum.Content}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.message_required'),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                                <Col xs={8} className="create-helpdesk-form-col col-right">
                                    <Form.Item
                                        className="helpdesk-form-item form-item-priority"
                                        label={translate('helpdesk.priority')}
                                        name={CreateHelpdeskFormEnum.Priority}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.priority_required'),
                                            },
                                        ]}
                                    >
                                        <Radio.Group value={IssuePriority.LOW}>
                                            <Space direction="vertical">
                                                <Radio value={IssuePriority.LOW}>
                                                    {translate('helpdesk.priority_enum.low')}
                                                </Radio>
                                                <Radio value={IssuePriority.NORMAL}>
                                                    {translate('helpdesk.priority_enum.normal')}
                                                </Radio>
                                                <Radio value={IssuePriority.HIGH}>
                                                    {translate('helpdesk.priority_enum.high')}
                                                </Radio>
                                                <Radio value={IssuePriority.CRITICAL}>
                                                    {translate('helpdesk.priority_enum.critical')}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </section>
    );
};
