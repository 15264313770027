import { IResourceComponentsProps } from '@refinedev/core';
import { FilterContextProvider } from 'contexts/filter.context';

import { Payouts } from './payout-report';

export const PayoutReportCrmIndex: React.FC<IResourceComponentsProps> = () => {
    return (
        <FilterContextProvider>
            <Payouts />
        </FilterContextProvider>
    );
};
