import './styles.scss';

import {
    DEFAULT_PRIMARY,
    DEFAULT_SECONDARY,
    TENANT_BUSINESS_SETTING_RESOURCE,
} from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { Button, Col, Form, Row, Spin } from 'antd';
import { useCreate, useCustom, usePermissions, useTranslate } from '@refinedev/core';
import Response from 'data-access/responses/response';
import { BrandSettings } from 'enums/settings.enum';
import { getEnvConfig } from 'getEnvConfig';
import { Setting } from 'interfaces/setting';
import { useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

enum BrandColorFields {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

interface IBrandColorSetting {
    [BrandColorFields.PRIMARY]: string | null;
    [BrandColorFields.SECONDARY]: string | null;
}

const DEFAULT_COLOR = '#000000';

export const BrandColorSetting: React.FC = () => {
    const [colorSetting, setColorSetting] = useState<IBrandColorSetting>({
        primary: null,
        secondary: null,
    });
    const translate = useTranslate();
    const apiUrl = getEnvConfig.PORTAL_URL;
    const [loading, setLoading] = useState<boolean>(true);
    const [form] = Form.useForm();
    const url = `${apiUrl}/${TENANT_BUSINESS_SETTING_RESOURCE}`;
    const { data } = useCustom<Response<Setting.IBrandSetting[]>>({
        url: url,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    const { mutate: createColorSetting } = useCreate();
    const { data: permissions } = usePermissions<string[]>();

    useEffect(() => {
        const response = data?.data;
        if (response?.isSuccess) {
            const settingData = (response.data || []).filter(
                (item) => item.type === BrandSettings.COLOR,
            );

            if (settingData?.length) {
                setColorSetting({
                    primary: settingData[0].data
                        ? settingData[0].data[BrandColorFields.PRIMARY]
                        : DEFAULT_PRIMARY,
                    secondary: settingData[0].data
                        ? settingData[0].data[BrandColorFields.SECONDARY]
                        : DEFAULT_SECONDARY,
                });

                form.setFieldsValue({
                    primary: settingData[0].data
                        ? settingData[0].data[BrandColorFields.PRIMARY]
                        : DEFAULT_PRIMARY,
                    secondary: settingData[0].data
                        ? settingData[0].data[BrandColorFields.SECONDARY]
                        : DEFAULT_SECONDARY,
                });
            }
        }
        setLoading(false);
    }, [data]);

    const onFinish = (formValue: Record<string, string>) => {
        const _colorSetting: Record<string, string> = {
            [BrandColorFields.PRIMARY]: formValue[BrandColorFields.PRIMARY],
            [BrandColorFields.SECONDARY]: formValue[BrandColorFields.SECONDARY],
        };

        createColorSetting(
            {
                resource: TENANT_BUSINESS_SETTING_RESOURCE,
                values: {
                    type: BrandSettings.COLOR,
                    data: _colorSetting,
                } as { type: string; data: Record<string, string> },
                successNotification: () => ({
                    message: translate('settings.update_color_successfully'),
                    type: 'success',
                }),
                errorNotification: () => ({
                    message: translate('settings.update_color_failed'),
                    type: 'error',
                }),
            },
            {
                onSuccess: () => {
                    window?.location?.reload();
                },
            },
        );
    };

    const changeColorComplete = (color: ColorResult, colorField: string) => {
        setColorSetting({ ...colorSetting, [colorField]: color?.hex });
        form.setFieldsValue({
            [colorField]: color?.hex,
        });
    };

    return (
        <Row
            className="w-full setting-color-containers tenant-setting-containers"
            id="tenantSettingContainers"
        >
            <Col span={24}>
                <Row className="tenant-setting-header-row px-6">
                    <Col className="tenant-setting-header-col w-full h-full items-center" span={24}>
                        <h2 className="tenant-setting-header-title">
                            {translate('settings.color_branding')}
                        </h2>
                    </Col>
                </Row>
                <Spin spinning={loading}>
                    <Form form={form} onFinish={onFinish} labelAlign={'right'}>
                        <Row className="px-6 pb-8 mt-6">
                            <Col span={24}>
                                <Row gutter={33}>
                                    {Object.values(BrandColorFields).map((item) => (
                                        <>
                                            <Col
                                                xs={24}
                                                sm={12}
                                                lg={10}
                                                className="tenant-setting-form-item-row  gap-4"
                                            >
                                                {/* <Col xs={24} md={24} className={`flex `} key={item}> */}
                                                <div className="flex flex-col gap-4  branch-item">
                                                    <h3 className="tenant-setting-form-item-label">
                                                        {translate(`settings.brand_color.${item}`)}
                                                    </h3>
                                                    <Form.Item name={item}>
                                                        <SketchPicker
                                                            className="tenant-setting-form-item-color-picker w-full"
                                                            color={
                                                                colorSetting[item] ?? DEFAULT_COLOR
                                                            }
                                                            onChangeComplete={(color) =>
                                                                changeColorComplete(color, item)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </Col>
                            {(permissions || []).includes(PERMISSIONS.SETTING_EDIT) ? (
                                <Col span={24} className="">
                                    <Row className="button-group flex justify-end items-center mt-8">
                                        <Button htmlType="submit" type="primary">
                                            {translate('buttons.save')}
                                        </Button>
                                    </Row>
                                </Col>
                            ) : null}
                        </Row>
                    </Form>
                </Spin>
            </Col>
        </Row>
    );
};
