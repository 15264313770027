export const WlPayoutMenuSideBarIcon: React.FC = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 20C0.448 20 0 19.552 0 19V9C0 8.448 0.448 8 1 8H19C19.552 8 20 8.448 20 9V19C20 19.552 19.552 20 19 20H1ZM2.91211 18H17.0879C17.1625 17.7897 17.2831 17.5987 17.4409 17.4409C17.5987 17.2831 17.7897 17.1625 18 17.0879V10.9121C17.7897 10.8375 17.5987 10.7169 17.4409 10.5591C17.2831 10.4013 17.1625 10.2103 17.0879 10H2.91211C2.83749 10.2103 2.7169 10.4013 2.55911 10.5591C2.40132 10.7169 2.21031 10.8375 2 10.9121V17.0879C2.21031 17.1625 2.40132 17.2831 2.55911 17.4409C2.7169 17.5987 2.83749 17.7897 2.91211 18ZM10 17C9.20435 17 8.44129 16.6839 7.87868 16.1213C7.31607 15.5587 7 14.7956 7 14C7 13.2044 7.31607 12.4413 7.87868 11.8787C8.44129 11.3161 9.20435 11 10 11C10.7956 11 11.5587 11.3161 12.1213 11.8787C12.6839 12.4413 13 13.2044 13 14C13 14.7956 12.6839 15.5587 12.1213 16.1213C11.5587 16.6839 10.7956 17 10 17ZM5 15C4.448 15 4 14.553 4 14C4 13.447 4.448 13 5 13C5.552 13 6 13.447 6 14C6 14.553 5.552 15 5 15ZM15 15C14.448 15 14 14.553 14 14C14 13.447 14.448 13 15 13C15.552 13 16 13.447 16 14C16 14.553 15.552 15 15 15ZM9 6V4H5.95117L9.95117 0L13.9512 4H11V6H9Z"
                fill="#7A838C"
            />
        </svg>
    );
};
