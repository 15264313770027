import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { TextEditor } from '@components/modules/editor';
import { DATE_FORMAT, ROOT_TENANT, SERVICE_OTHER_OPTION_ID } from '@constants/index.constant';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { QuoteStatus } from '@enums/quote-status.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IQuote } from '@interfaces/quote';
import { NSale } from '@interfaces/sale';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { useSelect } from '@refinedev/antd';
import {
    BaseKey,
    BaseRecord,
    useApiUrl,
    useCreate,
    useCustom,
    useGetIdentity,
    useNavigation,
    useOne,
    useUpdate,
} from '@refinedev/core';
import Response from '@responses/response';
import { formatPrice } from '@utils/resource';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Spin,
} from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import { cloneDeep, keyBy } from 'lodash';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { BillInformation } from '../common';

export interface IQuoteForm {
    contactId: string;
    date: Date;
    dueDate: Date;
    description: string;
    items: IQuoteItemForm[];
    paymentPlans: IQuote.IPlan[];
    termsAndConditions: string;
    status: QuoteStatus;
}

export interface IQuoteItemForm {
    frequency: ProductFrequencyEnum;
    id: string;
    margin: number;
    productId: string;
    productName: string;
    qty: number;
    serviceId: string;
    unitPrice: number;
    unitCost?: number;
    earning?: number;
    isNew?: boolean; // Optional property to mark new items
}

export const QuoteCrmCreateIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);

    const { id } = useParams();
    const [form] = Form.useForm();

    const { goBack, push } = useNavigation();
    const { mutate } = useCreate<BaseRecord>();
    const { mutate: updateMutate } = useUpdate<BaseRecord>();

    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);

    const [status, setStatus] = useState<QuoteStatus>(QuoteStatus.Draft);
    const [items, setItems] = useState<any>([]);

    const [earning, setEarning] = useState<number>(0);
    const planItemsAction: {
        [planIdx: string]: {
            removeItem: (index: number | number[]) => void;
        };
    } = {};

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM,
        apiUrl = useApiUrl(dataProviderName);

    const isSysadmin =
        userIdentity?.tenantId === ROOT_TENANT && userIdentity?.userType !== UserTypeEnum.RESELLER;

    const { refetch: refetchQuote } = useOne<IQuote.Quote>({
        dataProviderName,
        resource: 'v1/quotes',
        id: id as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    const { data: saleEarning, refetch: getSaleEarnings } = useCustom<Response<NSale.ISaleEarning>>(
        {
            url: `${apiUrl}/v1/sales/sale-earning`,
            method: 'post',
            queryOptions: {
                enabled: false,
            },
            config: {
                payload: {
                    items,
                },
            },
        },
    );

    useEffect(() => {
        if (id) {
            setQuoteDetail();
        }
    }, [id]);

    useEffect(() => {
        const fetchAndUpdateEarnings = async () => {
            if (items.length) {
                await getSaleEarnings();

                setEarning(saleEarning?.data?.data?.total ?? 0);
            } else {
                setEarning(0);
            }
        };
        fetchAndUpdateEarnings();
    }, [items, saleEarning]);

    useEffect(() => {
        if (saleEarning?.data.data.total) {
            const productsEarning = saleEarning?.data.data.items;
            const items = form.getFieldValue('items');
            items.map((item: any) => {
                const product = productsEarning.find(
                    (product) => product.productId === item.productId,
                );
                item.earning = product?.earning ?? 0;
            });
            form.setFieldsValue({ items });
        }
    }, [saleEarning]);

    const setQuoteDetail = async () => {
        const quoteResult = await refetchQuote();

        if (quoteResult && quoteResult.data?.data && !form.getFieldValue('id')) {
            const quoteData = quoteResult?.data?.data;

            if (
                quoteData?.status === QuoteStatus.Approved ||
                quoteData?.status === QuoteStatus.Rejected ||
                quoteData?.status === QuoteStatus.Paid
            ) {
                push('/404');
            }

            const items = quoteData.items.map((item) => {
                item.unitPrice = item.unitPrice ?? 0;
                return item;
            });

            setEarning(quoteData.earning || 0);

            form.setFieldsValue({
                id: quoteData.id,
                contactId: quoteData.contactId,
                description: quoteData.description,
                termsAndConditions: quoteData.termsAndConditions,
                items: items,
                date: dayjs(quoteData.date),
                dueDate: dayjs(quoteData.dueDate),
                paymentPlans: (quoteData.paymentPlans || []).map((plan) => {
                    plan.paymentDate = plan.paymentDueDate ? dayjs(plan.paymentDate) : undefined;
                    plan.paymentDueDate = plan.paymentDueDate
                        ? dayjs(plan.paymentDueDate)
                        : undefined;

                    (plan.paymentPlanItems || []).forEach((item) => {
                        const existedItem = (items || []).find((i) => i.id === item?.quoteItemId);
                        item.frequency = existedItem?.frequency;
                        item.percentage = +item.percentage || 0;
                    });

                    return plan;
                }),
            });
        }
    };

    const { selectProps: serviceSelectProps, queryResult } = useSelect<IService>({
        dataProviderName,
        resource: 'v1/services/active-services',
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            mode: 'server',
            current: 1,
            pageSize: 20,
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_services', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_services_failed', { ns: 'common' }),
                };
            }
        },
    });

    const services: any = keyBy(queryResult.data?.data, 'id');

    const onChangeService = (index: number) => {
        const items = form.getFieldValue('items');
        items[index].productName = null;
        items[index].productId = undefined;

        items[index].unitPrice = null;
        items[index].qty = items[index].qty || 1;
        items[index].frequency = null;
        items[index].margin = 0;

        setServiceUpdated(!serviceUpdated);
    };

    const onChangeProduct = (productId: string, index: number) => {
        const items = form.getFieldValue('items');
        const products = services[items[index].serviceId].products;
        const selectedProduct = products.find((p: any) => p.id === productId);

        items[index].productName = selectedProduct.name;
        items[index].unitPrice = selectedProduct.price;
        items[index].qty = 1;
        items[index].frequency = selectedProduct.frequency;
        items[index].margin = selectedProduct.margin;
        items[index].unitCost = selectedProduct.unitCost;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
        onChangeItems();
    };

    const getTotalPrice = () => {
        const items = form?.getFieldValue('items');
        if (items) {
            let total = 0;
            for (const item of items) {
                const itemTotal = item?.qty * item?.unitPrice || 0;

                total += itemTotal;
            }

            return total;
        }
        return 0;
    };

    const onChangeStatus = () => {
        setServiceUpdated(!serviceUpdated);
    };

    const _handleRemovePlanItemForm = ({
        planIdx,
        planItemIndex,
    }: {
        planIdx: number;
        planItemIndex: number;
    }) => {
        if (planItemsAction && planItemsAction[planIdx]) {
            planItemsAction[planIdx].removeItem(planItemIndex);
        }
    };

    const _removePlanItem = (itemId: string) => {
        if (!itemId) return;
        const plans = ((form.getFieldValue('paymentPlans') || []) as IQuote.IPlan[]).filter(
            (plan) => plan?.id,
        );
        plans.forEach((plan, _planIdx) => {
            const _planItemIdx = (plan?.paymentPlanItems || [])
                .filter((pI) => pI?.id)
                .findIndex((planItem) => planItem?.quoteItemId === itemId);

            if (_planItemIdx !== -1) {
                _handleRemovePlanItemForm({
                    planIdx: _planIdx,
                    planItemIndex: _planItemIdx,
                });
            }
        });
    };

    const onRemovePlanItem = async (formItem: IQuoteItemForm) => {
        if (!formItem) return;

        setIsLoading(true); // Start loading
        _removePlanItem(formItem?.id);
        onChangeStatus();

        const remainingItems = form
            .getFieldValue('items')
            .filter((item: IQuoteItemForm) => item.id !== formItem.id);

        setItems([...remainingItems]); // Ensure a new array reference to trigger re-render

        // Fetch earnings after removing the item
        await getSaleEarnings();

        // Update earnings based on fetched data
        setEarning(saleEarning?.data?.data?.total ?? 0);

        onChangeItems();
        setIsLoading(false); // Stop loading
    };

    const _calcTotalQuoteItem = (item: IQuoteItemForm | undefined): number => {
        if (!item) return 0;
        const subTotal = (item?.qty ?? 0) * (item?.unitPrice ?? 0) || 0;

        return subTotal;
    };

    const onChangeItems = () => {
        const items = form.getFieldValue('items');

        const itemCalculateSaleEarning = items
            .filter((item: any) => item?.productId && item?.qty)
            .map((item: any) => {
                return {
                    productId: item.productId,
                    qty: item.qty,
                    quoteItemId: id ? item.id : null,
                };
            });

        setItems(itemCalculateSaleEarning);
    };

    const onChangePlanItem = ({
        quoteItemId,
        planIdx,
        planItemIdx,
        planItemFieldName,
        quoteItemFieldName,
    }: {
        quoteItemId: string;
        planIdx: number;
        planItemIdx: number;
        planItemFieldName: keyof IQuote.IPaymentPlanItem;
        quoteItemFieldName: keyof IQuoteItemForm | null;
    }) => {
        if (!quoteItemId) return;

        const quoteItems = (form.getFieldValue('items') || []) as IQuoteItemForm[];

        const existedQuoteItem = quoteItems.find((q) => q?.id === quoteItemId);

        const plans = (form.getFieldValue('paymentPlans') as IQuote.IPlan[]) || [];

        if (!plans?.length) return;
        const changedPlan = plans[planIdx];

        if (!changedPlan?.paymentPlanItems?.length) return;

        let selectedPlanItem = changedPlan.paymentPlanItems[planItemIdx];
        if (quoteItemFieldName) {
            selectedPlanItem = {
                ...selectedPlanItem,
                [planItemFieldName]: existedQuoteItem
                    ? existedQuoteItem[quoteItemFieldName as keyof IQuoteItemForm]
                    : null,
            } as IQuote.IPaymentPlanItem;
        }

        if (existedQuoteItem?.frequency === ProductFrequencyEnum.ONE_TIME_PAYMENT) {
            selectedPlanItem.frequency = ProductFrequencyEnum.ONE_TIME_PAYMENT;
            selectedPlanItem.percentage = 0;
            selectedPlanItem.total = 0;
        }

        changedPlan.paymentPlanItems[planItemIdx] = selectedPlanItem;

        form.setFieldsValue({
            paymentPlans: plans,
        });

        onChangeStatus();
    };

    const onChangePlanItemPercent = async (planIdx: number, index: number) => {
        const item: IQuote.IPaymentPlanItem = form.getFieldValue([
            'paymentPlans',
            planIdx,
            'paymentPlanItems',
            index,
        ]);

        calculatePlanItemPrice({ quoteItemId: item?.quoteItemId, currPlanItemId: item?.id });

        const items: IQuoteItemForm[] = form.getFieldValue('items') || [];

        const quoteItemIdx = items.findIndex((i) => i.id === item?.quoteItemId);
        form.validateFields([['items', quoteItemIdx, 'productId']]);
    };

    const calculatePlanItemPrice = ({
        quoteItemId,
        currPlanItemId,
    }: {
        quoteItemId: string;
        currPlanItemId?: string;
    }) => {
        const quoteItems = (form.getFieldValue('items') || []) as IQuoteItemForm[];

        const existedQuoteItem = quoteItems.find((q) => q?.id === quoteItemId);

        const plans = (form.getFieldValue('paymentPlans') as IQuote.IPlan[]) || [];
        if (!plans?.length) return;

        //handle re-calculate
        const changedPlanItems: IQuote.IPaymentPlanItem[] = [];
        plans.forEach((plan) => {
            const existedPlanItem = (plan?.paymentPlanItems || []).find(
                (_planItem) => _planItem?.quoteItemId === quoteItemId,
            );

            if (currPlanItemId && existedPlanItem?.id === currPlanItemId) {
                changedPlanItems.unshift(existedPlanItem);
                return;
            }

            if (existedPlanItem) {
                changedPlanItems.push(existedPlanItem);
            }
        });

        const total = _calcTotalQuoteItem(existedQuoteItem);
        let remainPercent = 100;
        let remainPrice = total;

        (changedPlanItems || []).forEach((changedItem) => {
            const percentage = changedItem?.percentage ?? 0;
            const itemPrice = Number(((total * percentage) / 100).toFixed(2));

            changedItem.frequency =
                existedQuoteItem?.frequency ?? ProductFrequencyEnum.ONE_TIME_PAYMENT;

            remainPercent = Math.max(remainPercent - percentage, 0);

            if (remainPercent > 0) {
                changedItem.total = itemPrice;
            } else {
                changedItem.total = remainPrice;
            }

            remainPrice = Math.max(remainPrice - changedItem.total, 0);

            // Calculate earnings for this plan item
            const itemEarning = (existedQuoteItem?.earning || 0) * (percentage / 100);

            changedItem.earning = Number(itemEarning.toFixed(4)); // Save earnings value if needed
        });

        form.setFieldsValue({
            paymentPlans: plans,
        });
    };

    const onChangeQuantity = ({ itemId, qty }: { qty: number | null; itemId: string }) => {
        if (qty && qty > 0) {
            calculatePlanItemPrice({ quoteItemId: itemId });
        }
        onChangeStatus();
        onChangeItems();
    };

    const _handlePriceBeforeSubmit = (formVal: IQuoteForm): IQuoteForm | null => {
        if (!formVal) return null;
        const payload = cloneDeep(formVal) as IQuoteForm;
        for (const item of payload.items) {
            const basePrice = +Number.parseFloat(`${item?.unitPrice}`).toFixed(2);
            const baseUnitCost = +Number.parseFloat(`${item?.unitCost}`).toFixed(2);
            const baseMargin = +Number.parseFloat(`${item?.margin}`).toFixed(2);

            item.unitPrice = basePrice * 100;
            item.unitCost = baseUnitCost * 100;
            item.margin = baseMargin;
        }
        return payload;
    };
    const onFinish = (values: IQuoteForm) => {
        const payload = _handlePriceBeforeSubmit(values);
        if (!payload) return;
        payload.status = status;
        payload.items = payload.items.map((item) => {
            return {
                ...item,
                frequency: ProductFrequencyEnum.ONE_TIME_PAYMENT,
            };
        });
        setIsLoading(true);
        if (id) {
            updateMutate(
                {
                    dataProviderName,
                    id: id,
                    resource: 'v1/quotes',
                    values: payload,
                    successNotification: { message: 'Successfully updated', type: 'success' },
                },
                {
                    onError: (_) => {
                        setIsLoading(false);
                    },
                    onSuccess: (_) => {
                        form.resetFields();
                        setTimeout(() => {
                            goBack();
                        });
                    },
                },
            );
        } else {
            mutate(
                {
                    dataProviderName,
                    resource: 'v1/quotes',
                    values: payload,
                    successNotification: { message: 'Successfully created', type: 'success' },
                    errorNotification: false,
                },
                {
                    onError: (error) => {
                        if (error.message === 'quote_item_lack_of_percentage') {
                            notification.error({
                                message: t('quotes.error.quote_item_lack_of_percentage'),
                                type: 'error',
                            });
                        } else {
                            notification.error({
                                message: error.message,
                                type: 'error',
                            });
                        }
                        setIsLoading(false);
                    },
                    onSuccess: (_) => {
                        setIsLoading(false);
                        goBack();
                    },
                },
            );
        }
        return;
    };

    const submitForm = async (status: QuoteStatus) => {
        setStatus(status);
        form.submit();
    };

    const disablePaymentDate = (current: dayjs.Dayjs): boolean => {
        return current < dayjs().startOf('day');
    };

    const disablePaymentDueDate = (current: dayjs.Dayjs, index: number): boolean => {
        const paymentDate = form.getFieldValue('paymentPlans')[index].paymentDate;
        return current < (paymentDate ? dayjs(paymentDate).startOf('day') : dayjs().startOf('day'));
    };

    const validateQuoteItemsOnChangePlanItem = async () => {
        const items: IQuoteItemForm[] = form.getFieldValue('items') || [];
        for (let index = 0; index < items?.length; index++) {
            form.validateFields([['items', index, 'productId']]);
        }
    };

    const validateQuoteItemSumPercent = (value: string, quoteItemId: string) => {
        if (!value) return Promise.resolve();

        const plans: IQuote.IPlan[] = form.getFieldValue('paymentPlans') || [];

        if (!plans?.length) {
            return Promise.resolve();
        }

        let sumPercent = 0;
        let isPlanned = false; //check if quote item has been added to plan
        plans.forEach((plan) => {
            const existedItem = (plan?.paymentPlanItems || []).find(
                (i) => i?.quoteItemId === quoteItemId,
            );

            if (existedItem) {
                isPlanned = true;
                sumPercent += +existedItem.percentage;
            }
        });

        if (!isPlanned) {
            return Promise.reject(t('quotes.fields.percentage.planned_required'));
        }
        if (sumPercent < 100 && isPlanned) {
            return Promise.reject(t('quotes.fields.percentage.full_percentage_required'));
        }
        return Promise.resolve();
    };

    const selectServiceOtherOptions = {
        label: t('quotes.fields.other_service.label'),
        value: SERVICE_OTHER_OPTION_ID,
    };

    const quoteFormItems = (
        <Form.List name="items">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index: number) => {
                        const formItem = form.getFieldValue('items')[index] as IQuoteItemForm;

                        const total = formItem?.unitPrice * formItem?.qty || 0;

                        const isSelectedOtherService =
                            formItem?.serviceId === selectServiceOtherOptions.value;

                        if (!formItem?.id) {
                            form.setFieldsValue({
                                items: form
                                    .getFieldValue('items')
                                    .map((item: any, i: number) =>
                                        i === index ? { ...item, id: item?.id || uuidv4() } : item,
                                    ),
                            });
                        }

                        return (
                            <Row className="list-product-item item-custom" key={'items' + key}>
                                <Col xs={24} sm={6}>
                                    <>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'serviceId']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('quotes.fields.item.required'),
                                                },
                                            ]}
                                            label={t('quotes.fields.item.label')}
                                        >
                                            <Select
                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                {...serviceSelectProps}
                                                showSearch={false}
                                                onChange={() => {
                                                    onChangeService(index);
                                                    onRemovePlanItem(formItem);
                                                }}
                                                placeholder={'Select service'}
                                                disabled={!!id && !!formItem.id && !formItem.isNew} // disable select service if quote item is existed
                                            />
                                        </Form.Item>
                                    </>

                                    {formItem?.serviceId && isSelectedOtherService && (
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'productName']}
                                            label={t('quotes.fields.product.label')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('quotes.fields.product.required'),
                                                },
                                                {
                                                    validator: (_, value, ___) =>
                                                        validateQuoteItemSumPercent(
                                                            value,
                                                            formItem?.id,
                                                        ),
                                                },
                                            ]}
                                            className="product-custom"
                                        >
                                            <Input
                                                onChange={() => {
                                                    form.validateFields([
                                                        ['items', index, 'unitPrice'],
                                                        ['items', index, 'frequency'],
                                                    ]);
                                                    onRemovePlanItem(formItem);
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                    {formItem?.serviceId && !isSelectedOtherService && (
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'productId']}
                                            label={t('quotes.fields.product.label')}
                                            className="product-custom"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('quotes.fields.product.required'),
                                                },
                                                {
                                                    validator: (_, value, ___) =>
                                                        validateQuoteItemSumPercent(
                                                            value,
                                                            formItem?.id,
                                                        ),
                                                },
                                            ]}
                                        >
                                            <Select
                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                showSearch={false}
                                                placeholder={t('quotes.fields.product.placeholder')}
                                                onChange={(item) => {
                                                    onChangeProduct(item, index);
                                                    onRemovePlanItem(formItem);
                                                }}
                                                disabled={!!id && !!formItem.id && !formItem.isNew} // disable select product if quote item is existed
                                            >
                                                {services[formItem?.serviceId]?.products.map(
                                                    (product: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={product.id}
                                                                value={product.id}
                                                            >
                                                                {product.name}
                                                            </Select.Option>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Col>

                                <Col xs={24} sm={6}>
                                    <div className="price-field cursor-pointer">
                                        {formatPrice(formItem?.unitPrice * 1)}

                                        {!isSysadmin && formItem.margin !== undefined ? (
                                            <span className="charge">
                                                <b>{t('quotes.fields.margin.label')} : </b>
                                                {`${formItem.margin}%`}
                                            </span>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xs={24} sm={4}>
                                    <Form.Item
                                        {...restField}
                                        label={t('quotes.fields.qty.label')}
                                        name={[name, 'qty']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('quotes.fields.qty.required'),
                                            },
                                            {
                                                type: 'number',
                                                message: t('quotes.fields.qty.invalid_number'), // Error message if it's not a number
                                            },
                                            {
                                                validator: (_, value) =>
                                                    value && isNaN(value)
                                                        ? Promise.reject(
                                                              t('quotes.fields.qty.invalid_number'),
                                                          )
                                                        : Promise.resolve(),
                                            },
                                            {
                                                min: 1,
                                                type: 'number',
                                                message: t('quotes.fields.qty.min_value'), // Error message if less than 1
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            defaultValue={1}
                                            step={1}
                                            precision={0}
                                            onChange={(qty: number | null) =>
                                                onChangeQuantity({ qty, itemId: formItem.id })
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={4}>
                                    <Form.Item
                                        {...restField}
                                        label={t('quotes.fields.frequency.label')}
                                        name={[name, 'frequency']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('quotes.fields.frequency.required'),
                                            },
                                        ]}
                                    >
                                        <span>
                                            {t(
                                                'services.frequency.' +
                                                    ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                { ns: 'common' },
                                            )}
                                        </span>
                                        {/* <Select
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            options={[
                                                {
                                                    label: t(
                                                        'services.frequency.' +
                                                            ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                        { ns: 'common' },
                                                    ),
                                                    value: ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                },
                                            ]}
                                            value={ProductFrequencyEnum.ONE_TIME_PAYMENT}
                                            disabled
                                        /> */}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={4} className="total-wrapper">
                                    <span className="total-price">
                                        {t('quotes.fields.total.label')}
                                    </span>
                                    <div className="total-container cursor-pointer">
                                        <span>{formatPrice(total)}</span>
                                        <img
                                            className="ml-4"
                                            src="/images/icons/remove.svg"
                                            onClick={() => {
                                                onRemovePlanItem(formItem);
                                                remove(name);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}

                    <div className="btn-add-container">
                        <Button
                            className="add-quote-item-btn"
                            onClick={() => {
                                add({
                                    qty: 1,
                                    id: uuidv4(),
                                    isNew: true,
                                });
                            }}
                            type="link"
                            icon={<PlusOutlined />}
                        >
                            {t('quotes.actions.addItem')}
                        </Button>
                    </div>
                </>
            )}
        </Form.List>
    );

    const sumPercentagePlanItem = (plans: IQuote.IPlan[], itemId: string): number => {
        if (!plans?.length) return 0;
        const sum = (plans || []).reduce((prev, current) => {
            const paymentPlanItem = (current?.paymentPlanItems || [])
                .filter((i) => i?.id)
                .find((i) => i.quoteItemId === itemId);
            prev += paymentPlanItem?.percentage ? +paymentPlanItem.percentage : 0;
            return prev;
        }, 0);
        return sum;
    };

    const getAvailableQuoteItems = (currPlanIdx: number, currPlanItemIdx: number) => {
        const items = ((form.getFieldValue('items') as IQuoteItemForm[]) || []).filter(
            (item) =>
                (item?.productId || item?.productName) && item?.frequency && item?.unitPrice >= 0,
        );

        const quoteItems: IQuoteItemForm[] = [];
        const plans = ((form.getFieldValue('paymentPlans') || []) as IQuote.IPlan[]).filter(
            (plan) => plan?.id,
        );
        if (items?.length && plans?.length) {
            for (const item of items) {
                let valid = true;
                let isSelected = false;

                for (const plan of plans) {
                    const _planIdx = plans.findIndex((p) => p?.id === plan?.id);
                    const _paymentPlanItems = (plan.paymentPlanItems || []).filter((p) => p?.id);
                    if (_paymentPlanItems?.length) {
                        const existingIndex = _paymentPlanItems.findIndex(
                            (planItem: IQuote.IPaymentPlanItem) => planItem.quoteItemId === item.id,
                        );
                        const isExisting = existingIndex !== -1;

                        isSelected = currPlanItemIdx === existingIndex && _planIdx === currPlanIdx;
                        if (isExisting && _planIdx === currPlanIdx) {
                            valid = false;
                            break;
                        }
                    }
                }

                if (valid || isSelected) {
                    quoteItems.push(item);
                }
            }
        }

        return quoteItems;
    };

    const paymentPlans = (
        <Form.List name="paymentPlans">
            {(listFields, { add, remove }) => (
                <>
                    <div className="section-header">
                        <div className="flex items-center">
                            <span className="payment-plans-title">
                                {t('quotes.fields.paymentPlan.label')}
                            </span>
                            <div className=" ml-auto">
                                <div className="btn-add-container">
                                    <Button
                                        onClick={() => {
                                            add({ id: uuidv4() });
                                        }}
                                        type="link"
                                        icon={<PlusOutlined />}
                                        className="add-payment-plans"
                                    >
                                        {t('quotes.actions.addPaymentPlan')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {listFields.length ? (
                        listFields.map(({ key: listKey, name, ...restField }, index: number) => {
                            return (
                                <div
                                    key={'paymentPlans' + listKey}
                                    className="plan-container py-6 px-4 mb-6"
                                >
                                    <div className="cursor-pointer flex justify-between">
                                        <span className="plan-index">{`#${
                                            index + 1 < 10 ? `0${index + 1}` : index + 1
                                        }`}</span>
                                        <img
                                            className="ml-auto"
                                            src="/images/icons/x-remove.svg"
                                            onClick={() => {
                                                remove(name);
                                                validateQuoteItemsOnChangePlanItem();
                                            }}
                                        />
                                    </div>
                                    <Row gutter={20}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                className="date-label"
                                                {...restField}
                                                label={t('quotes.fields.paymentDate.label')}
                                                name={[name, 'paymentDate']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'quotes.fields.paymentDate.required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    getPopupContainer={(trigger) =>
                                                        trigger?.parentNode as HTMLElement
                                                    }
                                                    format={DATE_FORMAT}
                                                    disabledDate={(current) =>
                                                        disablePaymentDate(current)
                                                    }
                                                    onChange={() =>
                                                        form.resetFields([
                                                            [
                                                                'paymentPlans',
                                                                index,
                                                                'paymentDueDate',
                                                            ],
                                                        ])
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                {...restField}
                                                className="date-label"
                                                label={t('quotes.fields.paymentDueDate.label')}
                                                name={[name, 'paymentDueDate']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'quotes.fields.paymentDueDate.required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    getPopupContainer={(trigger) =>
                                                        trigger?.parentNode as HTMLElement
                                                    }
                                                    format={DATE_FORMAT}
                                                    disabledDate={(current) =>
                                                        disablePaymentDueDate(current, index)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="table-header">
                                        <Col span={6} className="label-field-required">
                                            {t('quotes.fields.item.label')}
                                        </Col>
                                        <Col span={6} className="label-field-required">
                                            {t('quotes.fields.frequency.label')}
                                        </Col>
                                        <Col span={6} className="label-field-required">
                                            {t('quotes.fields.percentage.label')}
                                        </Col>
                                        <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                        <Col span={2}></Col>
                                    </Row>
                                    {paymentPlanItems(name, index)}
                                </div>
                            );
                        })
                    ) : (
                        <div className="plan-term-conditions pb-4">
                            <div className="flex items-center">
                                <div className="condition-index" />
                                <div className="text ml-4">
                                    {t('quotes.term_conditions.agree_to_secure')}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </Form.List>
    );

    const calculateTotalEarningsByPlan = (planIdx: number) => {
        const plans = form.getFieldValue('paymentPlans') as IQuote.IPlan[];

        if (!plans || !plans[planIdx]) return 0;

        const paymentPlanItems = plans[planIdx].paymentPlanItems || [];
        let totalEarnings = 0;

        paymentPlanItems.forEach((item) => {
            totalEarnings += item.earning || 0;
        });

        return totalEarnings;
    };

    const paymentPlanItems = (name: number, planIdx: number) => {
        return (
            <Form.List name={[name, 'paymentPlanItems']}>
                {(subFields, { add: addItem, remove: removeItem }) => {
                    //extract remove item form
                    if (planItemsAction[`${planIdx}`]) {
                        planItemsAction[`${planIdx}`].removeItem = removeItem;
                    } else {
                        planItemsAction[`${planIdx}`] = {
                            removeItem,
                        };
                    }
                    return (
                        <>
                            {subFields.map(({ key: subKey, name: subName }, index: number) => {
                                const formPlanItem = form.getFieldValue([
                                    'paymentPlans',
                                    planIdx,
                                    'paymentPlanItems',
                                    index,
                                ]);
                                return (
                                    <Row
                                        gutter={20}
                                        className={`list-plan-item ${
                                            index + 1 === (subFields || []).length
                                                ? 'last-child'
                                                : ''
                                        }`}
                                        key={'paymentPlanItems' + subKey}
                                    >
                                        <Col xs={24} sm={6}>
                                            <Form.Item
                                                label={t('quotes.fields.item.label')}
                                                name={[subName, 'quoteItemId']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'quotes.fields.quote_item.required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentNode
                                                    }
                                                    showSearch={false}
                                                    placeholder={t(
                                                        'quotes.fields.quote_item.placeholder',
                                                    )}
                                                    onChange={(id: string) =>
                                                        onChangePlanItem({
                                                            quoteItemId: id,
                                                            planIdx,
                                                            planItemIdx: index,
                                                            planItemFieldName: 'frequency',
                                                            quoteItemFieldName: 'frequency',
                                                        })
                                                    }
                                                >
                                                    {(
                                                        getAvailableQuoteItems(planIdx, index) || []
                                                    )?.map((quoteItem: IQuoteItemForm) => {
                                                        return quoteItem.productName ? (
                                                            <Select.Option
                                                                key={quoteItem.id}
                                                                value={quoteItem.id}
                                                            >
                                                                {quoteItem.productName}
                                                            </Select.Option>
                                                        ) : null;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Form.Item
                                                name={[subName, 'frequency']}
                                                label={t('quotes.fields.frequency.label')}
                                                className="frequency-custom"
                                            >
                                                <span className="pl-3.5">
                                                    {formPlanItem?.frequency
                                                        ? t(
                                                              `services.frequency.${formPlanItem?.frequency}`,
                                                              { ns: 'common' },
                                                          )
                                                        : ''}
                                                </span>
                                                <Input className="hidden" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Form.Item
                                                label={t('quotes.fields.percentage.label')}
                                                name={[subName, 'percentage']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'quotes.fields.percentage.required',
                                                        ),
                                                    },
                                                    {
                                                        min: 1,
                                                        type: 'number',
                                                        message: t(
                                                            'quotes.fields.percentage.not_less_than_1',
                                                        ),
                                                    },
                                                    {
                                                        validator: (_, value: number, __) => {
                                                            if (!value) return Promise.resolve();
                                                            const plans = (form.getFieldValue(
                                                                'paymentPlans',
                                                            ) || []) as IQuote.IPlan[];
                                                            const currItem = form.getFieldValue([
                                                                'paymentPlans',
                                                                planIdx,
                                                                'paymentPlanItems',
                                                                index,
                                                            ]) as IQuote.IPaymentPlanItem;

                                                            if (!currItem?.quoteItemId)
                                                                return Promise.resolve();

                                                            const _sumPercentage =
                                                                sumPercentagePlanItem(
                                                                    plans,
                                                                    currItem?.quoteItemId,
                                                                );

                                                            const remainPercent =
                                                                100 - _sumPercentage + value * 1;

                                                            if (remainPercent <= 0) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'quotes.fields.percentage.full_percentage',
                                                                        { remainPercent },
                                                                    ),
                                                                );
                                                            }

                                                            if (value > remainPercent) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'quotes.fields.percentage.invalid_remain_percentage',
                                                                        { remainPercent },
                                                                    ),
                                                                );
                                                            }

                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    max={100}
                                                    defaultValue={1}
                                                    step={1}
                                                    precision={0}
                                                    onChange={(val: number | null) => {
                                                        onChangePlanItemPercent(planIdx, index);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={4}>
                                            <Form.Item
                                                name={[subName, 'total']}
                                                label={t('quotes.fields.total.label')}
                                                className="total-custom"
                                            >
                                                <span className="pl-6">
                                                    {formatPrice(
                                                        form.getFieldValue([
                                                            'paymentPlans',
                                                            planIdx,
                                                            'paymentPlanItems',
                                                            index,
                                                            'total',
                                                        ]) ?? 0,
                                                    )}
                                                </span>
                                                <Input className="hidden" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={2}>
                                            <div className="total-container button-delete-custom cursor-pointer">
                                                <img
                                                    className="ml-0"
                                                    src="/images/icons/red-remove.svg"
                                                    onClick={() => {
                                                        removeItem(subName);
                                                        validateQuoteItemsOnChangePlanItem();
                                                        onChangeStatus();
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            })}
                            <div className="btn-add-container">
                                <Button
                                    className="add-plan-item-btn"
                                    onClick={() =>
                                        addItem({
                                            id: uuidv4(),
                                            percentage: 0,
                                            frequency: null,
                                            total: 0,
                                        })
                                    }
                                    type="link"
                                    icon={<PlusOutlined />}
                                >
                                    {t('quotes.actions.addItem')}
                                </Button>
                            </div>
                            {!isSysadmin && (
                                <div className="sum-total flex">
                                    <span>
                                        {t('crm_internal.quote.total_earning', { ns: 'common' })}
                                    </span>
                                    <span className="ml-auto">
                                        {formatPrice(calculateTotalEarningsByPlan(planIdx))}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                }}
            </Form.List>
        );
    };

    return (
        <PayoutContextProvider>
            <Spin spinning={isLoading} tip="Loading...">
                <section className="block-container">
                    <div className="block-heading">
                        <span>
                            {id ? t('quotes.heading.editTitle') : t('quotes.heading.title')}
                        </span>
                        <div className="header-actions edit-order">
                            <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                            <Button
                                type="dashed"
                                disabled={status === QuoteStatus.Pending && isLoading}
                                loading={status === QuoteStatus.Draft && isLoading}
                                onClick={() => submitForm(QuoteStatus.Draft)}
                            >
                                {t('quotes.actions.saveAsDraft')}
                            </Button>
                            <Button
                                type="primary"
                                disabled={status === QuoteStatus.Draft && isLoading}
                                loading={status === QuoteStatus.Pending && isLoading}
                                onClick={() => submitForm(QuoteStatus.Pending)}
                            >
                                {id ? t('quotes.actions.save') : t('quotes.actions.create')}
                            </Button>
                        </div>
                    </div>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{ items: [{ qty: 1, id: uuidv4() }] }}
                        onFinish={onFinish}
                    >
                        <div className="section quote-information">
                            <div className="section-header ">
                                {t('quotes.heading.quoteInformation')}
                            </div>
                            <BillInformation form={form} />
                        </div>
                        <div className="section">
                            <Row gutter={20}>
                                <Col md={24}>
                                    <Form.Item
                                        label={t('quotes.fields.description.label')}
                                        name="description"
                                    >
                                        <TextEditor height="300px" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                            <Row className="item-list">
                                <Col span={24}>
                                    <Row
                                        className={` ${
                                            window.screen.width < 578 ? 'table-header-none' : ''
                                        } table-header`}
                                    >
                                        <Col span={6} className="label-field-required">
                                            {t('quotes.fields.item.label')}
                                        </Col>
                                        <Col span={6} className="label-field-required">
                                            {t('quotes.fields.unitPrice.label')}
                                        </Col>
                                        <Col span={4} className="label-field-required">
                                            {t('quotes.fields.qty.label')}
                                        </Col>
                                        <Col span={4} className="label-field-required">
                                            {t('quotes.fields.frequency.label')}
                                        </Col>
                                        <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                    </Row>

                                    {quoteFormItems}
                                </Col>
                            </Row>
                            <div className="sum-total flex">
                                <span>{t('quotes.heading.totalPrice')}</span>
                                <span className="ml-auto">{formatPrice(getTotalPrice())}</span>
                            </div>
                            {!isSysadmin && (
                                <div className="sum-total flex">
                                    <span>
                                        {t('crm_internal.quote.total_earning', { ns: 'common' })}
                                    </span>
                                    <span className="ml-auto">{formatPrice(earning || 0)}</span>
                                </div>
                            )}
                        </div>
                        <div className="section payment-plans">
                            <Row gutter={20}>
                                <Col md={24}>{paymentPlans}</Col>
                            </Row>
                        </div>
                        <div className="section">
                            <div className="section-header">{t('quotes.fields.tc.label')}</div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <Form.Item name="termsAndConditions">
                                        <TextEditor />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </section>
            </Spin>
        </PayoutContextProvider>
    );
};
