export const FacebookIcon: React.FC = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.4992 0.0037452L11.165 0C8.54265 0 6.84797 1.73871 6.84797 4.42982V6.47227H4.50106C4.29826 6.47227 4.13403 6.63668 4.13403 6.83949V9.79876C4.13403 10.0016 4.29845 10.1658 4.50106 10.1658H6.84797V17.633C6.84797 17.8358 7.01219 18 7.21499 18H10.277C10.4798 18 10.6441 17.8356 10.6441 17.633V10.1658H13.3881C13.5909 10.1658 13.7552 10.0016 13.7552 9.79876L13.7563 6.83949C13.7563 6.74211 13.7175 6.64886 13.6488 6.57994C13.5801 6.51103 13.4865 6.47227 13.3891 6.47227H10.6441V4.74086C10.6441 3.90868 10.8424 3.48622 11.9264 3.48622L13.4988 3.48566C13.7014 3.48566 13.8657 3.32124 13.8657 3.11863V0.370775C13.8657 0.168347 13.7016 0.00411972 13.4992 0.0037452Z"
                fill="#C2CFE0"
            />
        </svg>
    );
};
