import './styles.scss';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import { ChangeStatusActionIcon } from '@components/layout/icons/change-status-action';
import { DuplicateActionIcon } from '@components/layout/icons/duplicate-action';
import { EditActionIcon } from '@components/layout/icons/edit-action';
import { CustomSearch } from '@components/modules/custom-search';
import { FilterStatus } from '@components/modules/filter-status';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { QuoteStatus } from '@enums/quote-status.enum';
import { IInvoice } from '@interfaces/invoice';
import { IUser } from '@interfaces/user';
import {
    CreateButton,
    DeleteButton,
    EditButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
    useModalForm,
} from '@refinedev/antd';
import {
    BaseRecord,
    HttpError,
    LogicalFilter,
    useApiUrl,
    useCustomMutation,
    useGetIdentity,
    useNavigation,
    useNotification,
    usePermissions,
    useTable,
    useUpdate,
} from '@refinedev/core';
import { convertPrice, formatDate, getPublicMediaUrl } from '@utils/resource';
import { Avatar, Button, Dropdown, Menu, notification, Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { DataProviderNameEnum } from 'dataProvider';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { ChangeStatusModal } from 'pages/invoice/change-status-modal';
import qs from 'qs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const INVOICE_FILTER = 'invoice_filter';
export const INVOICE_RESOURCE = 'wl_invoice';

export const InvoiceCrmIndex: React.FC = () => {
    const { t } = useTranslation(['quote']);
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const LIST_FILTER_STATUS = [QuoteStatus.Pending, QuoteStatus.Paid, QuoteStatus.Void].map(
        (status) => ({
            label: t(`quotes.status.${status}`),
            value: status.toString(),
        }),
    );

    const { data: user } = useGetIdentity<IUser.IUserIdentity>();

    const {
        setFilters,
        filters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<IInvoice.InvoiceList>({
        resource: 'v1/invoices',
        sorters: {
            initial: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        syncWithLocation: true,
        dataProviderName,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { data: permissionsData } = usePermissions<string[]>();

    const { mutate } = useCustomMutation<any>();
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const { open: openNotification } = useNotification();
    const { push } = useNavigation();
    const { search } = useLocation();
    const queryParams = qs.parse(search, {
        ignoreQueryPrefix: true,
    });

    // Change status modal
    const {
        show: showChangeStatusModal,
        close: closeChangeStatusModal,
        modalProps: changeInvoiceStatusModal,
        formProps: changeInvoiceStatusForm,
        id: modalItemId,
        formLoading: modalLoading,
    } = useModalForm<BaseRecord, HttpError, { status: QuoteStatus }>({
        dataProviderName,
        action: 'edit',
        resource: 'v1/invoices/change-status',
        queryOptions: {
            enabled: false,
        },
        errorNotification: false,
        successNotification: false,
        redirect: false,
        onMutationError: (error, _, __) => {
            switch (error?.message) {
                case 'invoice_not_found':
                    notification.error({
                        message: t('quotes.error.invoice_not_found'),
                    });
                    break;
                case 'invoice_was_not_pending':
                    notification.error({
                        message: t('quotes.error.invoice_was_not_pending'),
                    });
                    break;
                default:
                    notification.error({
                        message: t('quotes.error.change_invoice_status_failed'),
                    });
            }
            return;
        },
        onMutationSuccess: (data, _, __) => {
            if (data?.data) {
                notification.success({
                    message: t('quotes.message.change_invoice_status_successfully'),
                });
                closeChangeStatusModal();
                tableQueryResult.refetch();
            } else {
                notification.error({
                    message: t('quotes.message.change_invoice_status_failed'),
                });
            }
        },
    });

    const { mutate: updateMutate, isLoading: updateLoading } = useUpdate();

    const duplicateInvoice = (id: string) => {
        updateMutate(
            {
                resource: 'v1/invoices/duplicate',
                id,
                values: {},
                errorNotification: false,
                successNotification: false,
                dataProviderName,
            },
            {
                onError: (error, _, __) => {
                    switch (error?.message) {
                        case 'invoice_not_found':
                            notification.error({
                                message: t('quotes.error.invoice_not_found'),
                            });
                            break;
                        case 'invoice_is_empty':
                            notification.error({
                                message: t('quotes.error.invoice_is_empty'),
                            });
                            break;
                        default:
                            notification.error({
                                message: t('quotes.error.duplicate_invoice_failed'),
                            });
                    }
                },
                onSuccess: (data, _, __) => {
                    if (data?.data) {
                        notification.success({
                            message: t('quotes.message.duplicate_invoice_successfully'),
                        });
                        tableQueryResult.refetch();
                    } else {
                        notification.error({
                            message: t('quotes.error.duplicate_invoice_failed'),
                        });
                    }
                },
            },
        );
    };

    const isInvoiceOwner = (invoice: IInvoice.InvoiceList) =>
        user?.id === (invoice.contactId || invoice.userId);

    const invoiceFilter = `invoiceFilter=${JSON.stringify(queryParams)}`;
    const menu = (id: string, record: IInvoice.InvoiceList) => (
        <Menu mode="vertical">
            {record.contactId && record?.status === QuoteStatus.Pending && (
                <Menu.Item
                    key="0"
                    icon={
                        <img
                            src="/images/icons/send.svg"
                            alt="send"
                            className="cursor-pointer mr-2"
                        />
                    }
                    onClick={() => {
                        sendInvoice(id);
                    }}
                    disabled={loading}
                >
                    {t('actions.send')}
                </Menu.Item>
            )}

            <Menu.Item
                key="1"
                icon={
                    <ShowButton
                        recordItemId={id}
                        style={{
                            padding: '5px 16px 5px 12px',
                            margin: '0px',
                        }}
                        onClick={() => {
                            push(`show/${id}?${invoiceFilter}`);
                        }}
                    />
                }
                style={{ padding: 0 }}
            ></Menu.Item>

            {(permissionsData || []).includes(PERMISSIONS.WL_INVOICE_EDIT) &&
            !isInvoiceOwner(record) &&
            record.status === QuoteStatus.Pending ? (
                <Menu.Item
                    className="action__custom"
                    key="2"
                    style={{ padding: 0 }}
                    disabled={!!record.planId}
                    icon={
                        <EditButton
                            id={id}
                            recordItemId={id}
                            icon={
                                <div className="cursor-pointer mr-2">
                                    <EditActionIcon color={record.planId ? '#BFBFBF' : undefined} />
                                </div>
                            }
                            style={{
                                padding: '5px 16px 5px 12px',
                                margin: 0,
                            }}
                            disabled={!!record.planId}
                        >
                            {t('actions.edit')}
                        </EditButton>
                    }
                ></Menu.Item>
            ) : null}

            {(permissionsData || []).includes(PERMISSIONS.WL_INVOICE_EDIT) &&
            !isInvoiceOwner(record) &&
            [QuoteStatus.Pending, QuoteStatus.Approved].includes(record.status) ? (
                <Menu.Item
                    key="3"
                    className="action__custom"
                    icon={
                        <Button
                            icon={
                                <div className="cursor-pointer mr-2">
                                    <ChangeStatusActionIcon />
                                </div>
                            }
                            style={{
                                padding: '5px 16px 5px 12px',
                                margin: 0,
                            }}
                            onClick={() => showChangeStatusModal(id)}
                        >
                            {t('actions.change_status')}
                        </Button>
                    }
                    style={{ padding: 0 }}
                ></Menu.Item>
            ) : null}

            {(permissionsData || []).includes(PERMISSIONS.WL_INVOICE_EDIT) &&
            !isInvoiceOwner(record) ? (
                <Menu.Item
                    key="4"
                    className="action__custom"
                    icon={
                        <Button
                            icon={
                                <div className="cursor-pointer mr-2">
                                    <DuplicateActionIcon />
                                </div>
                            }
                            style={{
                                padding: '5px 16px 5px 12px',
                                margin: 0,
                            }}
                            loading={updateLoading}
                            onClick={() => duplicateInvoice(id)}
                        >
                            {t('actions.duplicate')}
                        </Button>
                    }
                    style={{ padding: 0 }}
                ></Menu.Item>
            ) : null}

            {(permissionsData || []).indexOf(PERMISSIONS.WL_INVOICE_DELETE) >= 0 &&
                record.status === QuoteStatus.Pending &&
                !record?.quoteId && (
                    <Menu.Item
                        key="5"
                        style={{ padding: 0 }}
                        icon={
                            <DeleteButton
                                dataProviderName={dataProviderName}
                                successNotification={{
                                    message: t('quotes.message.delete_successfully'),
                                    type: 'success',
                                }}
                                resource="v1/invoices"
                                recordItemId={id}
                                style={{
                                    margin: 0,
                                    padding: '5px 16px 5px 12px',
                                }}
                                errorNotification={(err) => {
                                    const errorMsg = (err as { message: string })?.message;

                                    if (errorMsg === 'not_delete_invoice_create_by_plan') {
                                        return {
                                            message: t(
                                                'quotes.error.not_delete_invoice_create_by_plan',
                                            ),
                                            type: 'error',
                                        };
                                    }

                                    if (errorMsg === 'not_delete_invoice_status_paid') {
                                        return {
                                            message: t(
                                                'quotes.error.not_delete_invoice_status_paid',
                                            ),
                                            type: 'error',
                                        };
                                    }

                                    return {
                                        message: t('quotes.error.delete_invoice_failed'),
                                        type: 'error',
                                    };
                                }}
                            />
                        }
                    ></Menu.Item>
                )}
        </Menu>
    );

    const sendInvoice = (id: string) => {
        setLoading(true);
        mutate(
            {
                url: `${apiUrl}/v1/invoices/${id}/send`,
                method: 'post',
                values: {},
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    setLoading(false);
                },
                onSuccess: (data, variables, context) => {
                    const result = data?.data;
                    setLoading(false);
                    openNotification?.({
                        message: 'Successfully sent',
                        type: 'success',
                    });
                },
            },
        );
    };

    const onChangeFilter = (event: any) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'filter');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = event.target.value;
            } else {
                newFilter.push({
                    field: 'filter',
                    operator: 'eq',
                    value: event.target.value,
                });
            }

            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeStatus = (status: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'status');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = status;
            } else {
                newFilter.push({
                    field: 'status',
                    operator: 'eq',
                    value: status,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <PayoutContextProvider>
            <section className="item-list-container invoice-page-wrapper">
                <div className="list-header">
                    <div className="filter-wrapper">
                        <CustomSearch
                            placeholder={t('quotes.fields.search.invoce_placeholder')}
                            className={'search-item'}
                            onChange={(event: any) => onChangeFilter(event)}
                            defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                            allowClear={true}
                        />
                        <FilterStatus
                            placeholder={t('quotes.fields.search.status_placeholder')}
                            listStatus={LIST_FILTER_STATUS}
                            className="search-status"
                            defaultValue={filters?.find((f: any) => f.field === 'status')?.value}
                            onChangeStatus={(status: string) => onChangeStatus(status)}
                        />
                    </div>
                    {(permissionsData || []).includes(PERMISSIONS.WL_INVOICE_CREATE) ? (
                        <CreateButton icon={false} className="btn-add-new" type="primary">
                            <PlusOutlined />
                            {t('add_new', { ns: 'common' })}
                        </CreateButton>
                    ) : null}
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            pagination={false}
                            rowKey="id"
                            className="invoices-table"
                            tableLayout="fixed"
                            scroll={{ x: '900px', y: '100%' }}
                        >
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('incrementId', sorters)}
                                title={<>{t('quotes.table.number')}</>}
                                dataIndex="incrementId"
                                key="incrementId"
                                width={80}
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                title={<>{t('quotes.table.customer')}</>}
                                dataIndex="contactName"
                                key="contactName"
                                sorter
                                defaultSortOrder={getDefaultSortOrder('contactName', sorters)}
                                width={300}
                                render={(row, record: IInvoice.InvoiceList) => {
                                    return (
                                        <div className="flex justify-start items-center">
                                            <Avatar
                                                size={40}
                                                src={
                                                    record.contactAvatarUrl
                                                        ? getPublicMediaUrl(record.contactAvatarUrl)
                                                        : null
                                                }
                                                icon={<AntDesignOutlined />}
                                            />
                                            <p className="table-tbody-text ml-2 item-name pd-0">
                                                {row}
                                            </p>
                                        </div>
                                    );
                                }}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('status', sorters)}
                                title={<>{t('quotes.table.status')}</>}
                                dataIndex="status"
                                key="status"
                                width={120}
                                render={(text, _) => (
                                    <p className={`table-tbody-text status-${text}`}>
                                        {t('quotes.status.' + text)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('quotes.table.amount')}</>}
                                dataIndex="amount"
                                key="amount"
                                width={120}
                                render={(text, _) => (
                                    <p className="table-tbody-text">{convertPrice(text)}</p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('quotes.table.frequency')}</>}
                                dataIndex="frequencies"
                                key="frequencies"
                                width={200}
                                render={(frequencies, _) => (
                                    <p className="table-tbody-text">
                                        {frequencies
                                            .map((f: string) => t('quotes.frequency.' + f))
                                            .join(', ')}
                                    </p>
                                )}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('quotes.table.date')}</>}
                                width={200}
                                dataIndex="date"
                                key="date"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('dueDate', sorters)}
                                width={200}
                                title={<>{t('quotes.table.dueDate')}</>}
                                dataIndex="dueDate"
                                key="dueDate"
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(id, record: IInvoice.InvoiceList, index) => (
                                    <div
                                        className="flex justify-between items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Dropdown
                                            overlay={menu(id, record)}
                                            placement="bottomRight"
                                            className="w-8 ml-auto"
                                            arrow
                                            overlayClassName="invoice-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <div>
                                                <img
                                                    src="/images/icons/dots-vertical.svg"
                                                    alt="more"
                                                    className="cursor-pointer"
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
                <ChangeStatusModal
                    itemId={modalItemId}
                    modalProps={changeInvoiceStatusModal}
                    formProps={changeInvoiceStatusForm}
                    loading={modalLoading}
                />
            </section>
        </PayoutContextProvider>
    );
};
