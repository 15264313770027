export const MarkAllAsReadIcon: React.FC = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_7773_157222"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_7773_157222)">
                <path
                    d="M2 17V15H11V17H2ZM2 9.00005V7.00005H11V9.00005H2ZM16.375 19L12.825 15.45L14.225 14.05L16.35 16.175L20.6 11.925L22 13.35L16.375 19ZM16.375 11L12.825 7.45005L14.225 6.05005L16.35 8.17505L20.6 3.92505L22 5.35005L16.375 11Z"
                    fill="#ffffff"
                />
            </g>
        </svg>
    );
};
