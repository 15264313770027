import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { APPLICATIONS } from '@constants/index.constant';
import { Currency } from '@enums/currency.enum';
import { NApplication } from '@interfaces/application';
import { useApiUrl, useCustomMutation, useOne } from '@refinedev/core';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { Button, Col, Form, Input, InputNumber, Layout, Row } from 'antd';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import { getEnvConfig } from '../../getEnvConfig';
import s from './styles.module.scss';

const enum FieldTypeEnum {
    EMAIL = 'email',
    PASSWORD = 'password',
    FULL_NAME = 'fullName',
    QTY_TABLE = 'qtyTable',
    REFERENCE_CODE = 'referenceCode',
    SERVICE_PACKAGE = 'servicePackage',
}

const MAX_QTY_TABLE_FREE = 30;

export const ExtraApplicationPlanIndex = () => {
    const apiUrl = useApiUrl();
    const recaptchaRef = useRef<any>();

    const [form] = Form.useForm();
    const { t } = useTranslation(['common']);
    const { CAPTCHA_SITE_KEY } = getEnvConfig;

    const [planPrice, setPlanPrice] = useState<number>(0);
    const [extraAppPlanId, setExtraAppPlanId] = useState<string>();

    const { mutate } = useCustomMutation();

    const { data: extraAppPlans } = useOne<NApplication.IExtraAppPlan[]>({
        resource: 'v1/application/extra-app-plans',
        id: APPLICATIONS.POS.id,
        queryOptions: {
            enabled: true,
        },
    });

    const handleSubmit = async (values: NApplication.IPlanRequest) => {
        const requestForm: NApplication.IPlanRequest = {
            ...values,
            planPrice,
            extraAppPlanId: extraAppPlanId ?? '',
        };

        const recaptchaToken = await recaptchaRef.current.executeAsync();

        mutate({
            url: `${apiUrl}/v1/tenant/sales-package-plan`,
            method: 'post',
            values: requestForm,
            config: {
                headers: {
                    recaptchavalue: recaptchaToken ?? null,
                },
            },
            errorNotification: (error) => ({
                message: t(
                    `plan_form.errors.${
                        error?.statusCode === 500 || !error?.response?.data?.error
                            ? 'register_failed'
                            : error?.response?.data?.error
                    }`,
                ),
                type: 'error',
            }),
        });
    };

    return (
        <Layout className={`flex items-center justify-center ${s.planPage}`}>
            <Form form={form} layout="vertical" className={s.planContainer} onFinish={handleSubmit}>
                <section className={s.headerContainer}>
                    <div className={s.logoContainer}>
                        <img src="/images/ffood_logo.png" alt="logo" className={s.logoImg} />
                    </div>

                    <p className={`mr-2 ${s.titleForm}`}>{t('plan_form.title')}</p>
                </section>

                <section className={s.formContainer}>
                    <div className={`flex flex-col ${s.formWrapper}`}>
                        <Row className="w-full" gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    label={t('plan_form.fields.email')}
                                    name={FieldTypeEnum.EMAIL}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('plan_form.fields.email_required'),
                                        },
                                        {
                                            type: 'email',
                                            message: t('plan_form.fields.email_invalid'),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('plan_form.fields.email_placeholder')} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('plan_form.fields.password')}
                                    name={FieldTypeEnum.PASSWORD}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('plan_form.fields.password_required'),
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder={t('plan_form.fields.password_placeholder')}
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="w-full" gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    label={t('plan_form.fields.full_name')}
                                    name={FieldTypeEnum.FULL_NAME}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('plan_form.fields.full_name_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t('plan_form.fields.full_name_placeholder')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('plan_form.fields.qty_table')}
                                    name={FieldTypeEnum.QTY_TABLE}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('plan_form.fields.qty_table_required'),
                                        },
                                        {
                                            validator: async (_, value) => {
                                                const servicePackageFree =
                                                    extraAppPlans?.data?.find(
                                                        (extraAppPlan) =>
                                                            extraAppPlan.title === 'FREE',
                                                    );

                                                const isSelectServicePackageFree =
                                                    extraAppPlanId === servicePackageFree?.id;

                                                if (
                                                    (!value && value !== 0) ||
                                                    !isSelectServicePackageFree ||
                                                    (isSelectServicePackageFree &&
                                                        value <= MAX_QTY_TABLE_FREE)
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    t(
                                                        'plan_form.fields.qty_table_less_than_or_equal',
                                                    ),
                                                );
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        type="number"
                                        pattern="[0-9]*"
                                        placeholder={t('plan_form.fields.qty_table_placeholder')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="w-full" gutter={12}>
                            <Col span={24}>
                                <Form.Item
                                    label={t('plan_form.fields.reference_code')}
                                    name={FieldTypeEnum.REFERENCE_CODE}
                                >
                                    <Input
                                        placeholder={t(
                                            'plan_form.fields.reference_code_placeholder',
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            label={t('plan_form.fields.service_package')}
                            name={FieldTypeEnum.SERVICE_PACKAGE}
                            rules={[
                                {
                                    required: !extraAppPlanId,
                                    message: t('plan_form.fields.service_package_required'),
                                },
                            ]}
                        >
                            <Row className="w-full" gutter={12}>
                                {extraAppPlans?.data?.map((extraAppPlan) => (
                                    <Col
                                        span={8}
                                        onClick={() => {
                                            setPlanPrice(extraAppPlan.pricing);
                                            setExtraAppPlanId(extraAppPlan.id);
                                            form.validateFields([
                                                FieldTypeEnum.SERVICE_PACKAGE,
                                                FieldTypeEnum.QTY_TABLE,
                                            ]);
                                        }}
                                    >
                                        <div
                                            className={`text-center cursor-pointer ${
                                                extraAppPlanId === extraAppPlan.id
                                                    ? `${s.selectedService}`
                                                    : `${s.planItem}`
                                            }`}
                                        >
                                            <div className={s.iconPlan}>
                                                <img
                                                    src="/images/icons/plan.svg"
                                                    alt="dots-vertical"
                                                    className={s.actionIcon}
                                                />
                                            </div>
                                            <p className={s.namePlan}>{extraAppPlan.title}</p>
                                            <p className={s.pricePlan}>
                                                {formatPriceBaseOnCurrency({
                                                    price: extraAppPlan.pricing,
                                                    currency: Currency.VND,
                                                })}
                                            </p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Form.Item>
                    </div>
                </section>

                <div className={s.btnContainer}>
                    <Button type="primary" className="mr-2" htmlType="submit">
                        {t('common.submit')}
                    </Button>
                </div>

                <ReCAPTCHA
                    size="invisible"
                    badge="bottomleft"
                    ref={recaptchaRef}
                    sitekey={CAPTCHA_SITE_KEY}
                />
            </Form>
        </Layout>
    );
};
