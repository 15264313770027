export const UploadDocumentIcon: React.FC = () => {
    return (
        <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 17H9.5V12.825L11.1 14.425L12.5 13L8.5 9L4.5 13L5.925 14.4L7.5 12.825V17ZM2.5 20C1.95 20 1.47933 19.8043 1.088 19.413C0.696 19.021 0.5 18.55 0.5 18V2C0.5 1.45 0.696 0.979 1.088 0.587C1.47933 0.195667 1.95 0 2.5 0H10.5L16.5 6V18C16.5 18.55 16.3043 19.021 15.913 19.413C15.521 19.8043 15.05 20 14.5 20H2.5ZM9.5 7H14.5L9.5 2V7Z"
                fill="#6B6CFF"
            />
        </svg>
    );
};
