import { Create, Edit, SaveButton } from '@refinedev/antd';
import { ButtonProps, Drawer, DrawerProps, FormProps, Grid } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactCrmDetailForm } from './detail-form';

type ContactDetailsProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    isEditMode: boolean;
};

export const ContactCrmDetail: React.FC<ContactDetailsProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    isEditMode,
}) => {
    const { t } = useTranslation(['contact', 'common']);
    const [loading] = useState<boolean>(false);
    const breakpoint = Grid.useBreakpoint();

    return drawerProps.open ? (
        <Drawer
            {...drawerProps}
            width={breakpoint.md ? '700px' : '100%'}
            maskClosable={false}
            zIndex={999}
            className="drawer-form-container"
            afterOpenChange={() => {
                formProps.form?.resetFields();
            }}
            title={
                <span className="text-lg">
                    {!isEditMode
                        ? `${t('contacts.createContact')}`
                        : `${t('contacts.editContact')}`}
                </span>
            }
            extra={<SaveButton {...saveButtonProps} />}
        >
            {isEditMode ? (
                <Edit
                    breadcrumb={null}
                    isLoading={!loading ? false : true}
                    footerButtons={<></>}
                    headerProps={{ backIcon: null, extra: null, title: '' }}
                >
                    <ContactCrmDetailForm formProps={formProps} mode={'edit'} />
                </Edit>
            ) : (
                <Create
                    breadcrumb={null}
                    headerProps={{ backIcon: null }}
                    isLoading={!loading ? false : true}
                    footerButtons={<></>}
                    title={<></>}
                >
                    <ContactCrmDetailForm formProps={formProps} mode={'add'} />
                </Create>
            )}
        </Drawer>
    ) : (
        <></>
    );
};
