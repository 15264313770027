import './styles.scss';

import { useSelect } from '@refinedev/antd';
import { CrudFilters } from '@refinedev/core';
import { Empty, Select, SelectProps, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PAGE_SIZE } from '../../../constants/index.constant';
import { IPartner } from '../../../interfaces/partner';

export const SelectPartner: React.FC<
    SelectProps & {
        isActivePartner?: boolean;
        selectedPartner?: IPartner.IPartnerInfo;
        getSelectedPartner?: (partner: IPartner.IPartnerInfo) => void;
        clearPartner?: () => void;
    }
> = (props) => {
    const { t } = useTranslation(['common']);
    const [pageIndex, setPageIndex] = useState(1);
    const [currentData, setCurrentData] = useState<IPartner.IPartnerInfo[]>([]);
    const {
        value,
        className,
        placeholder,
        selectedPartner,
        getSelectedPartner,
        clearPartner,
        ...res
    } = props;

    const [filters, setFilters] = useState<CrudFilters>([
        {
            field: 'isActive',
            operator: 'eq',
            value: props?.isActivePartner ? props.isActivePartner : '',
        },
    ]);
    const { selectProps, queryResult } = useSelect<IPartner.IPartnerInfo>({
        resource: 'v1/partner/list',
        debounce: 500,
        onSearch: (value) => {
            setPageIndex(1);
            setCurrentData([]);
            setFilters([
                {
                    field: 'filter',
                    operator: 'eq',
                    value,
                },
                {
                    field: 'isActive',
                    operator: 'eq',
                    value: props?.isActivePartner ? props.isActivePartner : '',
                },
            ]);
            return [];
        },
        sort: [
            {
                field: 'name',
                order: 'asc',
            },
        ],

        pagination: {
            current: pageIndex,
            mode: 'server',
        },
        filters: filters,
        queryOptions: {
            onSuccess: (data) => {
                setCurrentData(currentData.concat(data.data));
            },
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_partners'),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_partners_failed'),
                };
            }
        },
    });

    const onChangePartner = (value: string) => {
        const partner = currentData.find((item) => item.id === value);
        partner && getSelectedPartner && getSelectedPartner(partner);
    };

    return (
        <>
            <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                {...selectProps}
                notFoundContent={queryResult.isFetching ? <Spin size="small" /> : <Empty />}
                placeholder="Select Partner"
                {...props}
                allowClear
                className="select-partner"
                defaultValue={
                    selectedPartner
                        ? {
                              value: selectedPartner?.id || '',
                              label: selectedPartner?.name || '',
                          }
                        : null
                }
                onPopupScroll={async (e: any) => {
                    const { target } = e;
                    if (
                        (target as any).scrollTop + (target as any).offsetHeight ===
                        (target as any).scrollHeight
                    ) {
                        if (
                            queryResult.data &&
                            currentData.length < queryResult.data?.total &&
                            !queryResult.isRefetching &&
                            !queryResult.isFetching
                        ) {
                            setPageIndex(pageIndex + 1);
                            await queryResult.refetch();
                        }
                    }
                }}
                options={currentData.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                loading={queryResult.isRefetching || queryResult.isFetching}
                onClear={() => {
                    setPageIndex(1);
                    setCurrentData([]);
                    setFilters([]);
                    clearPartner && clearPartner();
                    if (!filters.length) {
                        queryResult.refetch();
                    }
                }}
                onChange={(value) => onChangePartner(value)}
            />
        </>
    );
};
