import './styles.scss';

import { FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { DeleteButton, useModal } from '@refinedev/antd';
import {
    HttpError,
    useCustom,
    useCustomMutation,
    usePermissions,
    useTranslate,
} from '@refinedev/core';
import { Button, Dropdown, Menu, Modal, notification, Spin, Table, Tag } from 'antd';
import Response from 'data-access/responses/response';
import { getEnvConfig } from 'getEnvConfig';
import React, { useEffect, useState } from 'react';

import { Payment } from '../../../interfaces/payment';

interface IPaymentMethodProps {
    [name: string]: string;
}

export const PaymentMethod: React.FC<IPaymentMethodProps> = () => {
    const t = useTranslate();
    const apiUrl = getEnvConfig.PORTAL_URL;

    const { data: permissions } = usePermissions<string[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const { mutate: setDefaultPayment } = useCustomMutation<Response<boolean>, HttpError, any>();

    const { mutate: createToken } = useCustomMutation<Response<boolean>, HttpError, any>();

    const {
        data: paymentMethods,
        refetch: paymentRefetch,
        isLoading,
    } = useCustom<Payment.IPaymentMethod[]>({
        url: `${apiUrl}/v1/users/payment-methods`,
        config: {
            filters: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: 'finix',
                },
            ],
        },
        method: 'get',
    });

    const { modalProps, show, close } = useModal({
        modalProps: {
            onCancel: () => {
                // setClientSecret(null);
            },
        },
    });

    useEffect(() => {
        const onSubmit = async () => {
            setLoading(true);

            form.submit(
                getEnvConfig.FINIX_ENVIRONMENT as string,
                getEnvConfig.FINIX_APPLICATION_ID as string,
                async function (err: any, res: Finix.FormResponse) {
                    const tokenData = res.data || {};
                    const token = tokenData.id;
                    if (!token) {
                        notification.error({
                            message: 'Finix generate token error',
                        });
                    }

                    createToken(
                        {
                            url: `${apiUrl}/v1/users/tokenize`,
                            method: 'post',
                            values: {
                                token: token,
                            },
                            errorNotification: false,
                        },
                        {
                            onError: (error) => {
                                setLoading(false);
                                if (error) {
                                    notification.error({
                                        message:
                                            error?.response?.data?.error ?? 'Something went wrong',
                                    });
                                    return;
                                }
                            },
                            onSuccess: (data) => {
                                setLoading(false);
                                if (!data.data?.data) {
                                    notification.error({
                                        message:
                                            t(`error.${err?.response?.data?.error}`) ??
                                            'Something went wrong',
                                    });
                                    return;
                                }

                                close();
                                paymentRefetch();
                            },
                        },
                    );
                },
            );
        };

        const form = window.Finix.CardTokenForm('form-bank', {
            showAddress: false,
            onSubmit,
            styles: {
                default: {
                    color: '#000',
                    fontSize: '14px',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    padding: '8px 14px',
                    border: '1px solid #d9d9d9',
                    fontFamily: `-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'`,
                },
                success: {
                    color: '#5cb85c',
                },
                error: {
                    color: '#ff4d4f',
                    border: '1px solid rgba(255,0,0, 0.3)',
                },
            },
        });
    }, [modalProps.open]);

    const handleAddNewCard = () => {
        show();
    };

    const handleSetDefaultPayment = async (cardId: string) => {
        setDefaultPayment(
            {
                url: `${apiUrl}/v1/users/payment-methods/${cardId}/set-default`,
                method: 'put',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: async (data) => {
                    if (data.data) {
                        await paymentRefetch();
                    }
                },
            },
        );
    };

    return (
        <>
            <div className="mb-3">
                <h2>{t('billing.payment_methods', 'Payment methods')}</h2>
            </div>
            <Table
                rowKey="id"
                showHeader={false}
                dataSource={paymentMethods?.data || []}
                pagination={false}
                loading={isLoading}
                scroll={{ x: '576px' }}
            >
                <Table.Column
                    dataIndex="brand"
                    key="brand"
                    sorter
                    render={(brand, record: Payment.IPaymentMethod, index) => (
                        <div className="flex justify-between items-center ">
                            <div className="flex justify-start items-center">
                                <div className="card-img ml-auto">
                                    <img
                                        src={`/images/payments/${record.brand?.toLowerCase()}.svg`}
                                    />
                                </div>
                                <p className="table-tbody-text ml-2 item-name pd-0 w-52">
                                    {brand.toLowerCase()} {t('billing.ending_in', 'ending in')}{' '}
                                    {record.last4}
                                </p>
                            </div>
                        </div>
                    )}
                />
                <Table.Column
                    dataIndex="expMonth"
                    key="expMonth"
                    render={(text, record: Payment.IPaymentMethod, index) => (
                        <p className="table-tbody-text">
                            {t('billing.expires', 'Expires')} {record.expMonth}/{record.expYear}
                        </p>
                    )}
                />
                <Table.Column
                    dataIndex="isDefault"
                    key="isDefault"
                    render={(isDefault, record: Payment.IPaymentMethod, index) => (
                        <p className="table-tbody-text">
                            {isDefault ? <Tag>{t('billing.default', 'Default')}</Tag> : null}
                        </p>
                    )}
                />
                <Table.Column
                    dataIndex="id"
                    key="action"
                    render={(id, record: Payment.IPaymentMethod, index) => (
                        <div className="flex justify-end items-center" style={{ minWidth: '65px' }}>
                            <Dropdown
                                overlay={
                                    <Menu mode="vertical">
                                        {!record.isDefault &&
                                        (permissions || []).includes(PERMISSIONS.BILLING_CREATE) ? (
                                            <Menu.Item
                                                key="1"
                                                style={{
                                                    fontWeight: 500,
                                                }}
                                                icon={
                                                    <FormOutlined
                                                        style={{
                                                            color: 'green',
                                                        }}
                                                    />
                                                }
                                                onClick={() =>
                                                    handleSetDefaultPayment(record?.id as string)
                                                }
                                            >
                                                {t('buttons.makeDefault')}
                                            </Menu.Item>
                                        ) : null}
                                        {(permissions || []).includes(
                                            PERMISSIONS.BILLING_DELETE,
                                        ) ? (
                                            <Menu.Item key="3">
                                                <DeleteButton
                                                    successNotification={{
                                                        message: 'Successfully deleted',
                                                        type: 'success',
                                                    }}
                                                    resource="v1/users/payment-methods"
                                                    recordItemId={id}
                                                    onSuccess={() => {
                                                        paymentRefetch();
                                                    }}
                                                    disabled={record.isDefault}
                                                    className="delete__action"
                                                />
                                            </Menu.Item>
                                        ) : null}
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                                overlayClassName="contact-dropdown-container"
                                trigger={['click']}
                            >
                                <img
                                    src="/images/icons/dots-vertical.svg"
                                    alt="more"
                                    className="cursor-pointer"
                                />
                            </Dropdown>
                        </div>
                    )}
                />
            </Table>
            {(permissions || []).includes(PERMISSIONS.BILLING_CREATE) ? (
                <div className="my-2 flex justify-end">
                    <Button type="primary" onClick={handleAddNewCard}>
                        Add New Card
                    </Button>
                </div>
            ) : null}
            <Modal
                {...modalProps}
                title="Payment"
                maskClosable={false}
                footer={null}
                width={650}
                wrapClassName="payment-modal"
            >
                <Spin spinning={loading}>
                    <section id="form-bank"></section>
                </Spin>
            </Modal>
        </>
    );
};
