import './styles.scss';

import { CustomSearch } from '@components/modules/custom-search';
import { IContact } from '@interfaces/contact';
import { IInvoice } from '@interfaces/invoice';
import { NPDFMakeSetting } from '@interfaces/pdf-make.setting';
import { ISubscription } from '@interfaces/subscription';
import { useApiUrl, useCustom, useList } from '@refinedev/core';
import { renderTitleTable } from '@utils/pdf';
import { Input, Tabs } from 'antd';
import { Content } from 'pdfmake/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadPrint } from '../download-pdf';
import { ContactInvoiceTabContent } from './contact-invoice-list';
import { renderInvoiceSummary, renderInvoiceTable } from './contact-invoice-list/print-pdf-invoice';
import { ContactPaymentTabContent } from './contact-payment-list';
import { ContactSubscriptionTabContent } from './contact-subscription-list';

enum TabsEnum {
    PAYMENTS = 'contactPayments',
    INVOICES = 'contactInvoices',
    SUBSCRIPTIONS = 'contactSubscriptions',
}
export const PAGE_SIZE = 5;
export const ContactInvoicesPayments = ({
    contactId,
    contactName,
}: {
    contactId: string;
    contactName: string;
}) => {
    const { t } = useTranslation(['common', 'contact', 'quote']);

    const [tab, setTab] = useState<TabsEnum>(TabsEnum?.INVOICES);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [filter, setFilter] = useState<string>('');
    const [dataToPrint, setDataToPrint] = useState<NPDFMakeSetting.IReportBody>();
    const [invoiceData, setInvoiceData] = useState<IInvoice.Invoice[]>();
    const [prevPaymentIntentId, setPrevPaymentIntentId] = useState<string | null>(null);
    const [nextPaymentIntentId, setNextPaymentIntentId] = useState<string | null>(null);
    const apiUrl = useApiUrl();

    const onChangeTab = (e: TabsEnum) => {
        setTab(e);
    };

    const { data: totalPayments, refetch: totalPaymentRefetch } = useCustom<any>({
        url: `${apiUrl}/v1/contacts/get-all-payment-stripe/${contactId}`,
        method: 'get',
        errorNotification: false,
        queryOptions: {
            retry: 1,
        },
    });

    const {
        data: tableQueryInvoiceResult,
        refetch: invoiceRefetch,
        isLoading: invoiceLoading,
    } = useList<IInvoice.Invoice>({
        resource: 'v1/contacts/get-invoices',
        queryOptions: {
            enabled: false,
        },
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: PAGE_SIZE,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
            ],
            sort: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
    });

    const {
        data: paymentInvoiceData,
        refetch: paymentInvoiceRefetch,
        isLoading: paymentInvoiceLoading,
    } = useCustom<IContact.ResponsePaymentContact>({
        url: `${apiUrl}/v1/contacts/get-payment-invoice`,
        method: 'get',

        queryOptions: {
            enabled: false,
        },
        config: {
            filters: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: PAGE_SIZE,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
                {
                    field: 'prevPaymentIntentId',
                    operator: 'eq',
                    value: prevPaymentIntentId,
                },
                {
                    field: 'nextPaymentIntentId',
                    operator: 'eq',
                    value: nextPaymentIntentId,
                },
            ],
            sort: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
    });

    const {
        data: subscriptionData,
        refetch: subscriptionRefetch,
        isLoading: subscriptionLoading,
    } = useList<ISubscription.SubscriptionList>({
        resource: 'v1/contacts/recurring-invoice',
        queryOptions: {
            enabled: false,
        },
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: PAGE_SIZE,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
            ],
            sort: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
    });

    useEffect(() => {
        if (contactId) {
            switch (tab) {
                case TabsEnum.INVOICES: {
                    invoiceRefetch();
                    break;
                }
                case TabsEnum.PAYMENTS: {
                    paymentInvoiceRefetch();

                    break;
                }
                case TabsEnum.SUBSCRIPTIONS: {
                    subscriptionRefetch();
                    break;
                }
            }
        }
    }, [pageIndex, contactId, filter]);

    useEffect(() => {
        if (paymentInvoiceData) {
            setPrevPaymentIntentId(paymentInvoiceData?.data?.prevPaymentIntentId);
            setNextPaymentIntentId(paymentInvoiceData?.data?.nextPaymentIntentId);
        }
    }, [paymentInvoiceData]);

    useEffect(() => {
        if (contactId) {
            setPrevPaymentIntentId('');
            setNextPaymentIntentId('');
            setPageIndex(1);
            switch (tab) {
                case TabsEnum.INVOICES: {
                    invoiceRefetch();
                    break;
                }
                case TabsEnum.PAYMENTS: {
                    setFilter('');
                    paymentInvoiceRefetch();
                    break;
                }
                case TabsEnum.SUBSCRIPTIONS: {
                    subscriptionRefetch();
                    break;
                }
            }
        }
    }, [tab]);

    useEffect(() => {
        let summaryData: Content[] = [];
        const tableData: Content[] = [];

        if (invoiceData) {
            summaryData = renderInvoiceSummary(invoiceData, (rs: string, obj: any) => t(rs, obj));
        }
        if (invoiceData?.length) {
            const title = '';
            const resultMapTable = renderInvoiceTable(invoiceData, (rs: string, obj: any) =>
                t(rs, obj),
            );
            tableData.push(title, resultMapTable);
        }

        const InvoiceData: NPDFMakeSetting.IReportBody = {
            summarySection: summaryData || [],
            tableBody: tableData || [],
        };

        setDataToPrint(InvoiceData);
    }, [invoiceData]);

    const searchElement = useMemo(() => {
        if (tab !== TabsEnum.PAYMENTS) {
            return (
                <div className="flex">
                    <CustomSearch
                        placeholder={
                            tab === TabsEnum?.INVOICES
                                ? t('quotes.fields.search.invoce_placeholder', { ns: 'quote' })
                                : t('quotes.fields.search.recurring_invoice_placeholder', {
                                      ns: 'quote',
                                  })
                        }
                        className={'search-item p-3'}
                        onChange={(event: any) => {
                            setFilter(event?.target.value);
                            setPageIndex(1);
                        }}
                        allowClear={true}
                    />
                    {tab === TabsEnum.INVOICES && (
                        <DownloadPrint
                            title={`${t('contacts.pdf.invoice.invoice_of', {
                                ns: 'contact',
                            })} ${contactName}`}
                            contactName={contactName}
                            dataToPrint={dataToPrint}
                        />
                    )}
                </div>
            );
        }
    }, [tab, dataToPrint]);

    useEffect(() => {
        totalPaymentRefetch();
    }, [contactId]);

    return (
        <div className="contact-payments-invoices overflow-hidden">
            <Tabs
                tabBarExtraContent={searchElement}
                onChange={(e) => onChangeTab(e as TabsEnum)}
                defaultActiveKey={TabsEnum.INVOICES}
            >
                <Tabs.TabPane tab={t('contact.invoices', { ns: 'common' })} key={TabsEnum.INVOICES}>
                    <ContactInvoiceTabContent
                        cols={tableQueryInvoiceResult?.data || []}
                        current={pageIndex}
                        setCurrent={setPageIndex}
                        tableResult={tableQueryInvoiceResult}
                        pageSize={PAGE_SIZE}
                        setDataToPrint={(value: IInvoice.Invoice[]) => setInvoiceData(value)}
                        invoiceLoading={invoiceLoading}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={t('contact.recurring_invoice', { ns: 'common' })}
                    key={TabsEnum.SUBSCRIPTIONS}
                >
                    <ContactSubscriptionTabContent
                        cols={subscriptionData?.data || []}
                        current={pageIndex}
                        setCurrent={setPageIndex}
                        tableResult={subscriptionData}
                        total={subscriptionData?.total as number}
                        pageSize={PAGE_SIZE}
                        subscriptionLoading={subscriptionLoading}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('contact.payment', { ns: 'common' })} key={TabsEnum.PAYMENTS}>
                    <ContactPaymentTabContent
                        cols={paymentInvoiceData?.data?.data || []}
                        current={pageIndex}
                        setCurrent={setPageIndex}
                        tableResult={paymentInvoiceData}
                        pageSize={PAGE_SIZE}
                        setPrevPaymentIntentId={setPrevPaymentIntentId}
                        setNextPaymentIntentId={setNextPaymentIntentId}
                        total={totalPayments?.data || 0}
                        paymentInvoiceLoading={paymentInvoiceLoading}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
