import './styles.scss';

import { useApiUrl } from '@refinedev/core';
import { Image, message } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import Upload, { UploadChangeParam } from 'antd/lib/upload';
import { useState } from 'react';

export interface IServeralImageUploadProps {
    onChange: (fileList: UploadFile) => void;
}

export const ServeralImageUpload: React.FC<IServeralImageUploadProps> = ({ onChange }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const size = 5;

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt = file.size / 1024 / 1024 < size;
        if (!isLt) {
            message.error(`Image must smaller than ${size}MB!`);
        }

        if (!(isJpgOrPng && isLt)) {
            return false;
        }

        return true;
    };

    const handleChange = (fileOrn: UploadChangeParam<UploadFile>) => {
        if (fileOrn.file.status === 'done') {
            onChange(fileOrn.file);
        }
        setFileList(fileOrn.fileList);
    };

    const uploadButton = (
        <Image className="icon-action mx-3" src={'/images/icons/picture.svg'} preview={false} />
    );

    const apiUrl = useApiUrl();

    return (
        <>
            <Upload
                beforeUpload={beforeUpload}
                action={`${apiUrl}/v1/resource/upload-image`}
                listType="picture-card"
                onChange={(fileOrn) => handleChange(fileOrn)}
                fileList={fileList}
                accept={'.png, .jpg, .jpeg'}
                showUploadList={false}
            >
                {uploadButton}
            </Upload>
        </>
    );
};
