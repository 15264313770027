import './styles.scss';

import MainLogo from '@assets/logo.svg';
import { Loading } from '@components/layout/loading';
import Response from '@data-access/responses/response';
import {
    HttpError,
    IResourceComponentsProps,
    useApiUrl,
    useCustomMutation,
    useLogin,
} from '@refinedev/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Card, Col, Image, Layout, notification, Row, Spin } from 'antd';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TOKEN_KEY } from '../../../authProvider';
import { getEnvConfig } from '../../../getEnvConfig';
import PaymentForm from './payment-form';

const stripePromise = loadStripe(getEnvConfig.STRIPE_PUBLIC_KEY);

export const StepThreeIndex: React.FC<IResourceComponentsProps<unknown>> = ({ options }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const { mutate: loginOnboarding } = useCustomMutation<Response<{ token: string }>>();
    const apiUrl = useApiUrl();
    const accessToken = localStorage.getItem(TOKEN_KEY);
    const { mutate: login } = useLogin();
    const { search } = useLocation();
    const [isSkip, setIsSkip] = useState<boolean>(false);
    const { mutate: createToken } = useCustomMutation<Response<boolean>, HttpError, any>();

    const { redirect_status, setup_intent_client_secret, to, code, type } = qs.parse(search, {
        ignoreQueryPrefix: true,
    });

    const clientSecret = code as string;

    const handleLogin = () => {
        setLoading(true);
        const url = `${apiUrl}/v1/auth/onboarding/tenant/step-three`;
        loginOnboarding(
            {
                url: url,
                method: 'post',
                values: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    console.error(error);
                    setLoading(false);
                },
                onSuccess: (data, variables, context) => {
                    const redirectUrl = (
                        to ? to : `${window.location.protocol}//${window.location.host}/dashboard`
                    ) as string;
                    login({
                        data: data.data.data,
                        redirectUrl,
                    });
                },
            },
        );
    };

    useEffect(() => {
        if ((redirect_status === 'succeeded' && setup_intent_client_secret === code) || isSkip) {
            handleLogin();
        }
    }, [redirect_status, isSkip]);

    useEffect(() => {
        const onSubmit = async () => {
            setPaymentLoading(true);

            form.submit(
                getEnvConfig.FINIX_ENVIRONMENT as string,
                getEnvConfig.FINIX_APPLICATION_ID as string,
                async function (err, res: Finix.FormResponse) {
                    const tokenData = res.data || {};

                    const token = tokenData.id;
                    if (!token) {
                        notification.error({
                            message: 'Finix generate token error',
                        });
                    }

                    createToken(
                        {
                            url: `${apiUrl}/v1/users/tokenize`,
                            method: 'post',
                            values: {
                                token: token,
                            },
                        },
                        {
                            onError: (error) => {
                                // An error happened!
                                setPaymentLoading(false);
                                console.error(error);
                            },
                            onSuccess: () => {
                                handleLogin();
                            },
                        },
                    );
                },
            );
        };

        const form = window.Finix.CardTokenForm('form-bank', {
            showAddress: false,
            onSubmit,
            styles: {
                default: {
                    color: '#000',
                    fontSize: '14px',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    padding: '8px 14px',
                    border: '1px solid #d9d9d9',
                    fontFamily: `-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'`,
                },
                success: {
                    color: '#5cb85c',
                },
                error: {
                    color: '#ff4d4f',
                    border: '1px solid rgba(255,0,0, 0.3)',
                },
            },
        });
    }, []);

    const onSkip = () => {
        setIsSkip(true);
    };

    return (
        <Layout className="onboarding-layout-step-three">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col md={12}>
                    {!loading && (
                        <div className="onboarding-container">
                            <Card
                                title={<Image src={MainLogo} preview={false} />}
                                headStyle={{ borderBottom: 0 }}
                            >
                                <div className="org-info">
                                    <div className="title">Welcome to Nuvioo</div>
                                    <div className="desc">
                                        Digital Transformation Solutions - Your gateway to The
                                        Nuvioo World
                                    </div>
                                </div>
                                {(!clientSecret || clientSecret == 'undefined') && (
                                    <>
                                        <Spin spinning={paymentLoading}>
                                            <section id="form-bank"></section>
                                            <a
                                                className="skip-add-payment mt-3"
                                                onClick={() => onSkip && onSkip()}
                                            >
                                                Skip
                                            </a>
                                        </Spin>
                                    </>
                                )}
                                {clientSecret && clientSecret != 'undefined' && (
                                    <>
                                        <Elements
                                            stripe={stripePromise}
                                            options={{
                                                clientSecret: clientSecret,
                                            }}
                                        >
                                            <PaymentForm onSkip={() => onSkip()} />
                                        </Elements>
                                    </>
                                )}
                            </Card>
                        </div>
                    )}
                    {loading && <Loading />}
                </Col>
            </Row>
        </Layout>
    );
};
