import './styles.scss';

import { useTranslation } from 'react-i18next';

export const Hint: React.FC<{
    size: number;
    width?: number;
    height?: number;
    fileType: string[];
}> = ({ size, width, height, fileType }) => {
    const { t } = useTranslation('common');

    return (
        <ul className="hint-upload">
            <li>
                {t('hint_image.support')} <strong>{fileType.join('/ ')}</strong>
            </li>
            <li>
                {t('hint_image.maximum')} <strong>{size}MB</strong>
            </li>
            {height && width && (
                <li>
                    {t('hint_image.standard_size')}{' '}
                    <strong>
                        {width}px * {height}px
                    </strong>
                </li>
            )}
        </ul>
    );
};
