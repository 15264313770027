import { ArrowDownOutlined } from '@ant-design/icons';
import { List, Button } from 'antd';
import { LogicalFilter, useTable, useTranslate } from '@refinedev/core';
import { useEffect, useState } from 'react';

import { IDocument } from '../../../interfaces/document';
import { FileItem } from './item';

type FileListProps = {
    folderId: string;
    filterNameFile?: string;
    isUploadDocument?: boolean;
    isFetchedFolder?: boolean;
    uploadDocumentSuccess: () => void;
    deleteDocumentSuccess?: () => void;
};
export const DOCUMENT_RESOURCE = 'v1/documents';

export const FileList: React.FC<FileListProps> = (props) => {
    const {
        folderId,
        filterNameFile,
        isUploadDocument,
        isFetchedFolder,
        uploadDocumentSuccess,
        deleteDocumentSuccess,
    } = props;
    const t = useTranslate();
    const [total, setTotal] = useState<number>(0);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const { tableQueryResult, setCurrent, current, setFilters, filters } = useTable<IDocument>({
        resource: DOCUMENT_RESOURCE,
        initialPageSize: 8,
        initialCurrent: 1,
        initialFilter: [
            {
                field: 'folderId',
                operator: 'eq',
                value: folderId,
            },
            {
                field: 'filter',
                operator: 'eq',
                value: filterNameFile,
            },
        ],
        queryOptions: {
            enabled: false,
        },
    });

    const [data, setData] = useState<IDocument[]>([]);

    useEffect(() => {
        loadData();
    }, [current, filters]);

    useEffect(() => {
        const _filters = (filters || []).filter((f) => (f as LogicalFilter)?.field !== 'filter');
        _filters.push({
            field: 'filter',
            operator: 'eq',
            value: filterNameFile,
        });
        setFilters(_filters);
        setIsFilter(true);
    }, [filterNameFile]);

    useEffect(() => {
        if (isUploadDocument) {
            loadData();
        }
    }, [isUploadDocument]);
    useEffect(() => {
        if (isFetchedFolder) {
            loadData();
        }
    }, [isFetchedFolder]);

    const loadData = async () => {
        if (isUploadDocument || isFetchedFolder || isFilter) {
            setCurrent(1);
        }
        const dataResult = await tableQueryResult.refetch();
        if (isUploadDocument || isFetchedFolder || isFilter) {
            setData(dataResult.data?.data || []);
            setTotal(dataResult.data?.total || 0);
            uploadDocumentSuccess && uploadDocumentSuccess();
            if (isFilter) {
                setIsFilter(false);
            }
        } else {
            const docNoDuplicate = detectDuplicateDoc(data, dataResult.data?.data || []);
            const resultData = data.concat(docNoDuplicate);
            setData(resultData);
            setTotal(dataResult.data?.total || 0);
        }
    };

    const detectDuplicateDoc = (oldDocs: IDocument[], newDocs: IDocument[]) => {
        if (!oldDocs.length) {
            return newDocs;
        }
        const docNoDuplicate = newDocs.filter((doc) => {
            return !oldDocs.find((value) => doc.id === value.id);
        });
        return docNoDuplicate;
    };

    const onLoadMore = async () => {
        setCurrent(current + 1);
    };

    const onDeleteSuccess = async (id?: string) => {
        if (id) {
            const dataResult = await tableQueryResult.refetch();
            const currentData = data.filter((item) => item.id !== id);
            const docNoDuplicate = detectDuplicateDoc(currentData, dataResult.data?.data || []);
            const newData = currentData.concat(docNoDuplicate || []);
            setData(newData);
            setTotal(dataResult.data?.total || 0);
            deleteDocumentSuccess && deleteDocumentSuccess();
        }
    };

    const loadMore =
        total > data.length ? (
            <div className="btn-loading flex">
                <Button className="ml-auto btn-load-more" type="default" onClick={onLoadMore}>
                    {t('resource.label.loadMore')} <ArrowDownOutlined />
                </Button>
            </div>
        ) : null;

    return (
        <>
            <List
                grid={{
                    gutter: 20,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                dataSource={data}
                rowKey={(item) => item.id}
                loadMore={loadMore}
                loading={tableQueryResult.isFetching}
                renderItem={(item: IDocument) => (
                    <List.Item key={item.id} className="file-item">
                        <FileItem onDeleteSuccess={onDeleteSuccess} file={item} />
                    </List.Item>
                )}
            />
        </>
    );
};
